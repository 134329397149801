import React from 'react'
import { 
    Box,
    InputGroup,
    InputLeftAddon,
    Input,
    InputRightAddon,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Button,
    Text,
    Flex,
    Badge,
    Spinner,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react';
import { useHistory,Link } from 'react-router-dom'
import { onAuthStateChanged } from '@firebase/auth'
import axios from 'axios'
import { BASEURL } from '../../../api';
import { auth } from '../../../api/firebase-config';
import { GrFormNextLink } from 'react-icons/gr'
const Campaigns = () => {

  const [user,setUser] = React.useState(null)
  const [isFetching,setIsFetching] = React.useState(false)
  const [campaignsList,setCampaingsList] = React.useState([])
  const [noMoreCampaigns,setNoMoreCampaigns] = React.useState(false)

  const DynamicTr = ({campaignName,campginId,submissionsNumber}) => {
    return <>
     <Tr
     borderBottomColor={'brand.buttonHover'}
     borderBottomWidth={1.5}
     >
<Td maxW={170} overflowX='auto' p={0} wordBreak='break-word'>
<Link to={`/users-submissions/${campginId}`}>
    <Box display={'flex'} alignItems='center'>
    <Text textAlign='center' pl={5} fontSize={17} fontWeight='semibold' as='span'
_hover={{
  textDecoration:'underline'
}}
>
   {campaignName} 
</Text>
<GrFormNextLink size={20} />
    </Box>
        </Link>
</Td>
<Td>
<Text textAlign='center' fontSize={17} fontWeight='semibold'>
{submissionsNumber}
</Text>
</Td>
</Tr>
    </>
}


      const fetchCampaigns = () => {
        setIsFetching(true)
        axios.get(`${BASEURL}/campaigns/all-compaigns`,{
            params : {
                campaignsListLength:campaignsList.length,
                accessToken:user.accessToken
            }
        })
        .then(res=>{
            if(campaignsList.length > 0 && res.data.results.campaignsListAnalytics == 0) setNoMoreCampaigns(true)
            setCampaingsList([...campaignsList,...res.data.results.campaignsListAnalytics])
        }).then(()=>setIsFetching(false)).catch(err=>{
            console.log('fetch campaigns error',err)
        })
    }

      const Loading = () => {
        return <Flex align='center' justify={'center'} p={5}>
        <Spinner />                        
        </Flex>
    }
    onAuthStateChanged(auth,currentUser=>{
      setUser(currentUser)
  })
  
    React.useEffect(()=>{
      if(user) fetchCampaigns()
  },[user])    
      return (
          <Box>
           {isFetching ? <Loading /> : <>
           <TableContainer overflow={{lg:'hidden'}}>
  <Table variant={'unstyled'} size="lg" className='campaignsTable' >
    <Thead
    bgColor='brand.button'
    px={4}
    borderRadius={20}
    color='#fff'
    >
      <Tr>
        <Th style={{paddingLeft:'10px !important'}}>
            <Text pl={5} textAlign='left' fontWeight='semibold' fontSize={16}>Campaign Name</Text>
        </Th>
        <Th>
            <Text textAlign='center' fontWeight='semibold' fontSize={16}>NO OF SUBMISSION</Text>
        </Th>
      </Tr>
    </Thead>
    <Tbody>
    
    {campaignsList.map(campaign=>{
            return <DynamicTr submissionsNumber={campaign['submissionsNumber']} campaignName={campaign['campaign_name']} campginId={campaign['campaign_id']}/>
           })}
    </Tbody>
  </Table>
</TableContainer>
           {campaignsList.length == 0 && <Text>No Campaigns!</Text>}
           {(noMoreCampaigns == false && campaignsList.length != 0) && <Box display={'flex'} justifyContent='center' alignItems={'center'}>
            <Button
            isLoading={isFetching}
            loadingText='Loading'
            bgColor={'brand.button'}
            color='#fff'
            variant='solid'
            _hover={{
                bgColor:'brand.buttonHover'
            }}
            my={2}
            spinnerPlacement='start'
            size='lg'
            onClick={()=>fetchCampaigns()}
            >Load More</Button>
        </Box>}
           </>}
          </Box>
    );
}
 
export default Campaigns;