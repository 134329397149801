import {
    Box, Text, FormControl,
    FormLabel,
    FormHelperText,
    Flex,Input,Select, Textarea,Switch,
    Button,
    SimpleGrid,
    useDisclosure,Fade,
InputGroup,InputLeftElement,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useToast,
    Image,
    CircularProgress,
    CircularProgressLabel,
    Checkbox,
    IconButton
} from '@chakra-ui/react';
import React,{useState,useContext} from 'react'
import { FiCheck,FiThumbsUp,FiThumbsDown,FiChevronLeft,FiChevronRight,FiLink,FiArrowDown,FiXCircle } from 'react-icons/fi'
import axios from 'axios'
import { BASEURL } from '../../../api';
import { onAuthStateChanged } from '@firebase/auth'
import { auth } from '../../../api/firebase-config';
import { UserContext } from '../../../api';
import {useDropzone} from 'react-dropzone'
import { useHistory } from 'react-router-dom'
import AWS from 'aws-sdk';
import Metadata from '../../../Components/Metadata';
const ShortUniqueId = require('short-unique-id')

window.Buffer = window.Buffer || require("buffer").Buffer;

const CreateCampaign = ({setActiveIndex}) => {
    let history = useHistory()
    const containerStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: 'orange',
        borderStyle: 'dashed',
        backgroundColor: 'white',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
      };
      const dropParams = {
        accept: 'image/jpeg,image/png',
        maxFiles: 1,
        maxSize: 3145728
    }
    const onDrop = React.useCallback((acceptedFiles) => {
        let activeFile = acceptedFiles[0]
        uploadCampaginImage(activeFile)
      }, [])
      const {getRootProps, getInputProps} = useDropzone({onDrop,...dropParams})


    const { setActivePage,setCurrentStep,currentStep,setCampaignQrId,campaignQrId } = useContext(UserContext)
    
    const toast = useToast()
    const [user,setUser] = React.useState(null)
    const [mediaUploaded,setMediaUploaded] = React.useState(false)
    const [isUplaodingCampaignImage,setIsUploadingCampaginImage] = React.useState(false)
    const [localImageSrc,setLocalImageSrc] = React.useState(null)
    const [progress,setProgress] = React.useState(0)
    const [campaignImage,setCampaignImage] = React.useState('')
    const [campaignSansImage,setCampaignSansImage] = React.useState(false)
    const [subject,setSubject] = React.useState('')
    const [subjectErrMssg,setSubjectErrMssg] = React.useState('')
    const showToast = (title,description,status,duration) => {
        toast({
            title,
            description,
            status,
            duration,
            isClosable: true,
        })
    }

      // After Submissions
      const [sendAutomatedEmailAfterSubmission,setAutomatedEmailAfterSubmission] = React.useState(false)
    // Page States
 
    const [campaignNameError,setCampaignNameError] = useState('')
    const [campaignTypeError,setCampaignTypeError] = useState('')
    const [campaignDescriptionError,setCampaignDescriptionError] = useState('')
    const [mediaErrMssg,setMediaErrMssg] = React.useState('')
    const [emailTemplate,setEmailTemplate] = React.useState('')

    function TemplateVisualizationModal() {
        const { isOpen, onOpen, onClose } = useDisclosure()
        return (
          <>
            {emailTemplate.length != 0 && <Button 
                variant={'link'}
                colorScheme='orange'
                onClick={onOpen}
                >Visualize</Button>}
      
            <Modal isOpen={isOpen} onClose={onClose} size='full' isCentered>
              <ModalOverlay />
              <ModalContent mt={40} overflowY={'scroll'} minH='fit-content'>
                <ModalHeader>E-mail Template</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                <div dangerouslySetInnerHTML={{__html:emailTemplate}}/>
                </ModalBody>
      
                <ModalFooter>
                  <Button colorScheme='orange' variant={'outline'} mr={3} onClick={onClose}>
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </>
        )
      } 

      onAuthStateChanged(auth,currentUser=>{
        setUser(currentUser)
      })
    const [reviewState,setReviewState] = useState('')
    const [campaignDetails,setCampaignDetails] = useState({
        campaignType:'REVIEW',
        campaignName:'',
        campaignDescription:''
    })
    const [reviewFormSettings,setReviewFormSettings] = useState({
        orderID:true,
        phoneNumber:false,
        whatsappNumber:false,
        modelNumber:false,
        productID:false,
        shipmentNumber:false,
        media:true,
        customMessage:false
    })
    const [warrantyFormSettings,setWarrantyFormSettings] = useState({
        orderID:true,
        phoneNumber:false,
        whatsappNumber:false,
        modelNumber:false,
        productID:false,
        shipmentNumber:false,
        media:false,
        customMessage:false
    })
    const [registrationFormSettings,setResigtrationFormSettings] = useState({
        orderID:false,
        phoneNumber:false,
        whatsappNumber:false,
        modelNumber:false,
        productID:false,
        shipmentNumber:false,
        media:false,
        customMessage:false
    })
    const [thankYouPageSettings,setThankYouPageSettings] = useState({
        heading:'Thank You',
        subheading:'Your submission has been sent successfully.',
        redirectUrl:'',
        redirectButtonText:'Visit',
        isRedirectButtonVisible:true
    })
    const [redirectButtonVisible,setRedirectButtonVisible] = useState(false)
    const [reviewSubmissionHeading,setReviewSubmissionHeading] = useState('Give a Review For This Product.')
    const [registrationSubmissionHeading,setRegistrationSubmissionHeading] = useState('Register This Product.')
    const [WarrantySubmissionHeading,setWarrantySubmissionHeading] = useState('Get a Warranty For This Product.')


    // Page Methods

    const handleSatisfied = () => setReviewState('Satisfied')
    const handleUnSatisfied = () => setReviewState('Unsatisfied')
  
    const handleThankYouPageSettingsChanges = (data) => {
        setThankYouPageSettings({...thankYouPageSettings,...data})
    }

    const handleSubmissionFormChanges = (data) => {
        switch(campaignDetails.campaignType) {
            case 'REVIEW':
                setReviewFormSettings({...reviewFormSettings,...data})
            break
            case 'REGISTRATION':
                setResigtrationFormSettings({...registrationFormSettings,...data})
            break
            case 'WARRANTY':
               setWarrantyFormSettings({...warrantyFormSettings,...data})
            break
            default:
                setReviewFormSettings({...reviewFormSettings,...data})
        }
    }

    const handleRedirectButtonChanges = (switcherState) => {
        setThankYouPageSettings({...thankYouPageSettings,isRedirectButtonVisible:switcherState})
        setRedirectButtonVisible(switcherState)
    }

    const renderFormBooleans = () => {
        switch(campaignDetails.campaignType) {
            case 'REVIEW':
                return reviewFormSettings
            break
            case 'REGISTRATION':
                return registrationFormSettings
            break
            case 'WARRANTY':
                return warrantyFormSettings
            break
            default:
                return reviewFormSettings
        }
    }
    
    const renderFormHeading = () => {
        switch(campaignDetails.campaignType) {
            case 'REVIEW':
                return reviewSubmissionHeading
            break
            case 'REGISTRATION':
                return registrationSubmissionHeading
            break
            case 'WARRANTY':
                return WarrantySubmissionHeading
            break
            default:
                return reviewSubmissionHeading
        }
    }

    const handleFormHeadingChanges = (newHeading) => {
        switch(campaignDetails.campaignType) {
            case 'REVIEW':
                setReviewSubmissionHeading(newHeading)
            break
            case 'REGISTRATION':
                setRegistrationSubmissionHeading(newHeading)
            break
            case 'WARRANTY':
                setWarrantySubmissionHeading(newHeading)
            break
            default:
                setReviewSubmissionHeading(newHeading)
        }
    }


    // Submit Campaign
    const submitCampaign = () => {
        let Errmsg = 'This field cannot be empty.'
        if(thankYouPageSettings.heading.length == 0) setThankYouPageHeadingMissed(Errmsg)
            if(thankYouPageSettings.subheading.length == 0) setThankYouPageSubHeadingMissed(Errmsg)
            if(thankYouPageSettings.heading.length == 0 ||
                thankYouPageSettings.subheading.length == 0
                ){
                   return
                }
        axios.post(`${BASEURL}/campaigns`,{
            sellerId:user.uid,
            ...campaignDetails,
            formHeading:renderFormHeading(),
            ...renderFormBooleans(), // this function returns an object
            ...thankYouPageSettings,
            sendAutomatedEmailAfterSubmission,
            campaignImage,
            campaignQrId, // Generated in Client Side
            campaignSansImage,
            emailTemplate, // Email tamplate
            subject
        }).then((res)=>{
            showToast('Success','Campaign Created Successfully','success',2500)
            history.replace('/campaigns')
        }).catch((err)=>{
            showToast('Error','Email Not Verified Or Disabled!','error',3000)
        })

    }


    const [headingMissedMsg,setIsHeadingMissedMsg] = React.useState('')
    const [thankYouPageHeadingMissed,setThankYouPageHeadingMissed] = React.useState('')
    const [thankYouPageSubHeadingMissed,setThankYouPageSubHeadingMissed] = React.useState('')
    const filterSteps = (currentStep) => {
        let Errmsg = 'This field cannot be empty!'
        // console.log('step count',currentStep)
        
        if(currentStep == 1) {
            if(campaignDetails.campaignType.length == 0) {
                setCampaignTypeError(Errmsg)
            }if(campaignDetails.campaignName.length == 0) {
               setCampaignNameError(Errmsg)
            }if(campaignDetails.campaignDescription.length == 0) {
              setCampaignDescriptionError(Errmsg)
            }
            if(campaignSansImage == false && campaignImage.length == 0) {
                setMediaErrMssg(Errmsg)
            }

            if(campaignDetails.campaignType.length != 0 &&
                campaignDetails.campaignDescription.length != 0 &&
                campaignDetails.campaignName.length != 0 && 
                (campaignSansImage || campaignImage.length != 0)
                ) {
                   setCurrentStep(2)
                }

        }
        if(currentStep == 2) {
            switch(campaignDetails.campaignType) {
                case 'REVIEW':
                    if(reviewSubmissionHeading.length == 0) setIsHeadingMissedMsg(Errmsg)
                    else setCurrentStep(3)
                break
                case 'REGISTRATION':
                    if(registrationSubmissionHeading.length == 0) setIsHeadingMissedMsg(Errmsg)
                    else setCurrentStep(3)
                    break
                case 'WARRANTY':
                    if(WarrantySubmissionHeading.length == 0) setIsHeadingMissedMsg(Errmsg)
                    else setCurrentStep(3)
                    break
                default:
                  
            } 
        }
        if(currentStep == 3) {
            if(thankYouPageSettings.heading.length == 0) setThankYouPageHeadingMissed(Errmsg)
            if(thankYouPageSettings.subheading.length == 0) setThankYouPageSubHeadingMissed(Errmsg)
            if(thankYouPageSettings.heading.length != 0 &&
                thankYouPageSettings.subheading.length != 0
                ){
                submitCampaign()
                }
        }
    }

        const handleInputFocus = () => {
           setCampaignNameError('')
           setCampaignTypeError('')
           setCampaignDescriptionError('')
           setMediaErrMssg('')
        }
       
        function createObjectURL(object) {
            return (window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object);
        }
        const deleteCurrentCampaignImage = () => {
            setMediaUploaded(false)
            setProgress(0)
            setCampaignImage('')
            setLocalImageSrc(null)
            setIsUploadingCampaginImage(false)
        }
        const uploadCampaginImage = (file) => {
            // reset conditions 
            setMediaErrMssg('')
            setIsUploadingCampaginImage(true)
            setMediaUploaded(false)
            setProgress(0)
            setLocalImageSrc(null)
            let fileName = file.name
            
            if(file.size > 3145728){
                showToast('Upload Error','The file size must be less than or equal to 3MB','warning',3000)
                setIsUploadingCampaginImage(false)
                return
            }
            setLocalImageSrc(createObjectURL(file))
            // console.log('changed image',file)
        
            // const s3 = new AWS.S3({
            //     correctClockSkew: true,
            //     endpoint: 'https://s3.wasabisys.com', //use appropriate endpoint as per region of the bucket
            //     region: 'us-west-1',
            //     accessKeyId: process.env.REACT_APP_WASABI_ACCESSKEY,
            //     secretAccessKey: process.env.REACT_APP_WASABI_SECRETKEY,
            //     logger: console,
            //     signatureVersion:'v4',
            //   });
            const s3 = new AWS.S3({
                correctClockSkew: true,
                endpoint: 'https://s3.wasabisys.com', //use appropriate endpoint as per region of the bucket
                region: 'us-west-1',
                accessKeyId: 'QR6BXTUOMNVE2PAL5VKN',
                secretAccessKey:'HHjo3G0ZizdzW0dnT1C73n0vA4sWIKVqLOvSINDq',
                logger: console,
                signatureVersion:'v4',
              });
            const uid = new ShortUniqueId()
          let newUID = uid()
          const uploadRequest = new AWS.S3.ManagedUpload({
            // params: { Bucket: process.env.REACT_APP_WASABI_BUCKETNAME, Key: `${newUID}-${fileName}`, Body: file },
            params: { Bucket: 'sellersmedia', Key: `${newUID}-${fileName}`, Body: file },
            service: s3
          });
        
          uploadRequest.on('httpUploadProgress', function(event) {
            const progressPercentage = Math.floor(event.loaded * 100 / event.total);
            setProgress(progressPercentage)
          });
        
        
          uploadRequest.send(function(err) {
            if (err) {
              console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));
            } else {
                setMediaUploaded(true)
                // let url = `https://s3.us-east-1.wasabisys.com/${process.env.REACT_APP_WASABI_BUCKETNAME}/${newUID}-${fileName}`
                let url = `https://s3.us-east-1.wasabisys.com/${'sellersmedia'}/${newUID}-${fileName}`
                setIsUploadingCampaginImage(false)
                setCampaignImage(url)
                console.log('uploaded=>',url)
            }
          });
        
        }
        const handleThankYouFocus = () => {
            setThankYouPageHeadingMissed('')
            setThankYouPageSubHeadingMissed('')
        }
        
        React.useEffect(()=>{

            setCurrentStep(1)
            setActivePage('CREATECAMPAIGN')

            // Initialize Qr Code Link
            let uid = new ShortUniqueId()
            setCampaignQrId(uid())
        },[])
    return (
       <>
       <Metadata title='Create Campaign'/>
      <Flex
      direction={{base:'column',lg:'row'}}
      gap={{base:'100px',md:0}}
      >
      <Box
      w={{base:'100%',md:'60%'}}
      px={{base:1,sm:10}}
      minH={{base:'100vh',md:((currentStep > 1) ? '70vh' : '100vh')}}
      pos='relative'
       >
       <Text as='h1' fontSize='3xl' m={2} fontWeight='semibold'>Create New Campaign</Text>

        {currentStep == 1 && <Box
          pb={2}
          px={10}
          mt={4}
        >
                <FormControl my={6}>
                <FormLabel htmlFor='campaignType'>
                    <Text color='brand.fieldName' fontSize={20} fontWeight={500}>Campaign type</Text>
                </FormLabel>
                    <Select borderColor='brand.buttonHover' variant={'flushed'} value={campaignDetails.campaignType} id='campaignType' onChange={e=>setCampaignDetails({...campaignDetails,campaignType:e.target.value})}
                     _focus={{
                        borderColor:'brand.buttonHover',
                        borderBottomWidth:3
                    }}
                    >
                        <option value='REVIEW'>Product Review</option>
                        <option value='REGISTRATION'>Product Registration</option>
                        <option value='WARRANTY'>Product Warranty Registration</option>
                    </Select>
                    <FormHelperText  pos="absolute" bottom={-5} color='red.300'>{campaignTypeError}</FormHelperText>
                </FormControl>
                <FormControl my={6}>
                    <FormLabel htmlFor='campaignType'>
                    <Text color='brand.fieldName' fontSize={20} fontWeight={500}>Campaign name</Text>
                    </FormLabel>
                    <Input id='campaignType' type='text' 
                    value={campaignDetails.campaignName}
                    onChange={e=>setCampaignDetails({...campaignDetails,campaignName:e.target.value})}
                    onFocus={()=>handleInputFocus()}
                    variant='flushed'
                    borderColor={'brand.button'}
                    _focus={{
                        borderColor:'brand.buttonHover',
                        borderBottomWidth:3
                    }}
                    />
                    {/* <FormHelperText pos="absolute" bottom={-3} color='red.300'>test</FormHelperText> */}
                    <FormHelperText pos="absolute" bottom={-5} color='red.300'>{campaignNameError}</FormHelperText>
                </FormControl>
             {/* Product Description */}

             <FormControl my={6}>
                <FormLabel htmlFor='productDescription'>
                <Text color='brand.fieldName' fontSize={20} fontWeight={500}>Description</Text>
                    </FormLabel>
                <Textarea 
                value={campaignDetails.campaignDescription}
                placeholder='Short description for your product ....'
                onChange={e=>setCampaignDetails({...campaignDetails,campaignDescription:e.target.value})}
                onFocus={()=>handleInputFocus()}
                maxH={150}
                borderColor={'brand.button'}
                _focus={{
                    borderColor:'brand.buttonHover',
                    borderBottomWidth:3
                }}
                />
                 <FormHelperText  pos="absolute" bottom={-5} color='red.300'>{campaignDescriptionError}</FormHelperText>
            </FormControl>


            {/* Product Images */}
            <FormControl my={2}>
                <FormLabel htmlFor='productImages'>
                <Text color='brand.fieldName' fontSize={20} fontWeight={500}>Product Images</Text>
                    </FormLabel>
                      {(!isUplaodingCampaignImage && mediaUploaded == false) && <>
                        <div {...getRootProps()} style={{ ...containerStyle,textAlign:'center',color:'#ED8936',fontSize:17.5 }}>
                        <input {...getInputProps()} />
                        <FiArrowDown size={23}/>
                        <p>Drag 'n' drop file here<br />or<br /> click to browse file</p>
                        </div>
                        <Checkbox colorScheme='orange' isChecked={campaignSansImage}
                        py={1}
                        onChange={e=>{
                            setCampaignSansImage(e.target.checked)
                            setMediaErrMssg('')
                        }}
                         >Campaign Without Image</Checkbox>
                      </>}
                {/* <Input 
                type='file'
                onChange={e=>handleImageChange(e)}
                /> */}
                {(isUplaodingCampaignImage || mediaUploaded) &&  <Box
                        bgColor={'orange.100'}
                        w={'30%'}
                        pos='relative'
                        mt={3}
                        borderWidth={2}
                        >
                        <IconButton
                        onClick={()=>deleteCurrentCampaignImage()}
                        pos='absolute'
                        style={{
                            backgroundColor:'#F6AD55'
                        }}
                        right={-4}
                        top={-5}
                        zIndex={100}
                        variant='solid'
                        size='sm'
                        icon={<FiXCircle size={23} color='#fff'/>}
                        />
                        {(mediaUploaded && progress == 100) && <Box
                        pos={'absolute'}
                        top='50%'
                        left='50%'
                        bgColor='#fff'
                        style={{transform:'translate(-50%,-50%)'}}
                        borderRadius='full'
                        p={1}
                        boxShadow='md'
                        zIndex={1000}
                        >
                        <FiCheck 
                        size={44}
                        color='orange'
                        />  
                            </Box>}
                       
                        {progress < 100 && <CircularProgress 
                        value={progress} color='orange.400' 
                        pos={'absolute'}
                        top='50%'
                        left='50%'
                        style={{transform:'translate(-50%,-50%)'}}
                        zIndex={1000}
                        >
                            <CircularProgressLabel fontWeight={'semibold'}>{progress}%</CircularProgressLabel>
                        </CircularProgress>}
                       {(localImageSrc != null) && <Image 
                        src={localImageSrc}
                        filter='auto'
                        blur={progress == 100 ? '0px' : '1.5px'}
                        />}
                        </Box>}
               <FormHelperText textAlign={'left'} color="red.300">{mediaErrMssg}</FormHelperText>
            </FormControl>

        </Box>}
            {currentStep == 2 && <Fade in={currentStep == 2 && true}>
        <Box
          py={6}
          px={10}
          my={10}
          rounded='md'
        >
            {/* submissions form details */}
            <Box my={2}>
                <Box w={{base:'100%',md:'50%'}} my={2}>
                <FormControl>
                <FormLabel htmlFor='submissionHeading'>
                    <Text color='brand.fieldName' fontSize={20} fontWeight={500}>Heading</Text>
                </FormLabel>
                    <Input id='submissionHeading' type='text'
                    value={renderFormHeading()}
                    onChange={e=>handleFormHeadingChanges(e.target.value)}
                    onFocus={()=>setIsHeadingMissedMsg('')}
                    variant='flushed'
                    borderColor={'brand.button'}
                    _focus={{
                        borderColor:'brand.buttonHover',
                        borderBottomWidth:3
                    }}
                    />
        <FormHelperText color='red.300'>{headingMissedMsg}</FormHelperText>
                </FormControl>
                </Box>
                {/* <Text as='span' fontSize='lg' my={2}>Extra Fields</Text> */}
                <Flex w={'100%'} gap={5}
                direction={{base:'column',md:'row'}}
                >
                    <SimpleGrid flex={1} 
                    columns={2}
                    maxH={200}
                    spacingX={4}
                    spacingY={7}
                    >
           
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerNameIsVisible' mb='0'>
                        <Text color='brand.fieldName' fontSize={18} fontWeight={500}>Name</Text>
                        </FormLabel>
                        <Switch size='md' colorScheme={'orange'} id='buyerNameIsVisible' isChecked isDisabled/>
                        </Flex>
   
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerAddressIsVisible' mb='0'>
                        <Text color='brand.fieldName' fontSize={18} fontWeight={500}>Email address</Text>
                            </FormLabel>
                        <Switch  colorScheme={'orange'} id='buyerAddressIsVisible' isChecked isDisabled/>
                        </Flex>
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerOrderIdIsVisible' mb='0'>
                        <Text color='brand.fieldName' fontSize={18} fontWeight={500}>Order ID</Text>
                            </FormLabel>
                        <Switch  colorScheme={'orange'} id='buyerOrderIdIsVisible' isChecked={renderFormBooleans().orderID} isDisabled={renderFormBooleans().orderID}
                        onChange={(e)=>handleSubmissionFormChanges({orderID:e.target.checked})}
                        />
                        </Flex>
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerPhoneNumberIsVisible' mb='0'>
                        <Text color='brand.fieldName' fontSize={18} fontWeight={500}>Phone Number</Text>
                            </FormLabel>
                        <Switch  colorScheme={'orange'} id='buyerPhoneNumberIsVisible' isChecked={renderFormBooleans().phoneNumber}
                        onChange={(e)=>handleSubmissionFormChanges({phoneNumber:e.target.checked})}
                        />
                        </Flex>
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerWhatsappNumberIsVisible' mb='0'>
                        <Text color='brand.fieldName' fontSize={18} fontWeight={500}>Whatsapp Number</Text>
                           </FormLabel>
                        <Switch colorScheme={'orange'} id='buyerWhatsappNumberIsVisible' isChecked={renderFormBooleans().whatsappNumber}
                        onChange={(e)=>handleSubmissionFormChanges({whatsappNumber:e.target.checked})}
                        />
                        </Flex>
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerModelNumberIsVisible' mb='0'>
                        <Text color='brand.fieldName' fontSize={18} fontWeight={500}>Model Number</Text>
                           </FormLabel>
                        <Switch  colorScheme={'orange'} id='buyerModelNumberIsVisible' isChecked={renderFormBooleans().modelNumber}
                        onChange={(e)=>handleSubmissionFormChanges({modelNumber:e.target.checked})}
                        />
                        </Flex>
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerProductIdIsVisible' mb='0'>
                        <Text color='brand.fieldName' fontSize={18} fontWeight={500}>Product ID</Text>
                            </FormLabel>
                        <Switch  colorScheme={'orange'} id='buyerProductIdIsVisible' isChecked={renderFormBooleans().productID}
                        onChange={(e)=>handleSubmissionFormChanges({productID:e.target.checked})}
                        />
                        </Flex>
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerShipmentNumberIsVisible' mb='0'>
                        <Text color='brand.fieldName' fontSize={18} fontWeight={500}>Shipment Number</Text>
                           </FormLabel>
                        <Switch colorScheme={'orange'} id='buyerShipmentNumberIsVisible' isChecked={renderFormBooleans().shipmentNumber}
                        onChange={(e)=>handleSubmissionFormChanges({shipmentNumber:e.target.checked})}
                        />
                        </Flex>
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerImageIsVisible' mb='0'>
                        <Text color='brand.fieldName' fontSize={18} fontWeight={500}>Include Image</Text>
                            </FormLabel>
                        <Switch colorScheme={'orange'} id='buyerImageIsVisible' isChecked={renderFormBooleans().media} isDisabled={campaignDetails.campaignType == 'REVIEW' ? true : false}
                         onChange={(e)=>handleSubmissionFormChanges({media:e.target.checked})}
                        />
                        </Flex>
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerCustomMessageIsVisible' mb='0'>
                        <Text color='brand.fieldName' fontSize={18} fontWeight={500}>Custom Message</Text>
                            </FormLabel>
                        <Switch colorScheme={'orange'} id='buyerCustomMessageIsVisible' isChecked={renderFormBooleans().customMessage}
                         onChange={(e)=>handleSubmissionFormChanges({customMessage:e.target.checked})}
                        />
                        </Flex>
                  
                    </SimpleGrid>
                </Flex>
            </Box>
        </Box>
      </Fade>}
            {currentStep == 3 && <Fade in={currentStep == 3 && true}>
        <Box
         py={6}
         px={10}
          mt={4}
        >
         {/* Thank You Page Settings */}
         <Box my={2}>
                <Flex w={'100%'}
                direction={{base:'column',md:'row'}}
                   gap={5}>
                    <Box flex={1} 
                    >
                    <FormControl>
                        <FormLabel htmlFor='thankYouPageHeading'>
                        <Text color='brand.fieldName' fontSize={20} fontWeight={500}>Heading</Text>
                        </FormLabel>
                        <Input id='thankYouPageHeading' type='text'
                        value={thankYouPageSettings.heading}
                        onFocus={()=>handleThankYouFocus()}
                        onChange={(e)=>handleThankYouPageSettingsChanges({heading:e.target.value})}
                        variant='flushed'
                        borderColor={'brand.button'}
                        _focus={{
                            borderColor:'brand.buttonHover',
                            borderBottomWidth:3
                        }}
                       />
                          <FormHelperText color='red.300'>{thankYouPageHeadingMissed}</FormHelperText>
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor='thankYouPageSubheading'>
                        <Text color='brand.fieldName' fontSize={20} fontWeight={500}>Subheading</Text>
                            </FormLabel>
                        <Input id='thankYouPageSubheading' type='text'
                         value={thankYouPageSettings.subheading}
                         onFocus={()=>handleThankYouFocus()}
                         onChange={(e)=>handleThankYouPageSettingsChanges({subheading:e.target.value})}
                         variant='flushed'
                         borderColor={'brand.button'}
                         _focus={{
                             borderColor:'brand.buttonHover',
                             borderBottomWidth:3
                         }}
                        />
                         <FormHelperText color='red.300'>{thankYouPageSubHeadingMissed}</FormHelperText>
                    </FormControl>
                            <FormControl my={2}>
                        <FormLabel htmlFor='redirect_url'>
                        <Text color='brand.fieldName' fontSize={20} fontWeight={500}>URL</Text>
                            </FormLabel>
                            <InputGroup>
                                 <InputLeftElement
                                   pointerEvents='none'
                                   children={<FiLink color='#9C4221' />}
                                 />
                                 <Input id='redirect_url' type='text'
                         value={thankYouPageSettings.redirectUrl}
                         onChange={(e)=>handleThankYouPageSettingsChanges({redirectUrl:e.target.value})}
                         variant='flushed'
                         borderColor={'brand.button'}
                         _focus={{
                             borderColor:'brand.buttonHover',
                             borderBottomWidth:3
                         }}
                        />
                               </InputGroup>
                       
                    </FormControl>
                        <FormControl>
                        <FormLabel htmlFor='redirect_button_text'>
                        <Text color='brand.fieldName' fontSize={20} fontWeight={500}>Redirect Button Text</Text>
                            </FormLabel>
                        <Input id='redirect_button_text' type='text'
                         value={thankYouPageSettings.redirectButtonText}
                         onChange={(e)=>handleThankYouPageSettingsChanges({redirectButtonText:e.target.value})}
                         variant='flushed'
                         borderColor={'brand.button'}
                         _focus={{
                             borderColor:'brand.buttonHover',
                             borderBottomWidth:3
                         }}
                        />
                    </FormControl>
                        {/* </>} */}
                        {/* {campaignDetails.campaignType == 'REVIEW' && <> */}
                       <Flex gap={10}>
                       {/* <Flex alignItems={"center"} justify='center'>
                        <FormLabel htmlFor='auto_approval' mb='0'>
                        <Text color='brand.fieldName' fontSize={18} fontWeight={500}>Auto Approval</Text>
                            </FormLabel>
                        <Switch colorScheme={'orange'} id='auto_approval' onChange={(e)=>console.log('')} />
                        </Flex> */}
                        <Flex alignItems={"center"} justify='center' my={2}>
                        <FormLabel htmlFor='automated_email' mb='0'>
                        <Text color='brand.fieldName' fontSize={18} fontWeight={500}>Auto Mail</Text>
                            </FormLabel>
                        <Switch colorScheme={'orange'} id='automated_email' isChecked={sendAutomatedEmailAfterSubmission} onChange={(e)=>setAutomatedEmailAfterSubmission(e.target.checked)} />
                        </Flex>
                    
                       </Flex>
                       {sendAutomatedEmailAfterSubmission &&  <FormControl mb={6}>
                    <FormLabel htmlFor='emailSubject'>
                    <Text color='brand.fieldName' fontSize={20} fontWeight={500}>E-mail Subject</Text>
                    </FormLabel>
                    <Input id='emailSubject' type='text' 
                    onChange={e=>setSubject(e.target.value)}
                    onFocus={()=>setSubjectErrMssg('')}
                    variant='outline'
                    borderColor={'brand.button'}
                    _focus={{
                        borderColor:'brand.buttonHover',
                        borderBottomWidth:3
                    }}
                    />
                    {/* <FormHelperText pos="absolute" bottom={-3} color='red.300'>test</FormHelperText> */}
                    {/* <FormHelperText pos="absolute" bottom={-5} color='red.300'>{'test'}</FormHelperText> */}
                </FormControl>}
                        {/* </>} */}
                       
                    </Box>     
                </Flex>
            </Box>
        </Box>
      </Fade>}

        <Box pos={currentStep > 1 && 'absolute'} bottom={{base:'-40px',md:0}} right={0} left={0}>
        <Box display="flex" gap={4}
        justifyContent={'center'}
        my={4}
        >
         {currentStep > 1 &&  <Button 
                bgColor='brand.button'
                leftIcon={<FiChevronLeft />}
                py={4}
                _hover={{
                    bgColor:'brand.buttonHover'
                }}
                color='white' variant="solid"
                onClick={()=>setCurrentStep(currentStep-1)}
                >
                 Back
                </Button>}
        {currentStep <= 3 &&  <Button 
                bgColor='brand.button'
                rightIcon={<FiChevronRight />}
                py={4}
                _hover={{
                    bgColor:'brand.buttonHover'
                }}
                color='white' variant="solid"
                onClick={()=>filterSteps(currentStep)}
                >
                 {currentStep == 3 ? 'Save' : 'Next'}
                </Button>}
        </Box>
        </Box>
       </Box>
       {currentStep > 1 &&  <Box
        borderBottomLeftRadius={25}
        borderTopLeftRadius={25}
        bgColor='#FEEBCB'
        style={{
            height:'calc(100vh - 72px)',
            maxHeight: 'calc(100vh - 72px)'
        }}
        // mt={10}
        py={4}
        w={{base:'100%',md:'40%'}}
        // px={{base:1,sm:10}}
       overflowY='auto'
        >
            {currentStep == 3 &&  <Box
                    textAlign={"center"}
                    display='flex'
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems='center'
                    h={'100%'}
                    w={'100%'}
                    >
                        <Flex direction={'column'} alignItems='center'
                        bgColor='#fff'
                        w={{base:'90%',md:'80%'}}
                        maxW={{base:'90%',md:'80%'}}
                        wordBreak='break-word'
                        my={2}
                        minH={300}
                        maxH={300}
                        overflowY='hidden'
                        borderRadius={10}
                        py={4}
                        boxShadow='base'
                        >
                        <Box>
                        <Text as="span"><FiCheck size={60}/></Text>
                        </Box>
                        <Box>
                            <Text as="h1" fontSize={32} fontWeight='semibold'>{thankYouPageSettings.heading}</Text>
                            <Text as="h2" fontSize={22} fontWeight='semibold'>{thankYouPageSettings.subheading}</Text>
                        </Box>
                        <Box my={2}>
                        <Button 
                bgColor='brand.button'
                py={4}
                _hover={{
                    bgColor:'brand.button'
                }}
                color='white' variant="solid"
                >
                {thankYouPageSettings.redirectButtonText}
                </Button>
                        </Box>
                        </Flex>

                        {sendAutomatedEmailAfterSubmission && <>
                            <Flex w={{base:'90%',md:'80%'}}>
                            <FormControl my={2}>
                        <FormLabel display='flex' alignItems='center' justifyContent={'space-between'} mb='0'>
                        <Text>E-Mail Template</Text>
                        <Button variant={'link'} colorScheme='orange'>
                            <TemplateVisualizationModal />
                        </Button>
                        </FormLabel>
                        <Textarea
                        variant={'filled'}
                        bgColor='#fff'
                        minH={200}
                        borderColor='brand.button'
                        _focus={{
                          borderColor:'brand.buttonHover'
                        }}
                        onChange={e=>setEmailTemplate(e.target.value)}
                        >

                        </Textarea>
                        </FormControl>
                            </Flex>
                        </>}
                    </Box>}
            {currentStep == 2 && <Box
                    textAlign={"center"}
                    display='flex'
                    justifyContent={'center'}
                    alignItems='center'
                    mt={{base:10,md:0}}
                    minH={'90vh'}

                    >
                        <Flex direction={'column'}
                        w={{base:'90%',md:'80%'}}
                        bgColor='#fff'
                        py={3}
                        px={{base:2,md:5}}
                        borderRadius={10}
                        boxShadow='base'
                        >
                        <Box w={'60%'} textAlign='left'>
                        <Text as="span"
                        fontSize={22}
                        fontWeight='semibold'
                        >
                       {renderFormHeading()}
                        </Text>
                        </Box>
                       {campaignDetails.campaignType == 'REVIEW' && <Box>
                       <Box
                        display='flex'
                        justifyContent={'center'}
                        py={4}
                        gap={4}
                        >
                            <FiThumbsUp size={40} onClick={()=>handleSatisfied()}
                            color={reviewState == 'Satisfied' && '#FAC405'}
                            className='thumb_down_up'
                            />
                            <FiThumbsDown size={40} onClick={()=>handleUnSatisfied()}
                            color={reviewState == 'Unsatisfied' && '#FAC405'}
                            className='thumb_down_up'
                            />
                        </Box>
                        <Text textAlign={'center'}>{reviewState}</Text>
                       </Box>}
                        <Box> 
                        <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Name</FormLabel>
                        <Input id='campaignType' type='text' />
                        </FormControl>
                        <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Email address</FormLabel>
                        <Input id='campaignType' type='text' />
                        </FormControl>
                        {renderFormBooleans().phoneNumber && <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Phone Number</FormLabel>
                        <Input id='campaignType' type='text' />
                        </FormControl>}
                        {renderFormBooleans().whatsappNumber &&  <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Whatsapp Number</FormLabel>
                        <Input id='campaignType' type='text' />
                        </FormControl>}
                       {renderFormBooleans().orderID && <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Order ID</FormLabel>
                        <Input id='campaignType' type='text' />
                        </FormControl>}
                       {(campaignDetails.campaignType == 'REVIEW' && reviewState == 'Unsatisfied') && <FormControl isRequired>
                        <FormLabel htmlFor='negative_review'>Write a review</FormLabel>
                        <Textarea 
                        id="negative_review"
                        placeholder='Tell us what went wrong and how we can improve'
                        />
                        </FormControl>}
                        {renderFormBooleans().productID && <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Product ID</FormLabel>
                        <Input id='campaignType' type='text' />
                        </FormControl>}
                        {renderFormBooleans().modelNumber && <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Model Number</FormLabel>
                        <Input id='campaignType' type='text' />
                        </FormControl>}
                        {renderFormBooleans().shipmentNumber && <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Shipment Number</FormLabel>
                        <Input id='campaignType' type='text' />
                        </FormControl>}
                        {renderFormBooleans().media && <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Include a screenshot of the review</FormLabel>
                        <Input id='campaignType' type='file' />
                        </FormControl>}
                        {renderFormBooleans().customMessage && <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Custom Message</FormLabel>
                        <Textarea />
                        </FormControl>}
                        <Box my={2}>
                        <Button 
                bgColor='brand.button'
                w={'full'}
                py={4}
                _hover={{
                    bgColor:'brand.button'
                }}
                color='white' variant="solid"
                >
                 Submit
                </Button>
                        </Box>
                        </Box>
                        </Flex>
                    </Box>}
        </Box>}
      </Flex>
       </>
    );
}

export default CreateCampaign;