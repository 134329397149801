import React, { useContext, useState } from 'react';
import {
    Flex,
    Heading,
    FormControl,
    Input,
    Text,
    Image,
    Center
} from '@chakra-ui/react';
import { Box,Button,Progress,
    FormLabel,
    FormHelperText } from '@chakra-ui/react';
import { applyActionCode,verifyPasswordResetCode, confirmPasswordReset } from '@firebase/auth'
import { auth } from '../../api/firebase-config';
import { Link } from 'react-router-dom';
import { BASEURL } from '../../api';
import {
    signInWithPopup,
    getAdditionalUserInfo,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    onAuthStateChanged
} from '@firebase/auth'
import { FiArrowLeft } from 'react-icons/fi'
import { authProvider } from '../../api/firebase-config';
import {  UserContext } from '../../api';
import { useHistory } from 'react-router-dom';
import { sendPasswordResetEmail,signOut } from '@firebase/auth'
import { FcGoogle } from 'react-icons/fc'
import axios from 'axios';
import Metadata from '../../Components/Metadata';

const NewAuth = () => {
    const [user, setUser] = React.useState(null)

    onAuthStateChanged(auth, currentUser => {
        setUser(currentUser)
    })

    // Email Verification
    const [isVerifying,setIsVerifying] = React.useState(true)
    const [resultMessage,setResultMessage] = React.useState('')
    const [isVerified,setIsVerified] = useState(false)
    const [isExpiredLink,setExpiredLink] = useState(false)
    // Password Reset
    const [newPassword,setNewPassword] = React.useState('')
    const [passwordErrorMessage,setPasswordErrorMessage] = React.useState('')
    const [passwordResetSuccessMessage,setPasswordResetSuccessMessage] = React.useState('')
    const [isPasswordReset,setIsPasswordReset] = React.useState(false)
    const getoobCode = (url) =>{
        let oobString
        url.split('&').forEach(str=>{
        if(str.includes('oobCode')) oobString = str.split('=')[1]
        })
        console.log('oobstring',oobString)
        return oobString
    }

    function handleVerifyEmail(auth, actionCode) {

            applyActionCode(auth, actionCode).then((resp) => {
                console.log('resp',resp)
                onAuthStateChanged(auth,currentUser=>{
                axios.post(`${BASEURL}/sellers/activate_email`,{
                    uid : currentUser.uid
                }).then(res=>{
                    console.log(res.data)
                }).then(()=>{
                setIsVerified(true)
                setResultMessage('Your email has been verified.')
                }).then(()=>{
                    //sign out
                    signOut(auth)
                    localStorage.clear()
                    // setTimeout(()=>{
                    //     window.location.reload()
                    // },2000)
                })
                })
                 
            }).catch((error) => {
             setExpiredLink(true)
             setResultMessage('The verification link is invalid or expired.')
            }).finally(()=>setIsVerifying(false))
           }

    function handlePasswordReset(auth, actionCode) {
            
          verifyPasswordResetCode(auth, actionCode).then((email) => {
            confirmPasswordReset(auth, actionCode, newPassword).then((resp) => {
               setIsPasswordReset(true)
            }).catch((error) => {
              setPasswordErrorMessage('Your password is weak or too short.')
            });
          }).catch((error) => {
            setPasswordErrorMessage('The reset link is expired! Try again')
          });
        }

      
      const currentAction = () => {
        let current = window.location.href.split('?')[1].split('&')[0].split('=')[1]
        return current
    }

      React.useEffect(()=>{

       if(user) {
        console.log(user)
        if(currentAction() == 'verifyEmail') handleVerifyEmail(auth,getoobCode(window.location.href))
       }

      },[user])
    return (
        <Flex
        minH={'100vh'}
        >
            <Flex
            w={'60%'}
            bgColor="brand.button"
            align='center'
            justify='center'
            >
                 <Image 
        src='/images/login.png'
        w={600}
        objectFit='cover'
        />
            </Flex>
          <Flex 
          align={'center'}
          justify='center'
        //   bgColor='red.200'
          w='40%'
          >
   <Box
    // boxShadow={'md'}
    >
        {/* Email Verification */}
        {currentAction() == 'verifyEmail' && <Box>
        <Box w={'100%'}>
    {isVerifying && <Progress size='xs' colorScheme='orange' isIndeterminate />}
    </Box>
    <Box
     display="flex"
     flexDirection={'column'}
     alignItems='center'
    py={7}
    px={7}
    >
    <Text as="h1" fontSize='lg' mb={4}>
        {isVerifying && 'Please wait ... We are verifiying'}
        {resultMessage}
    </Text>
    <Text as="h2" fontWeight={'semibold'}>
        {!isVerifying && isVerified && <>
            Please <Button variant={'link'} as={Link} to='/auth' color='brand.button'>Sign in</Button> Again
        </>}
        {!isVerifying && isExpiredLink && <>
            Need help? <Button variant={'link'} as={Link} to='/contact' color='brand.button'>Contact Us</Button>
        </>}
    </Text>
    </Box>
        </Box>}

         {/* Password Reset */}
        {currentAction() == 'resetPassword' && <Box>
    <Box>
        {!isPasswordReset ? <Box
        display="flex"
        flexDirection={'column'}
        alignItems='flex-start'
        minW={400}
       py={7}
       px={7}
       my={2}
        >
       <Box my={3}>
       <Heading as="h1" ml={0} textAlign='left'>Set new password</Heading>
       <Heading color='blackAlpha.600' py={4} as='h2'textAlign='left' fontSize={'md'}>Your new password must be different</Heading>
       </Box>
    <FormControl>
     <FormLabel htmlFor='email'>New password</FormLabel>
    <Input onFocus={()=>setPasswordErrorMessage('')} id='password' type='password' onChange={(e)=>setNewPassword(e.target.value)}/>
    <FormHelperText color='red.400'>{passwordErrorMessage}</FormHelperText>
    </FormControl>
    <Button bgColor={'brand.button'} _hover={{bgColor:'brand.buttonHover'}} color='white' w={'full'} my={2} onClick={()=>handlePasswordReset(auth,getoobCode(window.location.href))}>
        Reset Password
    </Button>
    <Center w={'100%'}>
    <Button as={Link} to='/auth' variant={'link'} my={4} leftIcon={<FiArrowLeft size={20} />}>Back To Log in</Button>
    </Center>
        </Box> : <Box
        display="flex"
        flexDirection={'column'}
        alignItems='center'
       py={7}
       px={7}
       my={2}
        >
        <Text as="h1" fontSize='lg' mb={4} textAlign='center'>
        Password changed successfully.
    </Text>
    <Button variant={'ghost'} colorScheme='orange' as={Link} to='/auth'>Sign in</Button>
        </Box>}
    </Box>

    </Box>}
    
        </Box>
          </Flex>
        </Flex>
    );
}
 
export default NewAuth;