import React, { useEffect, useState } from 'react'
import {
    Button,
    Box, 
    Text,
    Divider,
    SimpleGrid,
    FormControl,
    FormLabel,
    Input,
    useToast
} from '@chakra-ui/react'
import { auth } from '../../api/firebase-config'
import { onAuthStateChanged } from '@firebase/auth'
import axios from 'axios'
import { BASEURL } from './../../api'

const Plans = () => {
    const toast = useToast()
    const [freePlanLimits,setFreePlanLimits] = React.useState(null)
    const [paidPlanLimits,setPaidPlanLimits] = React.useState(null)


    const showToast = (title,description,status,duration) => {
        toast({
            title,
            description,
            status,
            duration,
            isClosable: true,
        })
    }


    const [user,setUser] = React.useState(null)
    const fetchPlansLimits = () => {
        axios.get(`${BASEURL}/plans/all-plans`,{
            params:{
                accessToken:user.accessToken
            }
        }).then(res=>{
            const { free_per_day,paid_per_month } = res.data
            setFreePlanLimits(free_per_day)
            setPaidPlanLimits(paid_per_month)
            console.log(res.data)
        })
    }

    const editPlansLimits = (type) => {
        axios.post(`${BASEURL}/plans/edit-plans-limits`,{
            planType:type,
            limit:(type == 'free' ? freePlanLimits : paidPlanLimits)
        },{
            params:{
                accessToken:user.accessToken
            }
        }).then(res=>{
            showToast('Success',`${type} plan updated successfully`,'success',2000)
        }).catch(()=>{
            showToast('Error',`Cannot update ${type} plan, please try again`,'error',2000)
        })
    }
    onAuthStateChanged(auth,currentUser=>{
        setUser(currentUser)
    })

    useEffect(()=>{
        if(user) fetchPlansLimits()
    },[user])
    return (

        <Box>
            <Text as="h1" fontSize='2xl' mb={3} fontWeight='bold'>Plans Management</Text>
            <Divider />
            <Box my={5}>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacingX={10} spacingY={7}>
                    <Box>
                        <Text as="h2" fontSize={'xl'}>
                            Free Plan
                        </Text>
                        <FormControl>
                            <FormLabel>Number Of Emails / Day</FormLabel>
                            <Input type='number' variant='flushed'
                                borderColor={'brand.button'}
                                _focus={{
                                    borderColor:'brand.buttonHover',
                                    borderBottomWidth:3
                                }}
                            value={freePlanLimits}
                            onChange={(e)=>setFreePlanLimits(e.target.value)}
                             />
                        </FormControl>
                        <Button  bgColor={'brand.button'}
                         color='#fff'
                         variant='solid'
                         _hover={{
                             bgColor:'brand.buttonHover'
                         }} my={1.5}
                        onClick={()=>editPlansLimits('free')}
                        >
                            Update
                        </Button>
                    </Box>
                    <Box>
                        <Text as="h2" fontSize={'xl'}>
                            Paid Plan
                        </Text>
                        <FormControl>
                            <FormLabel>Number Of Emails / Month</FormLabel>
                            <Input type='number' variant='flushed'
                                borderColor={'brand.button'}
                                _focus={{
                                    borderColor:'brand.buttonHover',
                                    borderBottomWidth:3
                                }}
                             value={paidPlanLimits}
                             onChange={(e)=>setPaidPlanLimits(e.target.value)}
                            />
                        </FormControl>
                        <Button  bgColor={'brand.button'}
                         color='#fff'
                         variant='solid'
                         _hover={{
                             bgColor:'brand.buttonHover'
                         }} my={1.5}
                        onClick={()=>editPlansLimits('paid')}
                        >
                            Update
                        </Button>
                    </Box>
                </SimpleGrid>
            </Box>
        </Box>

    );
}

export default Plans;