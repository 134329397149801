import React,{useContext} from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Text,Box,Flex,useToast, Spinner, Image 
  } from '@chakra-ui/react'
import { FiCopy } from 'react-icons/fi'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { useParams } from 'react-router-dom';
import { BASEURL } from '../../api';
import axios from 'axios'
// import { auth } from '../../api/firebase-config';
import { onAuthStateChanged } from '@firebase/auth'
import { DateTime } from 'luxon';

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { auth } from '../../api/firebase-config'
import { UserContext } from '../../api';
import Metadata from '../../Components/Metadata';
const SubmissionsDetails = () => {
  
    const { campaignId } = useParams()

    const [areSubmissionsFetched,setAreSubmissionsFetched] = React.useState(false)
    const [submissionsList,setSubmissionsList] = React.useState([])
    const [totalSubmissions,setTotalSubmissions] = React.useState(null)
    const [submissionsType,setSubmissionsType] = React.useState('')
    const toast = useToast()
    const showToast = (data) => {
        toast({
            title: data,
            status: 'success',
            duration: 1500,
            isClosable: true,
        })
    }
    const DataRecord = ({type,value}) => {
        return <Flex
        justify={'space-between'}
        my={5}
        px={10}
        align='center'
        >
           <Box>
           <Text>{type}</Text>
           </Box>
           <Box
           maxW={{base:'20ch',md:'30ch'}}
           wordBreak={'break-all'}
           >
            {type == 'Buyer Screenshot' ? <>
            <Zoom>
            <Image 
            src={value}
            />
            </Zoom>
                </> : <Flex>
            <Text>{value}</Text>      
        <CopyToClipboard text={value}
          onCopy={() => showToast('Copied to clipboard.')}>
           <FiCopy size={20} style={{position:'relative',top:-2,marginLeft:2}}
            />
        </CopyToClipboard>
            </Flex>}
           </Box>
        </Flex>
    }

    const BuyerInfo  = ({submission}) => {
        const { 
        custom_message,
        email,
        media,
        model_number,
        name,
        negative_review,
        order_id,
        phone_number,
        product_id,
        shipment_number,
        submission_id,
        country,
        review_state,
        submission_type,
        whatsapp_number,
        submitted_at
     } = submission

     const renderTime = (time) => {
        const date = DateTime.fromISO(time)
        return (!date.isValid ? '' : date.toLocaleString(DateTime.DATETIME_SHORT))
     }
        return <>
        <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex='1' textAlign='left'>
            {name} - <Text fontSize={'sm'} as="span">at : {renderTime(submitted_at)}</Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
            <Box display='flex' flexDirection={{base:'column',md:'row'}}>
                <Box  w={{base:'100%',md:'50%'}}>
                    <Text as="h3" fontSize='lg' color='brand.button'>Buyer Details</Text>
                    {name.length && <DataRecord type={'Name'} value={name} />}
                    <DataRecord type={'Email'} value={email} />
                    {phone_number && <DataRecord type={'Phone Number'} value={phone_number} />}
                    {country && <DataRecord type={'Country'} value={country} />}
                    {whatsapp_number && <DataRecord type={'Whatsapp Number'} value={whatsapp_number} />}
    
                </Box>
                {/* <Divider /> */}
                <Box  w={{base:'100%',md:'50%'}}>
                <Text as="h3" fontSize='lg' color='brand.button'>Order Details</Text>
                   {order_id && <DataRecord type={'Order ID'} value={order_id} />}
                    {product_id && <DataRecord type={'Product ID'} value={product_id} />}
                    {model_number && <DataRecord type={'Model Number'} value={model_number} />}
                    {shipment_number && <DataRecord type={'Shipment Number'} value={shipment_number} />}
                    {media && <DataRecord type={'Buyer Screenshot'} value={media} />}
                    {custom_message && <DataRecord type={'Custom Message'} value={custom_message} />}
                    {submission_type == 'REVIEW' && <DataRecord type={'Review State'} value={review_state} />}
                    {submission_type == 'REVIEW' && <>
                        {negative_review.length != 0 && <DataRecord type={'Buyer Review'} value={negative_review} />}
                    </>}
                </Box>
            </Box>
        </AccordionPanel>
      </AccordionItem>
        </>
    }
    const renderSubmissionsType = (type) => {
        let firstLetter = type.split('')[0].toUpperCase()
        let typeInLowerArr = type.toLowerCase().split('') 
        typeInLowerArr[0] = firstLetter
        return typeInLowerArr.join('')
    }
    const [user,setUser] = React.useState(null)
    onAuthStateChanged(auth,currentUser=>{
        setUser(currentUser)
        console.log('user signed in',currentUser)
    })
    const fetchSubmissions = () => {
        // pass the seller Id
        axios.get(`${BASEURL}/submissions/all-submissions/${campaignId}`,{
            params:{
                accessToken:user.accessToken
            }
        })
        .then(res=>{
            console.log(res.data)
            setSubmissionsList(res.data.results.submissions)
            if(res.data.results.submissions.length != 0) {
                setSubmissionsType(res.data.results.submissions[0]['submission_type'])
                setTotalSubmissions(res.data.results.totalAnalytics.TotalSubmissions)
            }
            setAreSubmissionsFetched(true)
        }).catch(err=>{
            console.log(err)
        })
    }

    React.useEffect(()=>{
        if(user) fetchSubmissions()
    },[user])

    return (
        <Box w={'100%'} px={7}>
            <Metadata title='Submissions List'/>
            {(areSubmissionsFetched == false) ? <Box boxShadow={'md'}><Spinner /></Box> : <>
            {submissionsList.length != 0 && <>
                <Box
            // boxShadow={'md'}
            py={4}
            px={3}
            >
                <Text 
                fontSize={32}
                fontWeight='semibold'
                color='brand.fieldName'
                >Submissions List</Text>
                <Box>
                    <Text fontWeight='semibold'>Campaign Type : {renderSubmissionsType(submissionsType)}</Text>
                    <Text fontWeight='semibold'>No Of Submissions : {totalSubmissions}</Text>
                </Box>
            </Box>
            </>}

            <Box
            // boxShadow={'md'}
            py={4}
            px={3}
            >
                {submissionsList.length == 0 &&  <Text fontSize='2xl' fontWeight={'light'}>No Submissions Yet!</Text>}
            <Accordion allowMultiple>
                {submissionsList.map(info=>{
                    return <BuyerInfo submission={info}/>
                })}
            </Accordion>
            </Box>
            </>}
        </Box>
    );
}
 
export default SubmissionsDetails;