import {
    Box, Text, FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Flex,Input,Select, Textarea,Divider,Switch,
    Button,
    SimpleGrid,
    useDisclosure,Fade,  AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useToast,
    Image,
    CircularProgress,
    CircularProgressLabel
} from '@chakra-ui/react';
import React,{useState} from 'react'
import { FiCheck,FiThumbsUp,FiThumbsDown,FiChevronLeft,FiChevronRight } from 'react-icons/fi'
import axios from 'axios'
import { BASEURL } from '../../api';
import Stepper from "react-stepper-horizontal";
import { onAuthStateChanged } from '@firebase/auth'
import { auth } from '../../api/firebase-config';


import AWS from 'aws-sdk';
const ShortUniqueId = require('short-unique-id')

window.Buffer = window.Buffer || require("buffer").Buffer;

const CreateCampaignOLD = ({setActiveIndex}) => {
    const toast = useToast()
    const [user,setUser] = React.useState(null)
    const [mediaUploaded,setMediaUploaded] = React.useState(false)
    const [localImageSrc,setLocalImageSrc] = React.useState(null)
    const [progress,setProgress] = React.useState(0)
    const [campaignImage,setCampaignImage] = React.useState('')
    const showToast = (title,description,status,duration) => {
        toast({
            title,
            description,
            status,
            duration,
            isClosable: true,
        })
    }
    function ConfirmCancel() {
        const { isOpen, onOpen, onClose } = useDisclosure()
        const cancelRef = React.useRef()
      
        return (
          <>
            <Button
            flex={1}
            colorScheme='purple'
            size={'lg'}
            variant='outline'
            onClick={onOpen}
            >Cancel</Button>
      
            <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={cancelRef}
              onClose={onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    Cancel Creating a New Campaign
                  </AlertDialogHeader>
      
                  <AlertDialogBody>
                    Are you sure? All details you've entered will be deleted.
                  </AlertDialogBody>
      
                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button colorScheme='red' onClick={()=>setActiveIndex(1)} ml={3}>
                      Yes, I'm sure
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </>
        )
      }
      // After Submissions
      const [sendAutomatedEmailAfterSubmission,setAutomatedEmailAfterSubmission] = React.useState(false)
    // Page States
    const [detailsFormErrorMessages,setDetailsFormErrorMessages] = useState({
        campaignNameError:'',
        campaignTypeError:'',
        campaignDescriptionError:''
    })
    const [campaignNameError,setCampaignNameError] = useState('')
    const [campaignTypeError,setCampaignTypeError] = useState('')
    const [campaignDescriptionError,setCampaignDescriptionError] = useState('')
    const [mediaErrMssg,setMediaErrMssg] = React.useState('')
    const [emailTemplate,setEmailTemplate] = React.useState('')

    function TemplateVisualizationModal() {
        const { isOpen, onOpen, onClose } = useDisclosure()
        return (
          <>
            {emailTemplate.length != 0 && <Button 
                variant={'link'}
                colorScheme='purple'
                onClick={onOpen}
                >Visualize</Button>}
      
            <Modal isOpen={isOpen} onClose={onClose} size='full' isCentered>
              <ModalOverlay />
              <ModalContent mt={40} overflowY={'scroll'} minH='fit-content'>
                <ModalHeader>E-mail Template</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                <div dangerouslySetInnerHTML={{__html:emailTemplate}}/>
                </ModalBody>
      
                <ModalFooter>
                  <Button colorScheme='purple' variant={'outline'} mr={3} onClick={onClose}>
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </>
        )
      } 

      onAuthStateChanged(auth,currentUser=>{
        setUser(currentUser)
      })
    const [reviewState,setReviewState] = useState('')
    const [campaignDetails,setCampaignDetails] = useState({
        campaignType:'REVIEW',
        campaignName:'',
        campaignDescription:''
    })
    const [reviewFormSettings,setReviewFormSettings] = useState({
        orderID:true,
        phoneNumber:false,
        whatsappNumber:false,
        modelNumber:false,
        productID:false,
        shipmentNumber:false,
        media:true,
        customMessage:false
    })
    const [warrantyFormSettings,setWarrantyFormSettings] = useState({
        orderID:true,
        phoneNumber:false,
        whatsappNumber:false,
        modelNumber:false,
        productID:false,
        shipmentNumber:false,
        media:false,
        customMessage:false
    })
    const [registrationFormSettings,setResigtrationFormSettings] = useState({
        orderID:false,
        phoneNumber:false,
        whatsappNumber:false,
        modelNumber:false,
        productID:false,
        shipmentNumber:false,
        media:false,
        customMessage:false
    })
    const [thankYouPageSettings,setThankYouPageSettings] = useState({
        heading:'Thank You',
        subheading:'Your submission has been sent successfully.',
        redirectUrl:'',
        redirectButtonText:'Visit',
        isRedirectButtonVisible:false
    })
    const [redirectButtonVisible,setRedirectButtonVisible] = useState(false)
    const [reviewSubmissionHeading,setReviewSubmissionHeading] = useState('Give a Review For This Product.')
    const [registrationSubmissionHeading,setRegistrationSubmissionHeading] = useState('Register This Product.')
    const [WarrantySubmissionHeading,setWarrantySubmissionHeading] = useState('Get a Warranty For This Product.')


    // Page Methods

    const handleSatisfied = () => setReviewState('Satisfied')
    const handleUnSatisfied = () => setReviewState('Unsatisfied')
  
    const handleThankYouPageSettingsChanges = (data) => {
        setThankYouPageSettings({...thankYouPageSettings,...data})
    }

    const handleSubmissionFormChanges = (data) => {
        switch(campaignDetails.campaignType) {
            case 'REVIEW':
                setReviewFormSettings({...reviewFormSettings,...data})
            break
            case 'REGISTRATION':
                setResigtrationFormSettings({...registrationFormSettings,...data})
            break
            case 'WARRANTY':
               setWarrantyFormSettings({...warrantyFormSettings,...data})
            break
            default:
                setReviewFormSettings({...reviewFormSettings,...data})
        }
    }

    const handleRedirectButtonChanges = (switcherState) => {
        setThankYouPageSettings({...thankYouPageSettings,isRedirectButtonVisible:switcherState})
        setRedirectButtonVisible(switcherState)
    }

    const renderFormBooleans = () => {
        switch(campaignDetails.campaignType) {
            case 'REVIEW':
                return reviewFormSettings
            break
            case 'REGISTRATION':
                return registrationFormSettings
            break
            case 'WARRANTY':
                return warrantyFormSettings
            break
            default:
                return reviewFormSettings
        }
    }
    
    const renderFormHeading = () => {
        switch(campaignDetails.campaignType) {
            case 'REVIEW':
                return reviewSubmissionHeading
            break
            case 'REGISTRATION':
                return registrationSubmissionHeading
            break
            case 'WARRANTY':
                return WarrantySubmissionHeading
            break
            default:
                return reviewSubmissionHeading
        }
    }

    const handleFormHeadingChanges = (newHeading) => {
        switch(campaignDetails.campaignType) {
            case 'REVIEW':
                setReviewSubmissionHeading(newHeading)
            break
            case 'REGISTRATION':
                setRegistrationSubmissionHeading(newHeading)
            break
            case 'WARRANTY':
                setWarrantySubmissionHeading(newHeading)
            break
            default:
                setReviewSubmissionHeading(newHeading)
        }
    }


    // Submit Campaign
    const submitCampaign = () => {
        let Errmsg = 'This field cannot be empty.'
        if(thankYouPageSettings.heading.length == 0) setThankYouPageHeadingMissed(Errmsg)
            if(thankYouPageSettings.subheading.length == 0) setThankYouPageSubHeadingMissed(Errmsg)
            if(thankYouPageSettings.heading.length == 0 ||
                thankYouPageSettings.subheading.length == 0
                ){
                   return
                }
        axios.post(`${BASEURL}/campaigns`,{
            sellerId:user.uid,
            ...campaignDetails,
            formHeading:renderFormHeading(),
            ...renderFormBooleans(), // this function returns an object
            ...thankYouPageSettings,
            sendAutomatedEmailAfterSubmission,
            campaignImage,
            emailTemplate, // Email tamplate
        }).then((res)=>{
            // console.log(res.data)
            setActiveIndex(1)
        }).catch((err)=>{
            showToast('Error','Email Not Verified Or Disabled!','error',3000)
        })

    }
    const [goSteps, setGoSteps] = React.useState(0);
    

    const [headingMissedMsg,setIsHeadingMissedMsg] = React.useState('')
    const [thankYouPageHeadingMissed,setThankYouPageHeadingMissed] = React.useState('')
    const [thankYouPageSubHeadingMissed,setThankYouPageSubHeadingMissed] = React.useState('')
    const filterSteps = (currentStep) => {
        let Errmsg = 'This field cannot be empty!'
        // console.log('step count',currentStep)
        
        if(currentStep == 0) {
            if(campaignDetails.campaignType.length == 0) {
                setCampaignTypeError(Errmsg)
            }if(campaignDetails.campaignName.length == 0) {
               setCampaignNameError(Errmsg)
            }if(campaignDetails.campaignDescription.length == 0) {
              setCampaignDescriptionError(Errmsg)
            }
            // if(campaignImage.length  == 0) {
            //     setMediaErrMssg(Errmsg)
            // }

            if(campaignDetails.campaignType.length != 0 &&
                campaignDetails.campaignDescription.length != 0 &&
                campaignDetails.campaignName.length != 0 
                ) {
                    setGoSteps(prev=>prev+1)
                }

        }
        if(currentStep == 1) {
            switch(campaignDetails.campaignType) {
                case 'REVIEW':
                    if(reviewSubmissionHeading.length == 0) setIsHeadingMissedMsg(Errmsg)
                    else setGoSteps(prev=>prev+1)
                break
                case 'REGISTRATION':
                    if(registrationSubmissionHeading.length == 0) setIsHeadingMissedMsg(Errmsg)
                    else setGoSteps(prev=>prev+1)
                    break
                case 'WARRANTY':
                    if(WarrantySubmissionHeading.length == 0) setIsHeadingMissedMsg(Errmsg)
                    else setGoSteps(prev=>prev+1)
                    break
                default:
                  
            } 
        }
        if(currentStep == 2) {
            if(thankYouPageSettings.heading.length == 0) setThankYouPageHeadingMissed(Errmsg)
            if(thankYouPageSettings.subheading.length == 0) setThankYouPageSubHeadingMissed(Errmsg)
            if(thankYouPageSettings.heading.length != 0 &&
                thankYouPageSettings.subheading.length == 0
                ){
                //    submitCampaign()
                }
        }
    }

        const handleInputFocus = () => {
           setCampaignNameError('')
           setCampaignTypeError('')
           setCampaignDescriptionError('')
           setMediaErrMssg('')
        }
       
        function createObjectURL(object) {
            return (window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object);
        }

        const handleImageChange = (e) => {
            // reset conditions 
            setMediaErrMssg('')
            setMediaUploaded(false)
            setProgress(0)
            setLocalImageSrc(null)
            let file = e.target.files[0]
            let fileName = file.name
            
            if(file.size > 1 * 1024 * 1024){
                showToast('Upload Error','The file size must be less than or equal to 10MB','warning',3000)
                return
            }
            setLocalImageSrc(createObjectURL(file))
            // console.log('changed image',file)
        
            const s3 = new AWS.S3({
                correctClockSkew: true,
                endpoint: 'https://s3.wasabisys.com', //use appropriate endpoint as per region of the bucket
                region: 'us-west-1',
                accessKeyId: process.env.REACT_APP_WASABI_ACCESSKEY,
                secretAccessKey: process.env.REACT_APP_WASABI_SECRETKEY,
                logger: console,
                signatureVersion:'v4',
              });
        
            const uid = new ShortUniqueId()
          let newUID = uid()
          const uploadRequest = new AWS.S3.ManagedUpload({
            params: { Bucket: process.env.REACT_APP_WASABI_BUCKETNAME, Key: `${newUID}-${fileName}`, Body: file },
            service: s3
          });
        
          uploadRequest.on('httpUploadProgress', function(event) {
            const progressPercentage = Math.floor(event.loaded * 100 / event.total);
            // console.log('Upload progress ' + progressPercentage);
            setProgress(progressPercentage)
          });
        
        //   console.log('Configured and sending');
        
          uploadRequest.send(function(err) {
            if (err) {
              console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));
            } else {
                setMediaUploaded(true)
                let url = `https://s3.us-east-1.wasabisys.com/${process.env.REACT_APP_WASABI_BUCKETNAME}/${newUID}-${fileName}`
                setCampaignImage(url)
            }
          });
        
        
        }
        const handleThankYouFocus = () => {
            setThankYouPageHeadingMissed('')
            setThankYouPageSubHeadingMissed('')
        }
        
    return (
       <>
       <Box borderWidth={1.5} mb={200}
        boxShadow='md'
       >
       <Text as='h1' fontSize='3xl' m={2} fontWeight='semibold'>Create New Campaign</Text>
          
        <Stepper 
        completeBarColor={'purple'}
        activeColor={'#B794F4'}
        completeColor={'#68D391'}
        steps={ [{title: 'Campaign Details',onClick:()=>setGoSteps(0)}, {title: 'Form Details',onClick:()=>setGoSteps(1)}, {title: 'After Submission',onClick:()=>setGoSteps(2)}] } activeStep={ goSteps } />
        {goSteps == 0 && <Fade in={goSteps == 0 && true}>
        <Box
          py={6}
          px={10}
        //   color='white'
          mt={4}
        //   bgColor='orange.200'
          rounded='md'
          shadow='md'
        >
             {/* <Text as="h1" fontSize={'4xl'}>Campaign Details</Text> */}
          <Flex
            alignItems={'flex-end'}
            gap={{base:0,md:10}}
            mb={{base:0,md:5}}
            direction={{base:'column',md:'row'}}
            >
                <FormControl isRequired my={{base:2,md:0}}>
                    <FormLabel htmlFor='campaignType'>Campaign name</FormLabel>
                    <Input id='campaignType' type='text' 
                    value={campaignDetails.campaignName}
                    onChange={e=>setCampaignDetails({...campaignDetails,campaignName:e.target.value})}
                    onFocus={()=>handleInputFocus()}
                    />
                    {/* <FormHelperText pos="absolute" bottom={-3} color='red.300'>test</FormHelperText> */}
                    <FormHelperText pos="absolute" bottom={-5} color='red.300'>{campaignNameError}</FormHelperText>
                </FormControl>
                <FormControl isRequired my={{base:2,md:0}}>
                <FormLabel htmlFor='campaignType'>Campaign type</FormLabel>
                    <Select value={campaignDetails.campaignType} id='campaignType' onChange={e=>setCampaignDetails({...campaignDetails,campaignType:e.target.value})}>
                        <option value='REVIEW'>Product Review</option>
                        <option value='REGISTRATION'>Product Registration</option>
                        <option value='WARRANTY'>Product Warranty Registration</option>
                    </Select>
                    <FormHelperText  pos="absolute" bottom={-5} color='red.300'>{campaignTypeError}</FormHelperText>
                </FormControl>
            </Flex>

             {/* Product Description */}

             <FormControl isRequired mb={6}>
                <FormLabel htmlFor='productDescription'>Description</FormLabel>
                <Textarea 
                value={campaignDetails.campaignDescription}
                placeholder='Short description for your product ....'
                onChange={e=>setCampaignDetails({...campaignDetails,campaignDescription:e.target.value})}
                onFocus={()=>handleInputFocus()}
                maxH={150}
                />
                 <FormHelperText  pos="absolute" bottom={-5} color='red.300'>{campaignDescriptionError}</FormHelperText>
            </FormControl>


            {/* Product Images */}
            <FormControl isRequired my={2}>
                <FormLabel htmlFor='productImages'>Product Images</FormLabel>
                <Input 
                type='file'
                onChange={e=>handleImageChange(e)}
                />
                 <Box
                        bgColor={'red.100'}
                        w={'30%'}
                        pos='relative'
                        mt={3}
                        >
                        {(mediaUploaded) && <Box
                        pos={'absolute'}
                        top='50%'
                        left='50%'
                        bgColor='#fff'
                        style={{transform:'translate(-50%,-50%)'}}
                        borderRadius='full'
                        p={1}
                        boxShadow='md'
                        zIndex={1000}
                        >
                        <FiCheck 
                        size={44}
                        color='#805ad5'
                        />  
                            </Box>}
                       
                        {((progress > 0 ) && ( progress < 100)) && <CircularProgress 
                        value={progress} color='purple.400' 
                        pos={'absolute'}
                        top='50%'
                        left='50%'
                        style={{transform:'translate(-50%,-50%)'}}
                        zIndex={1000}
                        >
                            <CircularProgressLabel>{progress}%</CircularProgressLabel>
                        </CircularProgress>}
                       {(localImageSrc != null) && <Image 
                        src={localImageSrc}
                        filter='auto'
                        blur={progress == 100 ? '0px' : '1.5px'}
                        />}
                        {/* <Image 
                        src={"https://s3.us-east-1.wasabisys.com/sellersmedia/KW08r4-278709361_1968453766696416_6652861923396843589_n.jpg"}
                        filter='auto'
                        blur={progress == 100 ? '0px' : '1.5px'}
                        /> */}
                        </Box>
               <FormHelperText textAlign={'left'} color="red.300">{mediaErrMssg}</FormHelperText>
            </FormControl>

        </Box>
      </Fade>}
            {goSteps == 1 && <Fade in={goSteps == 1 && true}>
        <Box
          py={6}
          px={10}
        //   color='white'
          mt={4}
        //   bgColor='red.200'
          rounded='md'
          shadow='md'
        >
            {/* submissions form details */}
            <Box my={2}>
                <Text as='span' fontSize='xl'>Form Settings</Text>
                <Box w={{base:'100%',md:'50%'}} my={2}>
                <FormControl isRequired>
                    <FormLabel htmlFor='submissionHeading'>Heading</FormLabel>
                    <Input id='submissionHeading' type='text'
                    value={renderFormHeading()}
                    onChange={e=>handleFormHeadingChanges(e.target.value)}
                    onFocus={()=>setIsHeadingMissedMsg('')}
                    />
        <FormHelperText color='red.300'>{headingMissedMsg}</FormHelperText>
                </FormControl>
                </Box>
                <Text as='span' fontSize='lg' my={2}>Extra Fields</Text>
                <Flex w={'100%'} gap={5}
                direction={{base:'column',md:'row'}}
                >
                    <SimpleGrid flex={1} 
                    columns={2}
                    maxH={200}
                    spacingX={4}
                    spacingY={2}
                    >
           
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerNameIsVisible' mb='0'>Name</FormLabel>
                        <Switch id='buyerNameIsVisible' isChecked isDisabled/>
                        </Flex>
   
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerAddressIsVisible' mb='0'>Email address</FormLabel>
                        <Switch id='buyerAddressIsVisible' isChecked isDisabled/>
                        </Flex>
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerOrderIdIsVisible' mb='0'>Order ID</FormLabel>
                        <Switch id='buyerOrderIdIsVisible' isChecked={renderFormBooleans().orderID} isDisabled={renderFormBooleans().orderID}
                        onChange={(e)=>handleSubmissionFormChanges({orderID:e.target.checked})}
                        />
                        </Flex>
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerPhoneNumberIsVisible' mb='0'>Phone Number</FormLabel>
                        <Switch id='buyerPhoneNumberIsVisible' isChecked={renderFormBooleans().phoneNumber}
                        onChange={(e)=>handleSubmissionFormChanges({phoneNumber:e.target.checked})}
                        />
                        </Flex>
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerWhatsappNumberIsVisible' mb='0'>Whatsapp Number</FormLabel>
                        <Switch id='buyerWhatsappNumberIsVisible' isChecked={renderFormBooleans().whatsappNumber}
                        onChange={(e)=>handleSubmissionFormChanges({whatsappNumber:e.target.checked})}
                        />
                        </Flex>
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerModelNumberIsVisible' mb='0'>Model Number</FormLabel>
                        <Switch id='buyerModelNumberIsVisible' isChecked={renderFormBooleans().modelNumber}
                        onChange={(e)=>handleSubmissionFormChanges({modelNumber:e.target.checked})}
                        />
                        </Flex>
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerProductIdIsVisible' mb='0'>Product ID</FormLabel>
                        <Switch id='buyerProductIdIsVisible' isChecked={renderFormBooleans().productID}
                        onChange={(e)=>handleSubmissionFormChanges({productID:e.target.checked})}
                        />
                        </Flex>
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerShipmentNumberIsVisible' mb='0'>Shipment Number</FormLabel>
                        <Switch id='buyerShipmentNumberIsVisible' isChecked={renderFormBooleans().shipmentNumber}
                        onChange={(e)=>handleSubmissionFormChanges({shipmentNumber:e.target.checked})}
                        />
                        </Flex>
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerImageIsVisible' mb='0'>Include Image</FormLabel>
                        <Switch id='buyerImageIsVisible' isChecked={renderFormBooleans().media} isDisabled={campaignDetails.campaignType == 'REVIEW' ? true : false}
                         onChange={(e)=>handleSubmissionFormChanges({media:e.target.checked})}
                        />
                        </Flex>
                        <Flex alignItems={"center"} justify='space-between'>
                        <FormLabel htmlFor='buyerCustomMessageIsVisible' mb='0'>Custom Message</FormLabel>
                        <Switch id='buyerCustomMessageIsVisible' isChecked={renderFormBooleans().customMessage}
                         onChange={(e)=>handleSubmissionFormChanges({customMessage:e.target.checked})}
                        />
                        </Flex>
                  
                    </SimpleGrid>
                    <Divider orientation='vertical' />
                    <Box flex={1} 
                    // bgColor="green.200" 
                    textAlign={"center"}
                    display='flex'
                    justifyContent={'center'}
                    mt={{base:10,md:0}}
                    >
                        <Flex direction={'column'}
                        w={{base:'90%',md:'80%'}}
                        // bgColor='red.300'
                        py={3}
                        px={{base:2,md:5}}
                        borderRadius={10}
                        boxShadow='lg'
                        >
                        <Box w={'60%'} textAlign='left'>
                        <Text as="span"
                        fontSize={22}
                        fontWeight='semibold'
                        >
                       {renderFormHeading()}
                        </Text>
                        </Box>
                       {campaignDetails.campaignType == 'REVIEW' && <Box>
                       <Box
                        display='flex'
                        // bgColor="purple.300"
                        justifyContent={'center'}
                        py={4}
                        gap={4}
                        >
                            <FiThumbsUp size={40} onClick={()=>handleSatisfied()}
                            color={reviewState == 'Satisfied' && '#FAC405'}
                            className='thumb_down_up'
                            />
                            <FiThumbsDown size={40} onClick={()=>handleUnSatisfied()}
                            color={reviewState == 'Unsatisfied' && '#FAC405'}
                            className='thumb_down_up'
                            />
                        </Box>
                        <Text textAlign={'center'}>{reviewState}</Text>
                       </Box>}
                        <Box> 
                        <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Name</FormLabel>
                        <Input id='campaignType' type='text' />
                        </FormControl>
                        <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Email address</FormLabel>
                        <Input id='campaignType' type='text' />
                        </FormControl>
                        {renderFormBooleans().phoneNumber && <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Phone Number</FormLabel>
                        <Input id='campaignType' type='text' />
                        </FormControl>}
                        {renderFormBooleans().whatsappNumber &&  <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Whatsapp Number</FormLabel>
                        <Input id='campaignType' type='text' />
                        </FormControl>}
                       {renderFormBooleans().orderID && <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Order ID</FormLabel>
                        <Input id='campaignType' type='text' />
                        </FormControl>}
                       {(campaignDetails.campaignType == 'REVIEW' && reviewState == 'Unsatisfied') && <FormControl isRequired>
                        <FormLabel htmlFor='negative_review'>Write a review</FormLabel>
                        <Textarea 
                        id="negative_review"
                        placeholder='Tell us what went wrong and how we can improve'
                        />
                        </FormControl>}
                        {renderFormBooleans().productID && <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Product ID</FormLabel>
                        <Input id='campaignType' type='text' />
                        </FormControl>}
                        {renderFormBooleans().modelNumber && <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Model Number</FormLabel>
                        <Input id='campaignType' type='text' />
                        </FormControl>}
                        {renderFormBooleans().shipmentNumber && <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Shipment Number</FormLabel>
                        <Input id='campaignType' type='text' />
                        </FormControl>}
                        {renderFormBooleans().media && <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Include a screenshot of the review</FormLabel>
                        <Input id='campaignType' type='file' />
                        </FormControl>}
                        {renderFormBooleans().customMessage && <FormControl isRequired>
                        <FormLabel htmlFor='campaignType'>Custom Message</FormLabel>
                        <Textarea />
                        </FormControl>}
                        <Box my={2}>
                            <Button w={'full'} colorScheme='purple'
                            >Submit</Button>
                        </Box>
                        </Box>
                        </Flex>
                    </Box>
                </Flex>
            </Box>
        </Box>
      </Fade>}
            {goSteps == 2 && <Fade in={goSteps == 2 && true}>
        <Box
         py={6}
         px={10}
        //   color='white'
          mt={4}
        //   bgColor='blue.200'
          rounded='md'
          shadow='md'
        >
         {/* Thank You Page Settings */}
         <Box my={2}>
                <Text as='span' fontSize='lg'>Thank You Page Settings</Text>
                <Flex w={'100%'}
                //  bgColor="purple.100"
                direction={{base:'column',md:'row'}}
                   gap={5}>
                    <Box flex={1} 
                    // bgColor="orange.200"
                    >
                    <FormControl isRequired>
                        <FormLabel htmlFor='thankYouPageHeading'>Heading</FormLabel>
                        <Input id='thankYouPageHeading' type='text'
                        value={thankYouPageSettings.heading}
                        onFocus={()=>handleThankYouFocus()}
                        onChange={(e)=>handleThankYouPageSettingsChanges({heading:e.target.value})}
                         />
                          <FormHelperText color='red.300'>{thankYouPageHeadingMissed}</FormHelperText>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel htmlFor='thankYouPageSubheading'>Subheading</FormLabel>
                        <Input id='thankYouPageSubheading' type='text'
                         value={thankYouPageSettings.subheading}
                         onFocus={()=>handleThankYouFocus()}
                         onChange={(e)=>handleThankYouPageSettingsChanges({subheading:e.target.value})}
                        />
                         <FormHelperText color='red.300'>{thankYouPageSubHeadingMissed}</FormHelperText>
                    </FormControl>
                    <FormControl display='flex' alignItems='center' my={2}>
                        <FormLabel htmlFor='redirect_button' mb='0'>
                            Show Redirect Button?
                        </FormLabel>
                        <Switch id='redirect_button' onChange={(e)=>handleRedirectButtonChanges(e.target.checked)} />
                        </FormControl>
                        {redirectButtonVisible && <>
                            <FormControl isRequired my={2}>
                        <FormLabel htmlFor='redirect_url'>URL</FormLabel>
                        <Input id='redirect_url' type='text'
                         value={thankYouPageSettings.redirectUrl}
                         onChange={(e)=>handleThankYouPageSettingsChanges({redirectUrl:e.target.value})}
                         />
                    </FormControl>
                        <FormControl isRequired>
                        <FormLabel htmlFor='redirect_button_text'>Redirect Button Text</FormLabel>
                        <Input id='redirect_button_text' type='text'
                         value={thankYouPageSettings.redirectButtonText}
                         onChange={(e)=>handleThankYouPageSettingsChanges({redirectButtonText:e.target.value})}
                        />
                    </FormControl>
                        </>}
                        {campaignDetails.campaignType == 'REVIEW' && <>
                         <FormControl display='flex' alignItems='center' my={2}>
                        <FormLabel htmlFor='automated_email' mb='0'>
                        After submissions, Send an automated email
                        </FormLabel>
                        <Switch size='md' id='automated_email' onChange={(e)=>setAutomatedEmailAfterSubmission(e.target.checked)} />
                        </FormControl>
                        </>}
                       
                    </Box>
                    <Divider orientation='vertical' />
                    <Box flex={1} 
                    // bgColor="green.200" 
                    textAlign={"center"}
                    display='flex'
                    flexDirection={'column'}
                    justifyContent={'center'}
                    >
                        <Flex direction={'column'} alignItems='center'
                        // bgColor='blackAlpha.100'
                        w={{base:'90%',md:'80%'}}
                        maxW={{base:'90%',md:'80%'}}
                        wordBreak='break-word'
                        my={2}
                        minH={300}
                        maxH={300}
                        overflowY='hidden'
                        borderRadius={10}
                        py={4}
                        boxShadow='lg'
                        >
                        <Box>
                        <Text as="span"><FiCheck size={60}/></Text>
                        </Box>
                        <Box>
                            <Text as="h1" fontSize={32} fontWeight='semibold'>{thankYouPageSettings.heading}</Text>
                            <Text as="h2" fontSize={22} fontWeight='semibold'>{thankYouPageSettings.subheading}</Text>
                        </Box>
                        <Box my={2}>
                            {redirectButtonVisible && <Button colorScheme={'purple'}>
                                {thankYouPageSettings.redirectButtonText}
                            </Button>}
                        </Box>
                        </Flex>

                        {sendAutomatedEmailAfterSubmission && <>
                            <Flex w={{base:'90%',md:'80%'}}>
                            <FormControl my={2}>
                        <FormLabel display='flex' alignItems='center' justifyContent={'space-between'} mb='0'>
                        <Text>E-Mail Template</Text>
                        <Button variant={'link'} colorScheme='purple'>
                            <TemplateVisualizationModal />
                        </Button>
                        </FormLabel>
                        <Textarea
                        variant={'filled'}
                        minH={200}
                        onChange={e=>setEmailTemplate(e.target.value)}
                        >

                        </Textarea>
                        </FormControl>
                            </Flex>
                        </>}
                    </Box>
                </Flex>
            </Box>
        </Box>
      </Fade>}

        <Box display="flex" gap={4}
        // bgColor="green.200"
        justifyContent={'space-around'}
        my={4}
        >
        {goSteps > 0 && <Button colorScheme={'purple'} variant='ghost' leftIcon={<FiChevronLeft />} size='lg' onClick={()=>{
           goSteps > 0 && setGoSteps(prev=>prev-1)
            // onToggle()
        }}>Back</Button>}
        {goSteps < 2 && <Button colorScheme={'purple'} variant='ghost' rightIcon={<FiChevronRight />} size='lg' onClick={()=>{   
            goSteps < 2 && filterSteps(goSteps)
            // onToggle()
        }}>Next</Button>}
        </Box>

        {goSteps == 2 && <Box mx={3} my={4} display='flex' gap={2}>
            <Button
            flex={1}
            colorScheme='purple'
            size={'lg'}
            variant='solid'
            onClick={()=>submitCampaign()}
            >Save</Button>
           <ConfirmCancel />
          </Box>}
       </Box>
       </>
    );
}

export default CreateCampaignOLD;