import React,{useContext} from 'react'
import { Box,Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Button,
    Flex,
    useToast,
    Spinner,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    IconButton,
    useDisclosure,
    HStack,
    Circle,
    Divider,
    Select
} from '@chakra-ui/react'
import { BsThreeDotsVertical } from "react-icons/bs";
import { Chart } from "react-google-charts";
import { FiEdit2,FiDownload,FiCopy,FiArrowRight,FiBookOpen } from 'react-icons/fi'
import axios from 'axios'
import { QRCodeCanvas,QRCodeSVG } from 'qrcode.react';
import { BASEURL } from '../../api/index'
import { useLocation,useHistory, Link } from 'react-router-dom'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { auth } from '../../api/firebase-config';
import { onAuthStateChanged } from '@firebase/auth'
import { UserContext } from '../../api';
import Metadata from '../../Components/Metadata';

const Analytics = () => {

         const { setActivePage } = useContext(UserContext)
        const toast = useToast()
        const [user,setUser] = React.useState(null)
        const [isFetching,setIsFetching] = React.useState(false)

        const showToast = (title,description,status,duration) => {
            toast({
                title,
                description,
                status,
                duration,
                isClosable: true,
            })
        }
 
        onAuthStateChanged(auth,currentUser=>{
            setUser(currentUser)
        })
   

    // Analytics
    const [analyticsGraphData,setAnalyticsGraphData] = React.useState([
        ["Type", "Value"]
      ]);
      const [analyticsGraphGeoData,setAnalyticsGraphGeoData] = React.useState([
        ["Country", "Review"],
      ]);
    const [analyticsData,setAnalyticsData] = React.useState([])
    const [campaignType,setCampaignType] = React.useState('Review')

    const fetchAnalytics = () => {
        setIsFetching(true)
        axios.get(`${BASEURL}/analytics`,{
            params : {
                accessToken:user.accessToken
            }
        })
        .then(res=>{
            // Pie Graph
            let data = res.data.map((record=>[record['campaign_type'],record['nbr']]))
            setAnalyticsGraphData([['Type','Value'],...data])
            // Cards
            setAnalyticsData(res.data)
        }).then(()=>setIsFetching(false)).catch(err=>{
            showToast('Error','Unable to retrieve Analytics, Please Try Again!','error',3000)
            console.log('fetch campaigns error',err)
        })
    }
    const fetchCountries = (value) => {
        axios.get(`${BASEURL}/analytics/getCountries`,{
            params : {
                accessToken:user.accessToken,
                campaignType:value
            }
        })
        .then(res=>{
            // geo Graph
            let data = res.data.map((record=>[record['country'],record['nbr']]))
            console.log('geo data')
            setAnalyticsGraphGeoData([['Country',value],...data])
        }).catch(err=>{
            showToast('Error','Unable to retrieve Countries, Please Try Again!','error',3000)
            console.log('fetch campaigns error',err)
        })
    }

    React.useEffect(()=>{
        if(user) fetchAnalytics()
    },[user])
    React.useEffect(()=>{
        if(user) fetchCountries('Review')
    },[user])
    
    const RenderAnalyticsCards = () => {
        function getNumericData(campaginType) {
            for(let i=0;i<analyticsData.length;i++) {
                if(campaginType == analyticsData[i]['campaign_type']) {
                    return analyticsData[i]['nbr']
                }
            }
            return 0;
        }
        return <>
        <CampaignCount 
        campaignName={'Registration'}
        numericData={getNumericData("REGISTRATION")}
        />
        <CampaignCount 
        campaignName={'Review'}
        numericData={getNumericData("REVIEW")}
        />
        <CampaignCount 
        campaignName={'Warranty'}
        numericData={getNumericData("WARRANTY")}
        />
        </>
    }
    const CampaignCount = ({campaignName,numericData}) => {
        return <>
        <Metadata title='Analytics'/>
         <Flex 
                w={295} 
                h={160} 
                boxShadow='sm'
                px={4}
                py={2}
                borderWidth={2}
                borderColor='#FBD38D'
                borderRadius={'22px'}
                justify={'center'}
                align='center'
                direction='column'
                >
                    <Box w={'80%'}>
                        <Box>
                        <Text
                         fontSize={24}
                         fontWeight={500}
                        >{campaignName}</Text>
                        </Box>
                    </Box>
                    <Flex
                    justify={'space-between'}
                    align='center'
                    w={'80%'}
                    >
                        <Text
                        fontSize={36}
                        fontWeight={800}
                        >{numericData}</Text>
                        <Link
                        to={`/`}
                        >
                        <Circle bgColor='#FBD38D' size='40px'>
                        <FiArrowRight color='#C05621' size={23} />
                        </Circle>
                        </Link>
                        
                    </Flex>
                </Flex>
        </>
    }
React.useEffect(()=>{
    setActivePage('ANALYTICS')
},[])
    return (
        <Box
        px={7}
        >
          {(isFetching ) ? <Box p={10}><Spinner /> </Box> : <>
          <Box
             boxShadow={'base'}
             py={4}
             px={3} 
            >
                <Text 
                fontSize={32}
                fontWeight='semibold'
                >Analytics</Text>
                <Flex
                justifyContent={'space-between'}
                >
                    <Select defaultValue={'Review'} w={{base:'100%',md:250}}
                    borderColor='#FBD38D'
                    _focus={{
                        borderColor:'#FBD38D'
                    }}
                    onChange={(e)=>{
                        setCampaignType(e.target.value)
                        fetchCountries(e.target.value)
                    }}
                    >
                        <option value='Review'>Review</option>
                        <option value='Registration'>Registration</option>
                        <option value='Warranty'>Warranty</option>
                    </Select>
                </Flex>
              <Flex
              direction={{base:'column',lg:'row'}}
              gap={10}
              py={2}
              mb={3}
              >
              <RenderAnalyticsCards />
              </Flex>
              <Divider />
              <Flex 
              mt={3}
              py={2}
              direction={{base:'column',md:'row'}}
              justify='space-between'
              >
                <Box w={{base:'100%',md:'50%'}}>
                    <Text>
                    <Chart
                     chartType="GeoChart"
                     width="98%"
                     height="400px"
                     data={analyticsGraphGeoData}
                     options={
                        {
                            defaultColor: '#555',
                            backgroundColor: '#fff',
                            // datalessRegionColor: '#FBD38D',
                          colorAxis: {  colors: ['#FBD38D','#F6AD55','#DD6B20'] },
                          legend: 'none'
                        }
                      }
                    />
                    </Text>
                </Box>
                <Box w={{base:'100%',md:'45%'}}>
                <Chart
            className="svgWrapper"
            chartType="PieChart"
            data={analyticsGraphData}
            options={
              {
              
                title: null,
                colors: ['#FBD38D','#F6AD55','#DD6B20'],

                legend: {
                  position: 'bottom'
                },
                pieHole: 0.6
              }
            }
            width={"100%"}
            height={"400px"}
          />
                </Box>
              </Flex>
            </Box>
          </>}  
        </Box>
    );
}
 
export default Analytics;