import React from 'react'
import {
    Button,
    Box,
    Text,
    Divider,
    SimpleGrid,
    FormControl,
    FormLabel,
    Input,
    useToast
} from '@chakra-ui/react'
import axios from 'axios'
import { BASEURL } from './../../api'
import { auth } from '../../api/firebase-config'
import { onAuthStateChanged } from '@firebase/auth'
const Users = ({accessToken}) => {
    const [userEmailActivate, setUserEmailActivate] = React.useState('')
    const [userEmailDisable, setUserEmailDisable] = React.useState('')
    const [userEmailDelete, setUserEmailDelete] = React.useState('')
    const [userEmailReActivate, setUserEmailReActivate] = React.useState('')
    const toast = useToast()

    const showToast = (title, description, status, duration) => {
        toast({
            title,
            description,
            status,
            duration,
            isClosable: true,
        })
    }


    const handleActiveUserEmail = () => {
        axios.post(`${BASEURL}/admin/activate-email`, {
            email: userEmailActivate,
            accessToken
        }).then(res => {
            showToast('success', 'Account activated successfully', 'success', 1500)

            setUserEmailActivate('')
        }).catch((err) => {
            console.log(err)
            showToast('error', `Cannot activate account : ${err.response.data}`, 'error', 2500)
        })
    }

    const handleDisableAccount = () => {
        axios.post(`${BASEURL}/admin/disable-account`, {
            email: userEmailDisable,
            accessToken
        }).then(res => {
            console.log(res.data)
            showToast('success', 'Account disabled successfully', 'success', 1500)

            setUserEmailDisable('')
        }).catch((err) => {
            showToast('error', `Cannot Disable account : ${err.response.data}`, 'error', 2500)
        })
    }

    const handleReactivateAccount = () => {
        axios.post(`${BASEURL}/admin/reactivate-account`, {
            email: userEmailReActivate,
            accessToken
        }).then(res => {
            showToast('success', 'Account re-activated successfully', 'success', 1500)

            setUserEmailReActivate('')
        }).catch((err) => {
            showToast('error', `Cannot re-activate account : ${err.response.data}`, 'error', 2500)
        })
    }

    const handleDeleteSeller = () => {
        axios.post(`${BASEURL}/admin/delete-account`, {
            email: userEmailDelete,
            accessToken
        }).then(res => {
            showToast('success', 'Account deleted successfully', 'success', 1500)

            setUserEmailDelete('')
        }).catch((err) => {
            showToast('error', `Cannot re-activate account : ${err.response.data}`, 'error', 2500)
        })
    }

    return (

        <Box>
            <Text as="h1" fontSize='2xl' mb={3} fontWeight='bold'>Users Management</Text>
            <Divider />
            <Box my={5}>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacingX={10} spacingY={7}>
                    <Box>
                        <Text as="h2" fontSize={'xl'}>
                            Activate User Email
                        </Text>
                        <FormControl>
                            <FormLabel>Email address</FormLabel>
                            <Input type='email' variant='flushed'
                                borderColor={'brand.button'}
                                _focus={{
                                    borderColor:'brand.buttonHover',
                                    borderBottomWidth:3
                                }}
                                value={userEmailActivate}
                                onChange={(e) => setUserEmailActivate(e.target.value)}
                            />
                        </FormControl>
                        <Button 
                         bgColor={'brand.button'}
                         color='#fff'
                         variant='solid'
                         _hover={{
                             bgColor:'brand.buttonHover'
                         }}
                        my={1.5} onClick={() => handleActiveUserEmail()}>
                            Activate
                        </Button>
                    </Box>
                    <Box>
                        <Text as="h2" fontSize={'xl'}>
                            Disable User Account
                        </Text>
                        <FormControl>
                            <FormLabel>Email address</FormLabel>
                            <Input type='email' variant='flushed'
                                borderColor={'brand.button'}
                                _focus={{
                                    borderColor:'brand.buttonHover',
                                    borderBottomWidth:3
                                }}
                                value={userEmailDisable}
                                onChange={(e) => setUserEmailDisable(e.target.value)}
                            />
                        </FormControl>
                        <Button 
                         bgColor={'brand.button'}
                         color='#fff'
                         variant='solid'
                         _hover={{
                             bgColor:'brand.buttonHover'
                         }}
                         my={1.5} onClick={() => handleDisableAccount()}>
                            Disable
                        </Button>
                    </Box>
                    <Box>
                        <Text as="h2" fontSize={'xl'}>
                            Delete User Account
                        </Text>
                        <FormControl>
                            <FormLabel>Email address</FormLabel>
                            <Input type='email' variant='flushed'
                                borderColor={'brand.button'}
                                _focus={{
                                    borderColor:'brand.buttonHover',
                                    borderBottomWidth:3
                                }}
                                value={userEmailDelete}
                                onChange={(e) => setUserEmailDelete(e.target.value)}
                            />
                        </FormControl>
                        <Button 
                         bgColor={'brand.button'}
                         color='#fff'
                         variant='solid'
                         _hover={{
                             bgColor:'brand.buttonHover'
                         }}
                        my={1.5} onClick={() => handleDeleteSeller()}>
                            Delete
                        </Button>
                    </Box>
                    <Box>
                        <Text as="h2" fontSize={'xl'}>
                            Re-Activate User Account
                        </Text>
                        <FormControl>
                            <FormLabel>Email address</FormLabel>
                            <Input type='email'
                                value={userEmailReActivate}
                                onChange={(e) => setUserEmailReActivate(e.target.value)}
                                variant='flushed'
                                borderColor={'brand.button'}
                                _focus={{
                                    borderColor:'brand.buttonHover',
                                    borderBottomWidth:3
                                }}
                            />
                        </FormControl>
                        <Button 
                         bgColor={'brand.button'}
                         color='#fff'
                         variant='solid'
                         _hover={{
                             bgColor:'brand.buttonHover'
                         }}
                        my={1.5} onClick={() => handleReactivateAccount()}>
                            Re-Activate Account
                        </Button>
                    </Box>
                </SimpleGrid>
            </Box>
        </Box>

    );
}

export default Users;