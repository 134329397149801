import React from 'react'
import { Box,
    useColorModeValue,chakra,Button,Icon,HStack,Flex,PopoverContent,IconButton,useDisclosure,
    useColorMode,SimpleGrid,Stack,Popover,PopoverTrigger,VStack,Text,CloseButton, Divider, Avatar, Center, Image ,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from '@chakra-ui/react';
import { FiArrowRight,FiChevronDown,FiLogOut,FiMenu } from 'react-icons/fi'
import { useViewportScroll } from 'framer-motion';

import { Link,useLocation,useHistory } from 'react-router-dom'
import { useContext } from "react"
import { UserContext } from "../../api"
import { auth } from "../../api/firebase-config"
import { signOut } from '@firebase/auth'

const Header =  () => {
    const location = useLocation()
    const history = useHistory()
    const { isAuth,setIsAuth,userDisplayName,userAvatarLink,userRole } = useContext(UserContext)

    const signOutUser = () => {
        signOut(auth)
        .then(()=>{
            localStorage.clear()
            setIsAuth(false)

            history.replace('/auth')
        })
    }
    let currentLocation = location.pathname
    const [currenPersistentLocation, setCurrentPersistentLocation] = React.useState(location.pathname)

    const handleExit = () => {
        if (currentLocation != currenPersistentLocation) {
            // setIsOpen(false)
            setCurrentPersistentLocation(location.pathname)
        }
    }

    handleExit()


    const { toggleColorMode: toggleMode } = useColorMode();
    const text = useColorModeValue("dark", "light");
    // const SwitchIcon = useColorModeValue(FaMoon, FaSun);
    const bg = useColorModeValue("white", "gray.800");
    const ref = React.useRef(null);
    const [y, setY] = React.useState(0);
    const height = ref.current ? ref.current.getBoundingClientRect() : 0;
    const { scrollY } = useViewportScroll();
    React.useEffect(() => {
      return scrollY.onChange(() => setY(scrollY.get()));
    }, [scrollY]);
    const cl = useColorModeValue("gray.800", "white");
    const mobileNav = useDisclosure();
  
    const MobileNavContent = (
      <VStack
        pos="absolute"
        zIndex={1000000}
        top={0}
        left={0}
        right={0}
        display={mobileNav.isOpen ? "flex" : "none"}
        flexDirection="column"
        p={2}
        pb={4}
        m={2}
        bgColor={bg}
        // bg={bg}
        spacing={3}
        rounded="sm"
        boxShadow={'md'}
      >
        <CloseButton
          aria-label="Close menu"
          justifySelf="self-start"
          onClick={mobileNav.onClose}
        />
        <Button w="full" variant="solid" color='blackAlpha.600'>
          Home
        </Button>
        <Button
          w="full"
          variant="solid"
          colorScheme="orange"
        >
          Campaign
        </Button>
        <Button w="full" variant="solid" color='blackAlpha.600'>
          Blog
        </Button>
        <Button as={Link} to='/pricing' w="full" variant="solid" color='blackAlpha.600'>
          Pricing
        </Button>
        <Button w="full" variant="solid" color='blackAlpha.600'>
          Contact
        </Button>
      </VStack>
    );

    return (
     <>
       <chakra.header
        ref={ref}
        // shadow={y > height ? "sm" : undefined}
        transition="box-shadow 0.2s"
        bg={bg}
        // borderTop="6px solid"
        // borderTopColor="orange.400"
        w="full"
        overflowY="hidden"
        // borderBottomWidth={2}
        color="gray.200"
        // bgColor='red.200'
        _dark={{
          color: "gray.900",
        }}
      >
        <chakra.div h="4.5rem" mx="auto" maxW="1200px">
          <Flex
            w="full"
            h="full"
            px="6"
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex align="flex-start">
              <Link to="/">
                <HStack>
                 <Text color='brand.button'>Logo</Text>
                </HStack>
              </Link>
            </Flex>
            <Flex>
             {!location.pathname.startsWith('/c/') &&  <HStack
                spacing="5"
                display={{
                  base: "none",
                  md: "flex",
                }}
              >
                <Button
                as={Link}
                href='/blog'
                  bg={bg}
                  color="gray.500"
                  display="inline-flex"
                  alignItems="center"
                  fontSize="md"
                  _hover={{
                    color: cl,
                  }}
                  _focus={{
                    boxShadow: "none",
                  }}
                >
                  Home
                </Button>
                <Button
                as={Link}
                href='/blog'
                  bg={bg}
                  color="gray.500"
                  display="inline-flex"
                  alignItems="center"
                  fontSize="md"
                  _hover={{
                    color: cl,
                  }}
                  _focus={{
                    boxShadow: "none",
                  }}
                >
                  Campaign
                </Button>
                <Button
                as={Link}
                href='/blog'
                  bg={bg}
                  color="gray.500"
                  display="inline-flex"
                  alignItems="center"
                  fontSize="md"
                  _hover={{
                    color: cl,
                  }}
                  _focus={{
                    boxShadow: "none",
                  }}
                >
                  Blog
                </Button>
                <Button
                as={Link}
                to='/pricing'
                  bg={bg}
                  color="gray.500"
                  display="inline-flex"
                  alignItems="center"
                  fontSize="md"
                  _hover={{
                    color: cl,
                  }}
                  _focus={{
                    boxShadow: "none",
                  }}
                >
                  Pricing
                </Button>
                <Button
                  bg={bg}
                  color="gray.500"
                  display="inline-flex"
                  alignItems="center"
                  fontSize="md"
                  _hover={{
                    color: cl,
                  }}
                  _focus={{
                    boxShadow: "none",
                  }}
                >
                  Contact
                </Button>
              </HStack>}
            </Flex>
            <Flex justify="flex-end" align="center" color="gray.400">
            {!location.pathname.startsWith('/c/') && <HStack
                spacing="5"
                display={{
                  base: "none",
                  md: "flex",
                }}
              >
                 {!isAuth ? <>
                <Button 
                bgColor='brand.button'
                as={Link}
                to='/auth'
                py={4}
                _hover={{
                    bgColor:'brand.buttonHover'
                }}
                color='white' variant="solid">
                  Sign in
                </Button>
                </> : 
                <>
                  <Avatar name={userDisplayName} src={userAvatarLink} />
                  <Menu>
  <MenuButton as={Button} variant='ghost' rightIcon={<FiChevronDown />}>
    {userDisplayName?.split(' ')[0]}
  </MenuButton>
  <MenuList>
    {userRole == 'admin' && <>
    <MenuGroup title='Admin'>
      <MenuItem as={Link} to='/admin-dashboard'>Admin Dashboard</MenuItem>
    </MenuGroup>
    <MenuDivider />
    </>}
    <MenuGroup title='Profile'>
      <MenuItem as={Link} to='/settings'>My Account</MenuItem>
      <MenuItem as={Link} to='/campaigns'>All Campaigns</MenuItem>
      <MenuItem as={Link} to='/current-plan'>My Plan</MenuItem>
    </MenuGroup>
    <MenuDivider />
    <MenuItem icon={<FiLogOut />}
    onClick={()=>signOutUser()}
    >Logout</MenuItem>
  </MenuList>
</Menu>
                </>
                }
              </HStack>}     
            </Flex>
            {!location.pathname.startsWith('/c/') && <>

            {isAuth ? <>
              <Box display={{
                base: "flex",
                md: "none",
              }}
              gap={2}
              alignItems='center'
              >
                   <Avatar name={userDisplayName} src={userAvatarLink} />
                  <Menu>
  <MenuButton as={IconButton} variant='ghost' icon={<FiChevronDown color='#A0AEC0' />}>
    {/* {userDisplayName?.split(' ')[0]} */}
  </MenuButton>
  <MenuList color='#A0AEC0'>
    {userRole == 'admin' && <>
    <MenuGroup title='Admin'>
      <MenuItem as={Link} to='/admin-dashboard'>Admin Dashboard</MenuItem>
    </MenuGroup>
    <MenuDivider />
    </>}
    <MenuGroup title='Profile'>
      <MenuItem as={Link} to='/settings'>My Account</MenuItem>
      <MenuItem as={Link} to='/campaigns'>All Campaigns</MenuItem>
      <MenuItem as={Link} to='/current-plan'>My Plan</MenuItem>
    </MenuGroup>
    <MenuDivider />
    <MenuItem icon={<FiLogOut />}
    onClick={()=>signOutUser()}
    >Logout</MenuItem>
  </MenuList>
</Menu>
            </Box>  
            </> : <>
             <IconButton
              display={{
                base: "flex",
                md: "none",
              }}
              aria-label="Open menu"
              fontSize="20px"
              color="gray.800"
              _dark={{
                color: "inherit",
              }}
              variant="ghost"
              icon={<FiMenu />}
              onClick={mobileNav.onOpen}
            />
            </>}
            </> }
          </Flex>
          {MobileNavContent}
        </chakra.div>
      </chakra.header>
     </>
    );
  };
  
export default Header ;