import React,{useContext} from 'react'
import {
  Heading,
  Box,
  Flex,IconButton,
  Text,Fade,Button,Spinner, Center
} from '@chakra-ui/react'
import Users from './Users'
import Plans from './Plans'
import Metadata from '../../Components/Metadata'
import { UserContext } from '../../api';
import { FiArrowLeft } from "react-icons/fi";
import { Link } from 'react-router-dom'
import { BASEURL } from './../../api'
import { auth } from '../../api/firebase-config'
import { onAuthStateChanged } from '@firebase/auth'
import axios from 'axios'

const AdminDashboard = () => {
  const [accessToken, setAccessToken] = React.useState('')
  const [isAdminState, setIsAdminState] = React.useState(false)
  const [isFetching, setIsFetching] = React.useState(false)
  const { setActivePage } = useContext(UserContext)
const [currentMode,setCurrentMode] = React.useState('USERS')

onAuthStateChanged(auth, currentUser => {
  setAccessToken(currentUser.accessToken)
})

const isAdmin = (token) => {
  setIsFetching(true)
  axios.post(`${BASEURL}/admin/isAdmin`, {
    accessToken:token
  }).then(res => {
    setIsAdminState(res.data.isAdmin)
    setIsFetching(false)
  }).catch((err) => {
      console.log(err)
      // showToast('error', `Cannot activate account : ${err.response.data}`, 'error', 2500)
  })
}

React.useEffect(()=>{
 
if(accessToken.length > 0) {
  isAdmin(accessToken)
}
},[accessToken])

React.useEffect(()=>{
  setCurrentMode('USERS')
  setActivePage('ADMINDASH')
},[])
  return (
    <Flex
    minH={"100vh"}
    >
      
      {isFetching ? <Center w={'100%'}>
        <Spinner color={'brand.button'} size='lg' />
      </Center> : <>
      {!isAdminState ? <Box w={'100%'}>
        <Heading as="h1" textAlign={"center"}>Unauthorized Access.</Heading>
      </Box> : <>
    <Metadata title={'Admin Dashboard'}/>
    <Flex pt={10} bgColor='#FEEBCB' 
    w={'20%'}
    px={3.5}
    direction='column'
    justify={'space-between'}
   >
           <Box>
           <Text fontSize={'3xl'} fontWeight='bold'>Admin</Text>
          <Box
             my={10}
             borderLeftWidth={3}
             pl={2}
            borderLeftColor={currentMode == 'USERS' ? '#9C4221' : 'brand.button'}
            >
              <Button variant='link' color='#000' onClick={()=>setCurrentMode('USERS')}>
              <Text fontWeight={500}>Users Management</Text>
              </Button>
            </Box>
           <Box
             my={10}
             pl={2}
            borderLeftWidth={3}
            borderLeftColor={currentMode == 'PLANS' ? '#9C4221' : 'brand.button'}
            >
              <Button variant='link' color='#000' onClick={()=>setCurrentMode('PLANS')}>
              <Text fontWeight={500}>Plans Management</Text>
              </Button>
            </Box>
          </Box>
          <Box pos='relative' top={-10}>
          <Link to='/'>
          <Flex mt={20}
           >
          <IconButton 

          variant={'link'}
          color='brand.button'
          _hover={{
            color:'brand.buttonHover'
          }}
          icon={<FiArrowLeft size={20} />}
          />
           <Text fontSize={'1xl'} fontWeight='bold'>Back To Home</Text>
           </Flex>
    </Link>
          </Box>
           </Flex>
    <Box
    w={'70%'}
    px={10}
     >
      {currentMode == 'USERS' && <Box
        py={6}
        px={10}
        mt={4}
      >
            <Users accessToken={accessToken}/>
      </Box>}
          {currentMode == 'PLANS' && <Fade in={currentMode == 'PLANS' && true}>
      <Box
        py={6}
        px={10}
        my={10}
        rounded='md'
      >
         <Plans />
      </Box>
    </Fade>}

     </Box>  
      </>}
      </>}
    </Flex>
  );
}

export default AdminDashboard;