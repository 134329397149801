import {
    Box, Text, FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Flex,Input,Select, Textarea,Divider,Switch,
    Button,
    SimpleGrid,
    useDisclosure,Fade,  AlertDialog,
    AlertDialogBody,Avatar,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,InputGroup,InputLeftElement,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useToast,
    Image,
    CircularProgress,
    CircularProgressLabel
} from '@chakra-ui/react';
import React,{useState,useContext} from 'react'
import { FiCheck,FiThumbsUp,FiThumbsDown,FiChevronLeft,FiChevronRight,FiLink } from 'react-icons/fi'
import axios from 'axios'
import { BASEURL } from '../../api';
import Stepper from "react-stepper-horizontal";
import { onAuthStateChanged } from '@firebase/auth'
import { auth } from '../../api/firebase-config'
import { UserContext } from '../../api';

import AWS from 'aws-sdk';
import Campaigns from '../Dashboard/UserList.jsx/Campaigns';
import MailConfiguration from '../Dashboard/UserList.jsx/MailConfiguration';
import Metadata from '../../Components/Metadata';
const ShortUniqueId = require('short-unique-id')

window.Buffer = window.Buffer || require("buffer").Buffer;

const Emails = ({setActiveIndex}) => {
    const { setActivePage,setCurrentStep,currentStep ,currentSettingsMode,setCurrentSettingsMode,
        currentMailMode,setCurrentMailMode
    } = useContext(UserContext)
 
    const toast = useToast()
    const [user,setUser] = React.useState(null)
    const [mediaUploaded,setMediaUploaded] = React.useState(false)
    const [localImageSrc,setLocalImageSrc] = React.useState(null)
    const [progress,setProgress] = React.useState(0)
    const [campaignImage,setCampaignImage] = React.useState('')
    const showToast = (title,description,status,duration) => {
        toast({
            title,
            description,
            status,
            duration,
            isClosable: true,
        })
    }
    function ConfirmCancel() {
        const { isOpen, onOpen, onClose } = useDisclosure()
        const cancelRef = React.useRef()
      
        return (
          <>
            <Button
            flex={1}
            colorScheme='purple'
            size={'lg'}
            variant='outline'
            onClick={onOpen}
            >Cancel</Button>
      
            <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={cancelRef}
              onClose={onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    Cancel Creating a New Campaign
                  </AlertDialogHeader>
      
                  <AlertDialogBody>
                    Are you sure? All details you've entered will be deleted.
                  </AlertDialogBody>
      
                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button colorScheme='red' onClick={()=>setActiveIndex(1)} ml={3}>
                      Yes, I'm sure
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </>
        )
      }
      // After Submissions
      const [sendAutomatedEmailAfterSubmission,setAutomatedEmailAfterSubmission] = React.useState(false)
    // Page States
    const [detailsFormErrorMessages,setDetailsFormErrorMessages] = useState({
        campaignNameError:'',
        campaignTypeError:'',
        campaignDescriptionError:''
    })
    const [campaignNameError,setCampaignNameError] = useState('')
    const [campaignTypeError,setCampaignTypeError] = useState('')
    const [campaignDescriptionError,setCampaignDescriptionError] = useState('')
    const [mediaErrMssg,setMediaErrMssg] = React.useState('')
    const [emailTemplate,setEmailTemplate] = React.useState('')

    function TemplateVisualizationModal() {
        const { isOpen, onOpen, onClose } = useDisclosure()
        return (
          <>
            {emailTemplate.length != 0 && <Button 
                variant={'link'}
                colorScheme='purple'
                onClick={onOpen}
                >Visualize</Button>}
      
            <Modal isOpen={isOpen} onClose={onClose} size='full' isCentered>
              <ModalOverlay />
              <ModalContent mt={40} overflowY={'scroll'} minH='fit-content'>
                <ModalHeader>E-mail Template</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                <div dangerouslySetInnerHTML={{__html:emailTemplate}}/>
                </ModalBody>
      
                <ModalFooter>
                  <Button colorScheme='purple' variant={'outline'} mr={3} onClick={onClose}>
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </>
        )
      } 

      onAuthStateChanged(auth,currentUser=>{
        setUser(currentUser)
      })
    const [reviewState,setReviewState] = useState('')
    const [campaignDetails,setCampaignDetails] = useState({
        campaignType:'REVIEW',
        campaignName:'',
        campaignDescription:''
    })
    const [reviewFormSettings,setReviewFormSettings] = useState({
        orderID:true,
        phoneNumber:false,
        whatsappNumber:false,
        modelNumber:false,
        productID:false,
        shipmentNumber:false,
        media:true,
        customMessage:false
    })
    const [warrantyFormSettings,setWarrantyFormSettings] = useState({
        orderID:true,
        phoneNumber:false,
        whatsappNumber:false,
        modelNumber:false,
        productID:false,
        shipmentNumber:false,
        media:false,
        customMessage:false
    })
    const [registrationFormSettings,setResigtrationFormSettings] = useState({
        orderID:false,
        phoneNumber:false,
        whatsappNumber:false,
        modelNumber:false,
        productID:false,
        shipmentNumber:false,
        media:false,
        customMessage:false
    })
    const [thankYouPageSettings,setThankYouPageSettings] = useState({
        heading:'Thank You',
        subheading:'Your submission has been sent successfully.',
        redirectUrl:'',
        redirectButtonText:'Visit',
        isRedirectButtonVisible:false
    })
    const [redirectButtonVisible,setRedirectButtonVisible] = useState(false)
    const [reviewSubmissionHeading,setReviewSubmissionHeading] = useState('Give a Review For This Product.')
    const [registrationSubmissionHeading,setRegistrationSubmissionHeading] = useState('Register This Product.')
    const [WarrantySubmissionHeading,setWarrantySubmissionHeading] = useState('Get a Warranty For This Product.')


    // Page Methods

    const handleSatisfied = () => setReviewState('Satisfied')
    const handleUnSatisfied = () => setReviewState('Unsatisfied')
  
    const handleThankYouPageSettingsChanges = (data) => {
        setThankYouPageSettings({...thankYouPageSettings,...data})
    }

    const handleSubmissionFormChanges = (data) => {
        switch(campaignDetails.campaignType) {
            case 'REVIEW':
                setReviewFormSettings({...reviewFormSettings,...data})
            break
            case 'REGISTRATION':
                setResigtrationFormSettings({...registrationFormSettings,...data})
            break
            case 'WARRANTY':
               setWarrantyFormSettings({...warrantyFormSettings,...data})
            break
            default:
                setReviewFormSettings({...reviewFormSettings,...data})
        }
    }

    const handleRedirectButtonChanges = (switcherState) => {
        setThankYouPageSettings({...thankYouPageSettings,isRedirectButtonVisible:switcherState})
        setRedirectButtonVisible(switcherState)
    }

    const renderFormBooleans = () => {
        switch(campaignDetails.campaignType) {
            case 'REVIEW':
                return reviewFormSettings
            break
            case 'REGISTRATION':
                return registrationFormSettings
            break
            case 'WARRANTY':
                return warrantyFormSettings
            break
            default:
                return reviewFormSettings
        }
    }
    
    const renderFormHeading = () => {
        switch(campaignDetails.campaignType) {
            case 'REVIEW':
                return reviewSubmissionHeading
            break
            case 'REGISTRATION':
                return registrationSubmissionHeading
            break
            case 'WARRANTY':
                return WarrantySubmissionHeading
            break
            default:
                return reviewSubmissionHeading
        }
    }

    const handleFormHeadingChanges = (newHeading) => {
        switch(campaignDetails.campaignType) {
            case 'REVIEW':
                setReviewSubmissionHeading(newHeading)
            break
            case 'REGISTRATION':
                setRegistrationSubmissionHeading(newHeading)
            break
            case 'WARRANTY':
                setWarrantySubmissionHeading(newHeading)
            break
            default:
                setReviewSubmissionHeading(newHeading)
        }
    }


    // Submit Campaign
    const submitCampaign = () => {
        let Errmsg = 'This field cannot be empty.'
        if(thankYouPageSettings.heading.length == 0) setThankYouPageHeadingMissed(Errmsg)
            if(thankYouPageSettings.subheading.length == 0) setThankYouPageSubHeadingMissed(Errmsg)
            if(thankYouPageSettings.heading.length == 0 ||
                thankYouPageSettings.subheading.length == 0
                ){
                   return
                }
        axios.post(`${BASEURL}/campaigns`,{
            sellerId:user.uid,
            ...campaignDetails,
            formHeading:renderFormHeading(),
            ...renderFormBooleans(), // this function returns an object
            ...thankYouPageSettings,
            sendAutomatedEmailAfterSubmission,
            campaignImage,
            emailTemplate, // Email tamplate
        }).then((res)=>{
            // console.log(res.data)
            setActiveIndex(1)
        }).catch((err)=>{
            showToast('Error','Email Not Verified Or Disabled!','error',3000)
        })

    }
    const [goSteps, setGoSteps] = React.useState(0);
    

    const [headingMissedMsg,setIsHeadingMissedMsg] = React.useState('')
    const [thankYouPageHeadingMissed,setThankYouPageHeadingMissed] = React.useState('')
    const [thankYouPageSubHeadingMissed,setThankYouPageSubHeadingMissed] = React.useState('')
    const filterSteps = (currentStep) => {
        let Errmsg = 'This field cannot be empty!'
        // console.log('step count',currentStep)
        
        if(currentStep == 0) {
            if(campaignDetails.campaignType.length == 0) {
                setCampaignTypeError(Errmsg)
            }if(campaignDetails.campaignName.length == 0) {
               setCampaignNameError(Errmsg)
            }if(campaignDetails.campaignDescription.length == 0) {
              setCampaignDescriptionError(Errmsg)
            }
            // if(campaignImage.length  == 0) {
            //     setMediaErrMssg(Errmsg)
            // }

            if(campaignDetails.campaignType.length != 0 &&
                campaignDetails.campaignDescription.length != 0 &&
                campaignDetails.campaignName.length != 0 
                ) {
                    setGoSteps(prev=>prev+1)
                }

        }
        if(currentStep == 1) {
            switch(campaignDetails.campaignType) {
                case 'REVIEW':
                    if(reviewSubmissionHeading.length == 0) setIsHeadingMissedMsg(Errmsg)
                    else setGoSteps(prev=>prev+1)
                break
                case 'REGISTRATION':
                    if(registrationSubmissionHeading.length == 0) setIsHeadingMissedMsg(Errmsg)
                    else setGoSteps(prev=>prev+1)
                    break
                case 'WARRANTY':
                    if(WarrantySubmissionHeading.length == 0) setIsHeadingMissedMsg(Errmsg)
                    else setGoSteps(prev=>prev+1)
                    break
                default:
                  
            } 
        }
        if(currentStep == 2) {
            if(thankYouPageSettings.heading.length == 0) setThankYouPageHeadingMissed(Errmsg)
            if(thankYouPageSettings.subheading.length == 0) setThankYouPageSubHeadingMissed(Errmsg)
            if(thankYouPageSettings.heading.length != 0 &&
                thankYouPageSettings.subheading.length == 0
                ){
                //    submitCampaign()
                }
        }
    }

        const handleInputFocus = () => {
           setCampaignNameError('')
           setCampaignTypeError('')
           setCampaignDescriptionError('')
           setMediaErrMssg('')
        }
       
        function createObjectURL(object) {
            return (window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object);
        }

        const handleImageChange = (e) => {
            // reset conditions 
            setMediaErrMssg('')
            setMediaUploaded(false)
            setProgress(0)
            setLocalImageSrc(null)
            let file = e.target.files[0]
            let fileName = file.name
            
            if(file.size > 1 * 1024 * 1024){
                showToast('Upload Error','The file size must be less than or equal to 10MB','warning',3000)
                return
            }
            setLocalImageSrc(createObjectURL(file))
            // console.log('changed image',file)
        
            const s3 = new AWS.S3({
                correctClockSkew: true,
                endpoint: 'https://s3.wasabisys.com', //use appropriate endpoint as per region of the bucket
                region: 'us-west-1',
                accessKeyId: process.env.REACT_APP_WASABI_ACCESSKEY,
                secretAccessKey: process.env.REACT_APP_WASABI_SECRETKEY,
                logger: console,
                signatureVersion:'v4',
              });
        
            const uid = new ShortUniqueId()
          let newUID = uid()
          const uploadRequest = new AWS.S3.ManagedUpload({
            params: { Bucket: process.env.REACT_APP_WASABI_BUCKETNAME, Key: `${newUID}-${fileName}`, Body: file },
            service: s3
          });
        
          uploadRequest.on('httpUploadProgress', function(event) {
            const progressPercentage = Math.floor(event.loaded * 100 / event.total);
            // console.log('Upload progress ' + progressPercentage);
            setProgress(progressPercentage)
          });
        
        //   console.log('Configured and sending');
        
          uploadRequest.send(function(err) {
            if (err) {
              console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));
            } else {
                setMediaUploaded(true)
                let url = `https://s3.us-east-1.wasabisys.com/${process.env.REACT_APP_WASABI_BUCKETNAME}/${newUID}-${fileName}`
                setCampaignImage(url)
            }
          });
        
        
        }
        const handleThankYouFocus = () => {
            setThankYouPageHeadingMissed('')
            setThankYouPageSubHeadingMissed('')
        }
        
        React.useEffect(()=>{
          setCurrentMailMode('USERS')
            setActivePage('EMAILS')
        },[])
        
    return (
       <>
      <Flex>
        <Metadata title={'E-mails Marketing'}/>
      <Box
      w={{base:'100%',md:'60%'}}
      px={{base:1,sm:10}}
       >
       <Text as='h1' fontSize='2xl' m={2} fontWeight='semibold'>Campaigns List</Text>
        {currentMailMode == 'USERS' && <Box
          py={6}
          px={10}
          mt={4}
        >
             <Campaigns />
        </Box>}
            {currentMailMode == 'MAILSETTINGS' && <Fade in={currentMailMode == 'MAILSETTINGS' && true}>
        <Box
          py={6}
          px={10}
          my={10}
          rounded='md'
        >
          <MailConfiguration />
        </Box>
      </Fade>}

       </Box>
      </Flex>
       </>
    );
}

export default Emails;