import React,{useContext} from 'react'
import { Box,
    useColorModeValue,chakra,Button,Icon,HStack,Flex,PopoverContent,IconButton,useDisclosure,
    useColorMode,SimpleGrid,Stack,Popover,PopoverTrigger,VStack,Text,CloseButton, Divider, Avatar, Center, Image 
} from '@chakra-ui/react';
import { FiArrowRight } from 'react-icons/fi'
import { useViewportScroll } from 'framer-motion';
import { UserContext } from '../../api';
import { auth } from '../../api/firebase-config';
import { Link } from 'react-router-dom'
const Home =  () => {
  console.log(auth.currentUser)
  const { isAuth,setIsAuth,setActivePage } = useContext(UserContext)
    const { toggleColorMode: toggleMode } = useColorMode();
    const text = useColorModeValue("dark", "light");
    // const SwitchIcon = useColorModeValue(FaMoon, FaSun);
    const bg = useColorModeValue("white", "gray.800");
    const ref = React.useRef(null);
    const [y, setY] = React.useState(0);
    const height = ref.current ? ref.current.getBoundingClientRect() : 0;
    const { scrollY } = useViewportScroll();
    React.useEffect(() => {
      return scrollY.onChange(() => setY(scrollY.get()));
    }, [scrollY]);
    const cl = useColorModeValue("gray.800", "white");
    const mobileNav = useDisclosure();
  
    const MobileNavContent = (
      <VStack
        pos="absolute"
        zIndex={1000000}
        top={0}
        left={0}
        right={0}
        display={mobileNav.isOpen ? "flex" : "none"}
        flexDirection="column"
        p={2}
        pb={4}
        m={2}
        bgColor={bg}
        // bg={bg}
        spacing={3}
        rounded="sm"
        boxShadow={'md'}
      >
        <CloseButton
          aria-label="Close menu"
          justifySelf="self-start"
          onClick={mobileNav.onClose}
        />
        <Button w="full" variant="solid" color='blackAlpha.600'>
          Home
        </Button>
        <Button
          w="full"
          variant="solid"
          colorScheme="orange"
        >
          Campaign
        </Button>
        <Button w="full" variant="solid" color='blackAlpha.600'>
          Blog
        </Button>
        <Button w="full" variant="solid" color='blackAlpha.600'>
          Pricing
        </Button>
        <Button w="full" variant="solid" color='blackAlpha.600'>
          Contact
        </Button>
      </VStack>
    );

    const Testimonial = ({degree,pillBgColor,pillText,pillColor,content,clientName}) => {
      return <Box maxW={'fit-content'}>
      <Box
       style={{
         transform:`rotateZ(${degree}deg)`
       }}
       boxShadow='lg'
       pt={7}
       px={4}
       borderRadius={6}
       >
      <Box bgColor={pillBgColor} maxW='fit-content' borderRadius={10}>
         <Text
         fontWeight={600}
         color={pillColor}
         fontSize='md'
         px={2}
         py={1}
         >{pillText}</Text>
       </Box>
       <Box py={2}>
         <Text>{content}</Text>
       </Box>
      </Box>
      <Flex my={7} align='center' justify={'center'}>
      <Avatar name={clientName} src='#' />
      </Flex>
     </Box>
    }
    const heroDescriptionParagraphs = [
      'QR Codes give you the power to lead your visitors to web',
      'content valuable to your marketing efforts. Make redeeming',
      'coupons easier, amplify your social media reach, and make it',
      'easier for your audience to find your product '
    ]
    const HeroDescription = ({content}) => {
      return <Text 
      textAlign={'center'}
      fontWeight={600}
      as='span' display='block' color='blackAlpha.600'
      fontSize='lg'
      >
     {content}
      </Text>
    }

    React.useEffect(()=>{
      setActivePage('HOME')
    },[])
    return (
      <Box>
       <Flex justifyContent={'center'}
      alignItems='center'
      minH={!isAuth ? '95vh' : '100vh'}
      // minW={'100vw'}
       >
       <SimpleGrid
       pt={{base:50,md:0}}
        columns={[1,null,3]}
        spacing={50}
        // bgColor="red.200"
        >
          <Flex
          direction={'column'}
          gap={35}
          >
          <Testimonial 
      degree={2}
      pillText='IMPROVEMENT'
      pillBgColor={'#FED7D7'}
      pillColor='#C53030'
      content='Just Helpful for me and I like it'
      clientName={'K T'}
      />
      <Testimonial 
      degree={-4}
      pillText='COOL APP'
      pillBgColor={'#E9D8FD'}
      pillColor='#6B46C1'
      content="It's amazing app for company to managing"
      clientName={'A S'}
      />
          </Flex>
          <Flex
          direction={'column'}
          align='center'
          justify='center'
          >
           <Text as='h1' fontSize={'4xl'} textAlign='center' fontWeight={900}
           lineHeight={1}
           >Easy way to manage your productivity</Text>
            <Box display={{base:'block',sm:'none'}} my={4}>
              <Text as='p' textAlign='center' color='blackAlpha.600'>
       QR Codes give you the power to lead your visitors to web
       content valuable to your marketing efforts. Make redeeming
       coupons easier, amplify your social media reach, and make it
       easier for your audience to find your product.
              </Text>
            </Box>
            <Box w={'max-content'} display={{base:'none',sm:'block'}} my={4}>
            {heroDescriptionParagraphs.map(text=>{
            return <HeroDescription content={text}/>
          })}
            </Box>
          <Button bgColor='brand.button' my={5}
          as={Link}
          to={!isAuth ? '/auth' : '/create-campaign'}
                // py={4}
                _hover={{
                    bgColor:'brand.buttonHover'
                }}
                color='white' variant="solid"
                rightIcon={<FiArrowRight size={19} />}
                >
            <Text>Create Campain Now</Text>
          </Button>
          </Flex>
          <Flex
          direction={'column'}
          align='flex-end'
          gap={35}
          pl={{base:0,md:10}}
          >
          <Testimonial 
      degree={5}
      pillText='AWESOME'
      pillBgColor={'#FEEBCB'}
      pillColor='#DD6B20'
      content='I think its good app for managing'
      clientName={'R L'}
      />
      <Testimonial 
      degree={-4}
      pillText='COOL APP'
      pillBgColor={'#FED7E2'}
      pillColor='#ED64A6'
      content="It's amazing app for company to managing"
      clientName={'M B'}
      />
          </Flex>
        </SimpleGrid>
       
       </Flex>
      </Box>
    );
  };
  
export default Home ;