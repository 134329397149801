import React,{useState} from 'react'
import { Button,Heading, Text,Box, Flex,Tooltip , VStack, IconButton, HStack} from '@chakra-ui/react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Dashboard from './Pages/Dashboard/Dashboard';
import Campaign from './Pages/Campaign/Campaign';
import Pricing from './Pages/Pricing/Pricing';
import SubmissionsDetails from './Pages/Dashboard/SubmissionsDetails';
import MyPlan from './Pages/MyPlan';
import EditCampaign from './Pages/Dashboard/EditCampaign/EditCampaign';
import { UserContext } from './api'
import AdminDashboard from './Pages/Admin/AdminDashboard';
import EmailActions from './Pages/TransactionalPages/EmailActions';
import Submissions from './Pages/Dashboard/UserList.jsx/Submissions';
import Metadata from './Components/Metadata';
import Home from './Pages/Home/Home';
import Auth from './Pages/Auth/Auth';
import Header from './Components/Header/Header';
import HeaderSidebar from './Components/Header/HeaderSidebar';
import { FiHome,FiSend,FiSettings,FiPlus,FiBarChart,FiArrowRightCircle,FiArrowLeftCircle } from "react-icons/fi";
import { Link,useLocation } from 'react-router-dom'
import CampaignsList from './Pages/Dashboard/CampaignsList/CampaignsList';
import CreateCampaign from './Pages/Dashboard/CreateCampaign/CreateCampaign';
import Settings from './Pages/Settings/Settings';
import Emails from './Pages/Emails/Emails';
import { FiArrowLeft } from "react-icons/fi";
import { QRCodeCanvas,QRCodeSVG } from 'qrcode.react';
import Analytics from './Pages/Analytics/Analytics';

function App() {
  const location = useLocation()
  const [isAuth,setIsAuth] = useState(localStorage.getItem('isAuth'))
  const [userDisplayName,setUserDisplayName] = React.useState(localStorage.getItem('userDisplayName'))
  const [activePage,setActivePage] = useState(localStorage.getItem(''))
  const [allowedHeaderUrl,setAllowedHeaderUrl] = React.useState(false)
  const [allowedPaddingHV,setAllowedPaddingHV] = React.useState(false)
  const [allowedSidebarUrl,setAllowedSidebarUrl] = React.useState(false)
  const [currentStep,setCurrentStep] = React.useState(1)
  const [currentSettingsMode,setCurrentSettingsMode] = React.useState('PERSONAL')
  const [currentMailMode,setCurrentMailMode] = React.useState('USERS')

  // Settings
  const [isPasswordLoginSettigns,setIsPasswordLoginSettings] = React.useState(false)
  const [userAvatarLink,setUserAvatarLink] = React.useState(localStorage.getItem('userAvatarLink'))
  // Qr Code In creation Page
  const [campaignQrId,setCampaignQrId] = React.useState('')
  // Push Content Sidebar
  const [isPushed,setIsPushed] = React.useState(false)
  const push_toggle = () => {
      setIsPushed(!isPushed)
  }
  // Admin State
  const [userRole,setUserRole] = React.useState(localStorage.getItem('role'))
  let globalState = {
    isAuth,setIsAuth,activePage,setActivePage,setCurrentStep,currentStep,
    currentSettingsMode,setCurrentSettingsMode,currentMailMode,setCurrentMailMode,userDisplayName,setUserDisplayName,
    isPasswordLoginSettigns,setIsPasswordLoginSettings,userAvatarLink,setUserAvatarLink,
    campaignQrId,setCampaignQrId,userRole,setUserRole
  }

const allowedSideBarUrls = () => {
  let lp = location.pathname
  let isAllowed = (lp.startsWith('/analytics')) || (lp.startsWith('/dashboard/edit-campaign/')) || (lp.startsWith('/users-submissions/')) || (lp.startsWith('/dashboard/submissions/')) || (lp == '/dashboard') || (lp == '/send-emails') || (lp == '/campaigns') || (lp == '/create-campaign') || (lp == '/settings')
  setAllowedSidebarUrl(isAllowed)
}

const allowedHeaderUrls = () => {
  let lp = location.pathname
  let isAllowed = (lp == '/') || (lp == '/pricing') || (lp == '/current-plan') || lp.startsWith('/c/')
  setAllowedHeaderUrl(isAllowed)
}

const NotAllowedPaddingHV = () => {
  let lp = location.pathname
  let isAllowed = (lp != '/auth') || (lp != '/email-actions')
  setAllowedPaddingHV(isAllowed)
}

React.useEffect(()=>{
  allowedSideBarUrls()
  NotAllowedPaddingHV()
  allowedHeaderUrls()
},[location])

const downloadQRInPNG = (id) => {
  const canvas = document.getElementById(id);
  const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
  let downloadLink = document.createElement("a");
  downloadLink.href = pngUrl;
  downloadLink.download = `${id}.png`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
const downloadQRInSVG = (id) => {
  var svg = document.getElementById(id);

var serializer = new XMLSerializer();
var source = serializer.serializeToString(svg);

if(!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)){
    source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
}
if(!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)){
    source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
}

//add xml declaration
source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

var downloadLink = document.createElement("a");
var url = "data:image/svg+xml;charset=utf-8,"+encodeURIComponent(source);
downloadLink.href = url;
downloadLink.download = "newesttree.svg";
  
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
return <>
 {/* <Container maxW={1200}> */}
 <Box
 display={(isAuth && allowedSidebarUrl) && 'flex'}
 minH={'100vh'}
 >
   <UserContext.Provider value={{...globalState}}>
    {/* <Router> */}
{(isAuth && allowedSidebarUrl) && <Box
px={4}
py={2}
pos='relative'
zIndex={100}
>
 
  <Link to='/'>
  <Text fontWeight={'bold'}
  py={3}
  >LOGO</Text>
  </Link>
  <IconButton
  visibility={{base:((location.pathname != '/campaigns') && (location.pathname != '/analytics')) ? 'visible' : 'hidden',lg:'hidden'}} 
  onClick={()=>push_toggle()}
  variant='ghost'
  icon={!isPushed ? <FiArrowRightCircle  size={23}/> : <FiArrowLeftCircle size={23} />}
  />
  <VStack
  justify={'center'}
  h={'90%'}
  >
    <Tooltip label='Campaigns List'>
    <IconButton 
     as={Link}
     to='/campaigns'
     icon={<FiHome size={23} color={activePage == 'CAMPAIGNSLIST' ? '#fff' : ''} />}
    variant='ghost'
    _focus={{
      boxShadow:'none' 
      }}
    />
  </Tooltip>
    <Tooltip label='Create Campaign'>
    <IconButton 
    as={Link}
    to='/create-campaign'
    icon={<FiPlus  size={23} color={activePage == 'CREATECAMPAIGN' ? '#fff' : ''}/>}
     variant='ghost'
     _focus={{
     boxShadow:'none' 
     }}
    />
  </Tooltip>
    <Tooltip label='Analytics'>
    <IconButton 
    as={Link}
    to='/analytics'
    icon={<FiBarChart size={23} color={activePage == 'ANALYTICS' ? '#fff' : ''}/>}
    variant='ghost'
    _focus={{
      boxShadow:'none' 
      }}
    />
  </Tooltip>
    <Tooltip label='Send E-mails'>
    <IconButton 
    as={Link}
    to='/send-emails'
    icon={<FiSend  size={23} color={activePage == 'EMAILS' ? '#fff' : ''}/>}
    variant='ghost'
    _focus={{
      boxShadow:'none' 
      }}
    />
  </Tooltip>
    <Tooltip label='Settings'>
    <IconButton 
    as={Link}
    to='/settings'
    icon={<FiSettings  size={23} color={activePage == 'SETTINGS' ? '#fff' : ''}/>}
    variant='ghost'
    _focus={{
      boxShadow:'none' 
      }}
    />
  </Tooltip>

  </VStack>
</Box>}
{( (activePage == 'CAMPAIGNSLIST' &&( location.pathname.startsWith('/dashboard/submissions/') || location.pathname.startsWith('/dashboard/edit-campaign/'))) ||activePage == 'CREATECAMPAIGN' || activePage == 'SETTINGS' || activePage == 'EMAILS' || activePage == 'SUBMISSIONS') && <Flex 
     display={{base:!isPushed  && 'none',lg:'flex'}}
      bgColor='#FEEBCB'
       direction='column'
       align='stretch'
       justify={(location.pathname == '/settings' || location.pathname == '/send-emails') ? 'flex-start' : (location.pathname.startsWith('/users-submissions/') || location.pathname.startsWith('/dashboard/submissions/')) ? 'flex-end' : 'space-evenly'}
       w={{base:'50%',lg : (location.pathname.startsWith('/users-submissions/') || location.pathname.startsWith('/dashboard/submissions/') ) ? '10%' : '20%'}}
        borderTopLeftRadius={25}
        borderBottomLeftRadius={25}
        px={3.5}
        >
           {(activePage == 'CREATECAMPAIGN' || location.pathname.startsWith('/dashboard/edit-campaign/')) && <>
           <Box>
            <Box
            my={10}
            borderTopWidth={3}
            borderTopColor={currentStep == 1 ? '#9C4221' : 'brand.button'}
            >
              <Text color='brand.buttonHover' fontWeight={600}>STEP 1</Text>
              <Button variant='link' color='#000' onClick={()=>null}>
              <Text fontWeight={500}>Campaign Info</Text>
              </Button>
            </Box>
            <Box
             my={10}
            borderTopWidth={3}
            borderTopColor={currentStep == 2 ? '#9C4221' : 'brand.button'}
            >
              <Text color='brand.buttonHover' fontWeight={600}>STEP 2</Text>
              <Button variant='link' color='#000' onClick={()=>null}>
              <Text fontWeight={500}>Details</Text>
              </Button>
            </Box>
            <Box
             my={10}
            borderTopWidth={3}
            borderTopColor={currentStep == 3 ? '#9C4221' : 'brand.button'}
            >
              <Text color='brand.buttonHover' fontWeight={600}>STEP 3</Text>
              <Button variant='link' color='#000' onClick={()=>null}>
              <Text fontWeight={500}>Submission</Text>
              </Button>
            </Box>
            </Box>
            {location.pathname.startsWith('/dashboard/edit-campaign/') ? <>
            <Box pos='relative'>
          <Link to='/campaigns'>
          <Flex mt={20}
           >
          <IconButton 
          
          variant={'link'}
          color='brand.button'
          _hover={{
            color:'brand.buttonHover'
          }}
          icon={<FiArrowLeft size={20} />}
          />
           <Text fontSize={'1xl'} fontWeight='bold'>Back</Text>
           </Flex>
    </Link>
          </Box>
            </> : <>
            <Box 
            display="flex"
            flexDirection={'column'}
            justifyContent='center'
            alignItems={'center'}
            >
              <Text color='#C05621' fontSize='2xl' fontWeight='bold' textAlign='center'>QR Code</Text>
              <QRCodeCanvas id='qrCodeCanvas' fgColor='#C05621' bgColor='transparent' level='L' value={`${window.location.origin}/c/${campaignQrId}`} 
              imageSettings={{
                width:'180px !important',
                height:'180px !important'
              }}
              style={{
                display:'none',
                width:180,
                height:180
              }}
              />
              <QRCodeSVG id='qrCodeSvg' fgColor='#C05621' bgColor='transparent' level='L' value={`${window.location.origin}/c/${campaignQrId}`} 
              imageSettings={{
                width:'180px',
                height:'180px'
              }}
              style={{
                width:180,
                height:180
              }}
              />

              <HStack align={'center'} justify='center' my={2}>
                <Button
                size='sm'
                  bgColor='brand.button'
                  py={4}
                  _hover={{
                      bgColor:'brand.buttonHover'
                  }}
                  color='white' variant="solid"
                  onClick={()=>downloadQRInPNG('qrCodeCanvas')}
                >PNG</Button>
                <Button
                 size='sm'
                  bgColor='brand.button'
                  py={4}
                  _hover={{
                      bgColor:'brand.buttonHover'
                  }}
                  color='white' variant="solid"
                  onClick={()=>downloadQRInSVG('qrCodeSvg')}
                >SVG</Button>
              </HStack>
            </Box>
            </>}
           </>}
           {activePage == 'SETTINGS' && <>
           <Box mt={20}>
           <Text fontSize={'3xl'} fontWeight='bold'>Settings</Text>
           <Box>
          <Box
             my={10}
             borderLeftWidth={3}
             pl={2}
            borderLeftColor={currentSettingsMode == 'PERSONAL' ? '#9C4221' : 'brand.button'}
            >
              <Button variant='link' color='#000' onClick={()=>setCurrentSettingsMode('PERSONAL')}>
              <Text fontWeight={500}>Personal Information</Text>
              </Button>
            </Box>
            {isPasswordLoginSettigns && <Box
             my={10}
             pl={2}
            borderLeftWidth={3}
            borderLeftColor={currentSettingsMode == 'PASSWORD' ? '#9C4221' : 'brand.button'}
            >
              <Button variant='link' color='#000' onClick={()=>setCurrentSettingsMode('PASSWORD')}>
              <Text fontWeight={500}>Password</Text>
              </Button>
            </Box>}
           
          </Box>
           </Box>
           </>}
           {activePage == 'EMAILS' && !location.pathname.startsWith('/users-submissions/') && <>
           <Box mt={20}>
           <Text fontSize={'3xl'} fontWeight='bold'>Send E-mails</Text>
           <Box>
          <Box
             my={10}
             borderLeftWidth={3}
             pl={2}
            borderLeftColor={currentMailMode == 'USERS' ? '#9C4221' : 'brand.button'}
            >
              <Button variant='link' color='#000' onClick={()=>setCurrentMailMode('USERS')}>
              <Text fontWeight={500}>Users</Text>
              </Button>
            </Box>
           <Box
             my={10}
             pl={2}
            borderLeftWidth={3}
            borderLeftColor={currentMailMode == 'MAILSETTINGS' ? '#9C4221' : 'brand.button'}
            >
              <Button variant='link' color='#000' onClick={()=>setCurrentMailMode('MAILSETTINGS')}>
              <Text fontWeight={500}>Mail Configuration</Text>
              </Button>
            </Box>
          </Box>
           </Box>
        
           </>}
           {activePage == 'EMAILS' && location.pathname.startsWith('/users-submissions/') && <>
           <Box pos='relative' top={-10}>
          <Link to='/send-emails'>
          <Flex mt={20}
           >
          <IconButton 
          
          variant={'link'}
          color='brand.button'
          _hover={{
            color:'brand.buttonHover'
          }}
          icon={<FiArrowLeft size={20} />}
          />
           <Text fontSize={'1xl'} fontWeight='bold'>Back</Text>
           </Flex>
    </Link>
          </Box>
        
           
           </>}
           {activePage == 'ADMINDASH' && <Box mt={20}>
           <Text fontSize={'3xl'} fontWeight='bold'>Settings</Text>
           <Box>
          <Box
             my={10}
             borderLeftWidth={3}
             pl={2}
            borderLeftColor={currentSettingsMode == 'PERSONAL' ? '#9C4221' : 'brand.button'}
            >
              <Button variant='link' color='#000' onClick={()=>setCurrentSettingsMode('PERSONAL')}>
              <Text fontWeight={500}>Personal Information</Text>
              </Button>
            </Box>
           <Box
             my={10}
             pl={2}
            borderLeftWidth={3}
            borderLeftColor={currentSettingsMode == 'PASSWORD' ? '#9C4221' : 'brand.button'}
            >
              <Button variant='link' color='#000' onClick={()=>setCurrentSettingsMode('PASSWORD')}>
              <Text fontWeight={500}>Password</Text>
              </Button>
            </Box>
          </Box>
           </Box> }
           {activePage == 'CAMPAIGNSLIST' && location.pathname.startsWith('/dashboard/submissions/') && <>
           <Box pos='relative' top={-10}>
          <Link to='/campaigns'>
          <Flex mt={20}
           >
          <IconButton 
          
          variant={'link'}
          color='brand.button'
          _hover={{
            color:'brand.buttonHover'
          }}
          icon={<FiArrowLeft size={20} />}
          />
           <Text fontSize={'1xl'} fontWeight='bold'>Back</Text>
           </Flex>
    </Link>
          </Box>
           </>}
        </Flex>}

<Box
pos='relative'
w={'100%'}
zIndex={100}
bgColor='white'
borderTopLeftRadius={(isAuth && allowedSidebarUrl) && location.pathname != '/create-campaign' && location.pathname != '/settings' && location.pathname != '/send-emails' && !location.pathname.startsWith('/users-submissions/') && !location.pathname.startsWith('/dashboard/submissions/') && !location.pathname.startsWith('/dashboard/edit-campaign/') &&  '25px'}
borderBottomLeftRadius={(isAuth && allowedSidebarUrl) && location.pathname != '/create-campaign' && location.pathname != '/settings'  && location.pathname != '/send-emails' && !location.pathname.startsWith('/users-submissions/') && !location.pathname.startsWith('/dashboard/submissions/') && !location.pathname.startsWith('/dashboard/edit-campaign/') && '25px'}
py={allowedPaddingHV || location.pathname != '/email-actions' && 5}
px={allowedPaddingHV ||  location.pathname != '/email-actions' && 8}
>
  
        {allowedSidebarUrl && <HeaderSidebar /> }
        {(allowedHeaderUrl) && <Header />}
        <Switch>
          <Route exact path="/">
        <Box px={{base:4,md:20}}>
            <Text as="h1" fontSize={'lg'}>
              <Metadata title="Home Page"/> 
              <Home />
            </Text>
          </Box>
          </Route>
          {!isAuth && <Route path="/auth">
          {/* <Auth /> */}
          <Auth />
          </Route>}
          <Route path="/pricing">
          <Pricing />
          </Route>
          <Route path="/email-actions">
         <EmailActions />
          </Route>
          {isAuth && <Route path="/dashboard" exact>
          <Dashboard />
          </Route>}
          {isAuth && <Route path="/campaigns" exact>
            <CampaignsList />
          </Route>}
          {isAuth && <Route path="/settings" exact>
            <Settings />
          </Route>}
          {isAuth && <Route path="/create-campaign" exact>
           <CreateCampaign />
          </Route>}
          {isAuth && <Route path="/analytics" exact>
           <Analytics />
          </Route>}
          {isAuth && <Route path="/send-emails" exact>
          <Emails />
          </Route>}
          {isAuth && <Route path="/dashboard/submissions/:campaignId" exact>
          <SubmissionsDetails />
          </Route>}
          {isAuth &&   <Route path="/dashboard/edit-campaign/:campaignId" exact>
          <EditCampaign />
          </Route>}
          {isAuth && <Route path="/current-plan">
            <MyPlan />
          </Route>}
          
          <Route path="/c/:campaignId">
          <Campaign />
          </Route>
          <Route path='/users-submissions/:campaignId' exact>
            <Submissions />
          </Route>
          {/* Admin Dashboard */}
          {isAuth && <Route path="/admin-dashboard" exact>
          <AdminDashboard />
          </Route>}
          <Route path="*">
            <Heading as="h1" textAlign={"center"}>Page not found.</Heading>
          </Route>
        </Switch>
</Box>
{/* </Router> */}
      </UserContext.Provider>
 </Box>
{/* </Container> */}
 </>
}
export default App;
