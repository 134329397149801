import React from 'react'
import { 
    Box,
    InputGroup,
    InputLeftAddon,
    Input,
    InputRightAddon,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Button,
    Text,
    Flex,
    Spinner,
    Divider,
    Checkbox,
    useToast,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
} from '@chakra-ui/react';
import { useHistory,useLocation } from 'react-router-dom'
import { onAuthStateChanged } from '@firebase/auth';
import { auth } from '../../../api/firebase-config';
import axios from 'axios'
import { BASEURL } from '../../../api';

const MailConfiguration = () => {
  const toast = useToast()
  const history = useHistory()
    const [currentLocation,setCurrentLocation] = React.useState(null)
    const [customSlug,setCustomSlug] = React.useState('')
    const [customDomain,setCustomDomain] = React.useState('')
    const [useCustomDomain,setUseCustomEmail] = React.useState(true)
    const [customMailMode,setCustomMailMode] = React.useState(false)
    const [isPaid,setIsPaid] = React.useState(false)
    const [isFetchingPlan,setIsFetchingPlan] = React.useState(true)
    const [user,setUser] = React.useState(null)

    const showToast = (title,description,status,duration) => {
      toast({
          title,
          description,
          status,
          duration,
          isClosable: true,
      })
  }

    onAuthStateChanged(auth,currentUser=>{
      setUser(currentUser)
    })
    const returnIsPaid = (plan) => {
        if(plan == 'free') return false
        else if(plan == 'paid') return true
    }
    const fetchCustomer = () => {
        // console.log(user.accessToken)
        axios.get(`${BASEURL}/sellers`,{
          params:{
            accessToken:user.accessToken
          }
        })
        .then(res=>{
          setCustomSlug(res.data.custom_slug)
          setCustomDomain(res.data.custom_domain)
          setUseCustomEmail(res.data.use_custom_domain)
          // setCustomDomain()
          setIsPaid(returnIsPaid(res.data.plan))
        }).catch(err=>{
          console.log(err)
        }).finally(()=>{
          setIsFetchingPlan(false)
        })
      }
      const [freeMsgErr,setFreeMsgErr] = React.useState(null)
      const [paidMsgErr,setPaidMsgErr] = React.useState(null)
      const validateAndSubmit = () => {
        let errMsg = 'This field cannot be empty.'
        let isDirty = false
        if(!isPaid){
          if(customSlug.length == 0) {
            isDirty = true
            setFreeMsgErr(errMsg)
          }
        }else {
          if(customSlug.length == 0 || customDomain.length == 0) {
            isDirty = true
            setPaidMsgErr(errMsg)
          }
        }
        // validate
        if(isDirty) return
        else handleSaveButton()
      }
      const handleFocus = () => {
        setFreeMsgErr(null)
        setPaidMsgErr(null)
      }
      const handleSaveButton = () => {
        // if(isPaid){
          axios.post(`${BASEURL}/sellers/custom-domain`,{
            accessToken:user.accessToken,
            customDomain,
            customSlug,
            useCustomDomain
          }).then(res=>{
            showToast('Success','Changes saved successfully.','success',1500)
          })
        // }
      }
const Loading = () => {
        return <Flex align='center' justify={'center'} p={5}>
        <Spinner />                        
        </Flex>
    }

    React.useEffect(()=>{
        setCurrentLocation(window.location.hostname)
        if(user) fetchCustomer()
    },[user])
    return ( 
        <>
       {isFetchingPlan ? <Loading /> :  <Box>
            <FormControl>
                <FormLabel htmlFor='email'>Sender Address</FormLabel>
              {(customMailMode == true) ?
             <>
              <InputGroup>
              <Input onFocus={()=>handleFocus()} type='email' placeholder={customSlug?.length == 0 ? 'Your Slug' : customSlug} onChange={e=>setCustomSlug(e.target.value)}/>
              <InputRightAddon children='@' 
              borderTopRightRadius={0}
              borderBottomRightRadius={0}
              />
              <Input onFocus={()=>handleFocus()} type='email' placeholder={customDomain?.length == 0 ? 'Your Domain' : customDomain}
              borderTopLeftRadius={0}
              borderBottomLeftRadius={0}
              onChange={e=>setCustomDomain(e.target.value)}
              />
            </InputGroup>
            <Box>
            <Checkbox colorScheme='orange' isChecked={useCustomDomain == '1'}
            onChange={e=>setUseCustomEmail(e.target.checked)}
            >Use This E-email</Checkbox>
            </Box>
             </> : <>
                <InputGroup>
                <Input  onFocus={()=>handleFocus()} type='email' placeholder={customSlug?.length == 0 ? 'Your Slug' : customSlug} onChange={e=>setCustomSlug(e.target.value)}/>
                <InputRightAddon children={`@${currentLocation}`} />
              </InputGroup>
               </>
              }
          <FormHelperText textAlign={'left'} color="red.300">{
          isPaid ? <>
          {paidMsgErr}
          </> : <>
         {freeMsgErr}
          </>
          }</FormHelperText>
            </FormControl>
            <Flex my={7} justify='space-between' alignItems='center' gap={{base:2,lg:0}}>
                <Button 
                bgColor='brand.button'
                py={4}
                _hover={{
                    bgColor:'brand.buttonHover'
                }}
                color='white' variant="solid" onClick={()=>validateAndSubmit()}>
                    Save
                </Button>
                <Flex fontSize='md' align='center' gap={1}
                
                >
                    
                    {!isPaid ? <>
                        <Text>Do you have your own domain?</Text>
                        <Button colorScheme={'orange'} variant='link' onClick={()=>history.push('/pricing')}>Upgrade</Button>
                    </> : <>
                    {!customMailMode ? 
                    <> 
                    <Button  onClick={()=>setCustomMailMode(true)}
                    py={4}
                    variant='link'
                    color='brand.buttonHover'
                    >Set Up Custom Domain</Button>
                    </> :
                     <>
                    <Button variant='link' onClick={()=>setCustomMailMode(false)}
                     py={4}
                     color='brand.buttonHover'
                    >Cancel</Button>
                    </>}
                    </>}
                </Flex>
            </Flex>
                        <Divider />
            {(isPaid && customMailMode) && <Box mt={2}>
                <Text as="h2">*Please add the following TXT record to your DNS settings after entering your custom email:</Text>
                <br />
                <TableContainer>
  <Table size='sm'>
    <Thead>
      <Tr>
        <Th>Location</Th>
        <Th>Type</Th>
        <Th>Value</Th>
      </Tr>
    </Thead>
    <Tbody>
      <Tr>
        <Td>yourdomain.com</Td>
        <Td>TXT </Td>
        <Td>v=spf1 a mx include:relay.mailchannels.net ~all</Td>
      </Tr>
    </Tbody>
  </Table>
</TableContainer>
            </Box>}
        </Box>}
        </>
    );
}
 
export default MailConfiguration;