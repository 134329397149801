import React,{useContext} from 'react'
import {
  Box,
  InputGroup,
  InputLeftAddon,
  Input,
  InputRightAddon,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Flex,
  Badge,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { useHistory, Link, useParams } from 'react-router-dom'
import axios from 'axios'
import { BASEURL } from '../../../api';
import { auth } from '../../../api/firebase-config';
import { onAuthStateChanged } from '@firebase/auth'
import { UserContext } from '../../../api';
import Metadata from '../../../Components/Metadata';
const Submissions = () => {
  const { setActivePage } = useContext(UserContext)
  const toast = useToast()
  const { campaignId } = useParams()
  const [emailTemplate, setEmailTempalte] = React.useState('')
  const [submissionsList, setSubmissionsList] = React.useState([])
  const [areSubmissionsFetched, setAreSubmissionsFetched] = React.useState(false)
  const [user, setUser] = React.useState(null)
  const [subject,setSubject] = React.useState('')
  const [subjectErrMssg,setSubjectErrMssg] = React.useState('')

  function TemplateVisualizationModal() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
      <>
        {emailTemplate.length != 0 && <Button
          variant={'link'}
          color='brand.button'
          onClick={onOpen}
        >Visualize</Button>}

        <Modal isOpen={isOpen} onClose={onClose} size='full' isCentered>
          <ModalOverlay />
          <ModalContent mt={40} overflowY={'scroll'} minH='fit-content'>
            <ModalHeader>E-mail Template</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div dangerouslySetInnerHTML={{ __html: emailTemplate }} />
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='orange' variant={'outline'} mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }


  const showToast = (title, description, status, duration) => {
    toast({
      title,
      description,
      status,
      duration,
      isClosable: true,
    })
  }

  const fetchSubmissions = () => {
    // pass the seller Id
    axios.get(`${BASEURL}/submissions/all-submissions/${campaignId}`, {
      params: {
        accessToken: user.accessToken
      }
    })
      .then(res => {
        console.log(res.data)
        setSubmissionsList(res.data.results.submissions)
        setAreSubmissionsFetched(true)
      }).catch(err => {
        console.log(err)
      })
  }

  onAuthStateChanged(auth, currentUser => {
    setUser(currentUser)
  })
  const sendMail = async () => {

    if(subject.length == 0) {
      setSubjectErrMssg('Subject cannot be empty!')
      return
    }
    const submissionsWithEmailsNotSent = submissionsList.filter(submission => {
      return (submission.email_sent != '1')
    })
    // console.log('submissionsWithEmailsNotSent',submissionsWithEmailsNotSent)
    if (submissionsWithEmailsNotSent != 0) {
      axios.post(`${BASEURL}/emails/send-mail`, {
        accessToken: user.accessToken,
        submissions: submissionsWithEmailsNotSent
      }).then(({data}) => {
        console.log('recieved data',data)
        let customSlug = data.customSlug
        let allowedEmails = data.emails
        let useCustomDomain = data.useCustomDomain
        let userPlan = data.userPlan
        let senderEmail = ''
        let hostName = window.location.hostname
        // let hostName = 'qrapp.pages.dev'
        if(userPlan == 'free') {
          if(customSlug.length == 0) {
            senderEmail = `${'contact'}@${hostName}`
          }else {
            senderEmail = `${customSlug}@${hostName}`
          }
          
        }else if(userPlan == 'paid'){
          if(customSlug.length == 0) {
            if(useCustomDomain == '1') {
              senderEmail = `${'contact'}@${data.customDomain}`
            }else{
              senderEmail = `${'contact'}@${hostName}`
            }
          }else{
            if(useCustomDomain == '1') {
              senderEmail = `${customSlug}@${data.customDomain}`
            }else {
              senderEmail = `${customSlug}@${hostName}`
            }
          }
        
        }

        let emailInfo = {
          allowedEmails,
          senderEmail,
          emailTemplate,
          emailSubject : subject,
          senderFullName : localStorage.getItem('userDisplayName')
        }
        
        if(data.canSend) {
          axios.post(`${BASEURL}/sendToWorker`,{
            emailInfo
          }).then((res)=>{
            // Show the toast: success and warning 
           if(res.data.status == 500 || res.data.status == 400) {
            showToast('Sending Failed!','Please use a proper Subject and E-mail','warning',2500)
           }else {
            axios.post(`${BASEURL}/emails/fill_emails`,{
              ids:data.ids,
              words:data.words,
              timeArr:data.timeArr,
              values:data.values
            })
              showToast('Email Sent Successfully!','','success',2500)
              setTimeout(()=>{
                window.location.reload()
              },2500)
           }
            console.log('sent from backend',res)
          }).catch(err=>console.log(err))
        
        }else {
          showToast('Sending Failed!','Quota exceeded!','warning',2500)
        }
      })
    } else {
      showToast('Invalid Operation', "No Submissions Available!.", 'info', 1700)
    }
  }
  const DynamicTr = ({ email, submissionId, emailSent }) => {
    return <>
     <Tr
     borderBottomColor={'brand.buttonHover'}
     borderBottomWidth={1.5}
     >
<Td maxW={170} overflowX='auto' pl={2} wordBreak='break-word'>
    {email}
</Td>
<Td display={'flex'} alignItems='center' justifyContent={'center'}>

{emailSent == '1' ?
            <Badge colorScheme='green' px={1} py={1.1} display='inline-flex' alignItems={'center'}>SENT</Badge>
            : <Badge colorScheme='orange' display='inline-flex' alignItems={'center'}>NOT SENT</Badge>
          }
</Td>
</Tr>
    </>
}

  React.useEffect(() => {
    if (user) fetchSubmissions()
  }, [user])

  React.useEffect(()=>{
    setActivePage('EMAILS')
},[])
  return (
    <Flex w={'100%'} gap={10}
      direction={{ base: 'column-reverse', md: 'row' }}
      px={7}
    >
      <Box w={{ base: '100%', md: '60%' }}>
      <Text color='brand.fieldName' fontSize={20} fontWeight={500} my={2}>Users List</Text>
        <TableContainer overflow={{lg:'hidden'}}>
  <Table variant={'unstyled'} size="lg" className='campaignsTable' >
    <Thead
    bgColor='brand.button'
    px={4}
    borderRadius={20}
    color='#fff'
    >
      <Tr>
        <Th style={{paddingLeft:'10px !important'}}>
            <Text pl={5} textAlign='left' fontWeight='semibold' fontSize={16}>E-mail</Text>
        </Th>
        <Th>
            <Text textAlign='center' fontWeight='semibold' fontSize={16}>Status</Text>
        </Th>
      </Tr>
    </Thead>
    <Tbody>
    
    {submissionsList.map(submission=>{
            return <DynamicTr email={submission['email']} submissionId={submission['submission_id']} emailSent={submission['email_sent']}/>
           })}
    </Tbody>
  </Table>
</TableContainer>
      </Box>
      <Box w={{ base: '100%', md: '40%' }}>
      <FormControl my={6}>
                    <FormLabel htmlFor='emailSubject'>
                    <Text color='brand.fieldName' fontSize={20} fontWeight={500}>E-mail Subject</Text>
                    </FormLabel>
                    <Input id='emailSubject' type='text' 
                    onChange={e=>setSubject(e.target.value)}
                    onFocus={()=>setSubjectErrMssg('')}
                    variant='outline'
                    borderColor={'brand.button'}
                    _focus={{
                        borderColor:'brand.buttonHover',
                        borderBottomWidth:3
                    }}
                    />
                    {/* <FormHelperText pos="absolute" bottom={-3} color='red.300'>test</FormHelperText> */}
                    <FormHelperText pos="absolute" bottom={-5} color='red.300'>{subjectErrMssg}</FormHelperText>
                </FormControl>
        <FormControl>
          <FormLabel
            display={'flex'}
            justifyContent='space-between'
            htmlFor='email'
            alignItems={'center'}
          > 
          <Text color='brand.fieldName' fontSize={20} fontWeight={500}>E-mail Template</Text>
            <TemplateVisualizationModal />
          </FormLabel>
          <Textarea placeholder='Paste HTML code here ...'
            variant={'outline'}
            borderColor='brand.button'
            _focus={{
              borderColor:'brand.buttonHover'
            }}
            minH={300}
            onChange={(e) => setEmailTempalte(e.target.value)}
          />
        </FormControl>
        <Button w={'full'} 
         bgColor={'brand.button'}
         color='#fff'
         variant='solid'
         _hover={{
             bgColor:'brand.buttonHover'
         }}
        my={5}
          onClick={() => sendMail()}
        >
          SEND E-MAIL
        </Button>
      </Box>
    </Flex>
  );
}

export default Submissions;