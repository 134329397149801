// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getAuth,GoogleAuthProvider } from '@firebase/auth'

// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
//     appId: process.env.REACT_APP_FIREBASE_APPID
//   };

const firebaseConfig = {
  apiKey: "AIzaSyAW1A956Sy5YLY4ZB6_BfDoOXm1uBcrd9U",
  authDomain: "qr-scanner-469c4.firebaseapp.com",
  projectId: "qr-scanner-469c4",
  appId: "1:304149671586:web:afaf4bf0eaaddd7e1271fb"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const authProvider = new GoogleAuthProvider()
export const auth = getAuth(app)
