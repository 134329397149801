import {
    Box, Text, FormControl,
    FormLabel,
    FormHelperText,
    Flex,Input, Textarea,
    Button,
    useDisclosure,Fade,  AlertDialog,
    AlertDialogBody,Avatar,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast,
    CircularProgress,
    CircularProgressLabel,
} from '@chakra-ui/react';
import React,{ useContext} from 'react'
import axios from 'axios'
import { BASEURL } from '../../api';
import { onAuthStateChanged } from '@firebase/auth'
import { auth } from '../../api/firebase-config'
import { UserContext } from '../../api';
import { updatePassword, signInWithEmailAndPassword,signOut } from '@firebase/auth'
import AWS from 'aws-sdk';
import { useHistory } from 'react-router-dom'
import Metadata from '../../Components/Metadata'
const ShortUniqueId = require('short-unique-id')

window.Buffer = window.Buffer || require("buffer").Buffer;

const Settings = ({setActiveIndex}) => {
    let history = useHistory()
    const signOutUser = () => {
      signOut(auth)
      .then(()=>{
          localStorage.clear()
          setIsAuth(false)

          history.replace('/auth')
      })
  }
    const { setActivePage,setCurrentStep,setUserDisplayName ,currentSettingsMode,
      setCurrentSettingsMode,setIsPasswordLoginSettings,setUserAvatarLink,userDisplayName,userAvatarLink,setIsAuth } = useContext(UserContext)
    const toast = useToast()
    const avatarRef = React.useRef()
    const [user,setUser] = React.useState(null)

    const [currentPassword,setCurrentPassword] = React.useState(null)
    const [newPassword,setNewPassword] = React.useState(null)
    const [editPasswordErrorMssg,setEditPasswordErrorMssg] = React.useState('')
    const [userData,setUserData] = React.useState({
      firstName:null,
      lastName:null,
      bio:null
    })
      const[firstName,setFirstName] = React.useState('')
      const[lastName,setLastName] = React.useState('')
      const[bio,setBio ] = React.useState('')
      const[email,setEmail ] = React.useState('')
    console.log(userData)
    const [c,setC] = React.useState('')
    console.log(c)
    const [mediaUploaded,setMediaUploaded] = React.useState(false)
    const [isUploadingAvatar, setIsUploadingAvatar] = React.useState(false)
    const [isFetching, setIsFetching] = React.useState(true)
    const [mediaErrMssg,setMediaErrMssg] = React.useState('')
    const [localImageSrc,setLocalImageSrc] = React.useState(null)
    const [progress,setProgress] = React.useState(0)
    const [campaignImage,setCampaignImage] = React.useState('')
    onAuthStateChanged(auth,currentUser=>{
      setUser(currentUser)
    })
    const showToast = (title,description,status,duration) => {
        toast({
            title,
            description,
            status,
            duration,
            isClosable: true,
        })
    }
    let campaignNameError = null
    function ConfirmCancel() {
        const { isOpen, onOpen, onClose } = useDisclosure()
        const cancelRef = React.useRef()
      
        return (
          <>
            <Button
            flex={1}
            colorScheme='purple'
            size={'lg'}
            variant='outline'
            onClick={onOpen}
            >Cancel</Button>
      
            <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={cancelRef}
              onClose={onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    Cancel Creating a New Campaign
                  </AlertDialogHeader>
      
                  <AlertDialogBody>
                    Are you sure? All details you've entered will be deleted.
                  </AlertDialogBody>
      
                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button colorScheme='red' onClick={()=>setActiveIndex(1)} ml={3}>
                      Yes, I'm sure
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </>
        )
      }
      // After Submissions
       
        function createObjectURL(object) {
            return (window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object);
        }

        
        const checkIsPasswordLogin = (firUserData) => {
          let isPasswordLogin = false
          for(let i=0;i<firUserData.length;i++) {
            if(firUserData[i].providerId == 'password') isPasswordLogin = true
          }
          return isPasswordLogin;
        }
        const fetchUserData = () => {
          // console.log(user.accessToken)
          axios.get(`${BASEURL}/sellers`,{
            params:{
              accessToken:user.accessToken
            }
          })
          .then(res=>{
            const { first_name, last_name,email,bio} = res.data
            setFirstName(first_name)
            setLastName(last_name)
            setBio(bio)
            setEmail(email)
            console.log(userData)
          }).then(err=>{
            console.log(err)
          }).finally(()=>{
            setIsFetching(false)
          })
        }
        React.useEffect(()=>{
          
          if(user) {
            setIsPasswordLoginSettings(checkIsPasswordLogin(user.providerData))
            fetchUserData()
          }
        },[user])

        const updateData = () => {
          axios.post(`${BASEURL}/sellers/update_personal_data`,{
            accessToken:user.accessToken,
            firstName,
            lastName,
            bio
          }).then(res=>{
            console.log(res)
            if(res.data.wasUpdated == true ) {
              axios.get(`${BASEURL}/sellers`,{
                params:{
                  accessToken:user.accessToken
                }
              })
              .then(res=>{
                showToast('Success','Account details updated successfully','success',2500)
                const { first_name,last_name } = res.data
                let fullName = `${first_name} ${last_name}`
                localStorage.setItem('userDisplayName',fullName)
                setUserDisplayName(fullName)
              })
            }
          }).catch(err=>{
            console.log(err)
          })
        }
        const uploadAvatar = (file) => {
          console.log('gf')
          setIsUploadingAvatar(true)
          setMediaErrMssg('')
          setMediaUploaded(false)
          setProgress(0)
          setLocalImageSrc(null)
          let fileName = file.name
          
          if(file.size > 1 * 1024 * 1024){
              showToast('Upload Error','The file size must be less than or equal to 10MB','warning',3000)
              return
          }
          setLocalImageSrc(createObjectURL(file))
          // console.log('changed image',file)
      
          const s3 = new AWS.S3({
              correctClockSkew: true,
              endpoint: 'https://s3.wasabisys.com', //use appropriate endpoint as per region of the bucket
              region: 'us-west-1',
              accessKeyId: 'QR6BXTUOMNVE2PAL5VKN',
              secretAccessKey:'HHjo3G0ZizdzW0dnT1C73n0vA4sWIKVqLOvSINDq',
              logger: console,
              signatureVersion:'v4',
            });
      
        const uid = new ShortUniqueId()
        let newUID = uid()
        const uploadRequest = new AWS.S3.ManagedUpload({
          params: { Bucket: 'sellersavatars', Key: `${newUID}-${fileName}`, Body: file },
          service: s3
        });
      
        uploadRequest.on('httpUploadProgress', function(event) {
          const progressPercentage = Math.floor(event.loaded * 100 / event.total);
          console.log('Upload progress ' + progressPercentage);
          setProgress(progressPercentage)
        });
      
      //   console.log('Configured and sending');
      
        uploadRequest.send(function(err) {
          if (err) {
            console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));
          } else {
              setMediaUploaded(true)
              let url = `https://s3.us-east-1.wasabisys.com/${'sellersavatars'}/${newUID}-${fileName}`
              axios.post(`${BASEURL}/sellers/editAvatar`,{
                accessToken:user.accessToken,
                avatarLink:url
              }).then(res=>{
                console.log(res)
              setUserAvatarLink(url)
              localStorage.setItem('userAvatarLink',url)
              })
              // setCampaignImage(url)
          }

          setIsUploadingAvatar(false)
        });
        
      }
      const deleteAvatar = () => {
       if(userAvatarLink == null) {
        showToast('Warning','No Avatar found to be deleted','warning',2500)
       }else {
        axios.post(`${BASEURL}/sellers/deleteAvatar`,{
          accessToken:user.accessToken,
        }).then(res=>{
          showToast('Success','Avatar deleted successfully','success',2500)
          setUserAvatarLink(null)
          localStorage.setItem('userAvatarLink',null)
        })
       }
      }
      const updateUserPassword = async () => {
        try {
          await signInWithEmailAndPassword(auth, auth.currentUser.email, currentPassword).then(()=>{
             updatePassword(auth.currentUser,newPassword)
             .then(()=>{
              showToast('Success','Your password changed successfully, Please Sign in Again!','success',3000)
              signOutUser()
            })
         })
        } catch (error) {
          setEditPasswordErrorMssg('The Current Password is incorrect')
        }
         }
        React.useEffect(()=>{
          setCurrentSettingsMode('PERSONAL')
            setActivePage('SETTINGS')
            // Ready To Upload
        },[])
       
       React.useEffect(()=>{
        if(!isFetching) {
          avatarRef?.current?.addEventListener('change',(e) => {
            let file = e.target.files[0]
            console.log(file)
            uploadAvatar(file)
        })
         }
       },[isFetching])
        
    return (
       <>
       <Metadata title='Settings'/>
      <Flex>
      {!isFetching && <Box
      w={{base:'100%',lg:'70%'}}
      px={10}
       >
        {currentSettingsMode == 'PERSONAL' && <Box
          py={6}
          px={{base:1,md:10}}
          mt={4}
        >
             <Text as="h1" fontSize={'2xl'} my={3}>Edit Account Information</Text>

                <Flex align='center' justify={'center'} gap={7}>
                {isUploadingAvatar && <CircularProgress value={progress} color='brand.buttonHover'>
                  <CircularProgressLabel>{progress}%</CircularProgressLabel>
                </CircularProgress>}
                {!isUploadingAvatar && <Avatar name={userDisplayName} src={userAvatarLink} />}
                
                <Box>
                  <label htmlFor="avatarInput"
                  style={{
                    backgroundColor: 'rgb(246, 173, 85)',
                    borderRadius: '0.375rem',
                    padding: '8px 12px',
                    color: 'white',
                    fontSize: '0.875rem',
                    fontWeight: 600,
                    cursor:'pointer'
                  }}
                  >Upload</label>
                <Input type='file' id='avatarInput' display='none' ref={avatarRef}/>
                </Box>
                <Button size='sm' variant={'outline'} onClick={()=>deleteAvatar()}>Delete</Button>
                </Flex>
                <FormControl my={6} display='flex' alignItems='center' justifyContent={'space-between'}>
                    <FormLabel htmlFor='firstName'>
                    <Text color='#2D3748' fontSize={20} fontWeight={500}>First Name</Text>
                    </FormLabel>
                    <Input id='firstName' type='text' 
                    w={'70%'}
                    value={firstName}
                    onChange={e=>setFirstName(e.target.value)}
                    variant='outline'
                    borderColor={'brand.button'}
                    _focus={{
                        borderColor:'brand.buttonHover',
                        borderBottomWidth:3
                    }}
                    />
                    {/* <FormHelperText pos="absolute" bottom={-3} color='red.300'>test</FormHelperText> */}
                    <FormHelperText pos="absolute" bottom={-5} color='red.300'>{campaignNameError}</FormHelperText>
                </FormControl>
                <FormControl my={6} display='flex' alignItems='center' justifyContent={'space-between'}>
                    <FormLabel htmlFor='firstName'>
                    <Text color='#2D3748' fontSize={20} fontWeight={500}>Last Name</Text>
                    </FormLabel>
                    <Input id='firstName' type='text' 
                    w={'70%'}
                    value={lastName}
                    onChange={e=>setLastName(e.target.value)}
                    variant='outline'
                    borderColor={'brand.button'}
                    _focus={{
                        borderColor:'brand.buttonHover',
                        borderBottomWidth:3
                    }}
                    />
                    {/* <FormHelperText pos="absolute" bottom={-3} color='red.300'>test</FormHelperText> */}
                    <FormHelperText pos="absolute" bottom={-5} color='red.300'>{campaignNameError}</FormHelperText>
                </FormControl>
                <FormControl my={6} display='flex' alignItems='center' justifyContent={'space-between'}>
                    <FormLabel htmlFor='firstName'>
                    <Text color='#2D3748' fontSize={20} fontWeight={500}>Your Bio</Text>
                    </FormLabel>
                    <Textarea 
                    w={'70%'}
                    value={bio}
                placeholder='Short description for your product ....'
                onChange={e=>setBio(e.target.value)}
                    onFocus={()=>null}
                maxH={150}
                _focus={{
                    borderColor:'brand.buttonHover',
                    borderBottomWidth:3
                }}
                borderColor={'brand.button'}
                />
                    {/* <FormHelperText pos="absolute" bottom={-3} color='red.300'>test</FormHelperText> */}
                    <FormHelperText pos="absolute" bottom={-5} color='red.300'>{campaignNameError}</FormHelperText>
                </FormControl>
                <FormControl my={6} display='flex' alignItems='center' justifyContent={'space-between'}>
                    <FormLabel htmlFor='firstName'>
                    <Text color='#2D3748' fontSize={20} fontWeight={500}>E-mail</Text>
                    </FormLabel>
                    <Input id='email' type='text' 
                    readOnly
                    w={'70%'}
                    value={email}
                    onChange={e=>setEmail(e.target.value)}
                    onFocus={()=>null}
                    variant='outline'
                    borderColor={'brand.button'}
                    _focus={{
                        borderColor:'brand.buttonHover',
                        borderBottomWidth:3
                    }}
                    />
                    {/* <FormHelperText pos="absolute" bottom={-3} color='red.300'>test</FormHelperText> */}
                    <FormHelperText pos="absolute" bottom={-5} color='red.300'>{campaignNameError}</FormHelperText>
                </FormControl>
                {/* <FormControl my={6} display='flex' alignItems='center' justifyContent={'space-between'}>
                    <FormLabel htmlFor='firstName'>
                    <Text color='#2D3748' fontSize={20} fontWeight={500}>Location</Text>
                    </FormLabel>
                    <Input id='firstName' type='text' 
                    w={'70%'}
                    onChange={e=>null}
                    onFocus={()=>null}
                    variant='outline'
                    borderColor={'brand.button'}
                    _focus={{
                        borderColor:'brand.buttonHover',
                        borderBottomWidth:3
                    }}
                    />
                    <FormHelperText pos="absolute" bottom={-5} color='red.300'>{campaignNameError}</FormHelperText>
                </FormControl> */}
                  <Button
                  bgColor={'brand.button'}
                  color='#fff'
                  variant='solid'
                  _hover={{
                      bgColor:'brand.buttonHover'
                  }} 
                  onClick={()=>updateData()}
                  >
                    Save
                  </Button>
        </Box>}
            {currentSettingsMode == 'PASSWORD' && <Fade in={currentSettingsMode == 'PASSWORD' && true}>
        <Box
          py={6}
          px={10}
          my={10}
          rounded='md'
        >
           <Text as="h1" fontSize={'2xl'} my={3}>Edit Password</Text>
            {/* submissions form details */}
            <FormControl my={6} display='flex' alignItems='center' justifyContent={'space-between'}>
                    <FormLabel htmlFor='firstName'>
                    <Text color='#2D3748' fontSize={18}  fontWeight={500}>Current Password</Text>
                    </FormLabel>
                    <Input id='oldPassword' type='password' 
                    w={'60%'}
                    onChange={e=>setCurrentPassword(e.target.value)}
                    onFocus={()=>setEditPasswordErrorMssg('')}
                    variant='outline'
                    borderColor={'brand.button'}
                    _focus={{
                        borderColor:'brand.buttonHover',
                        borderBottomWidth:3
                    }}

                    />
                    {/* <FormHelperText pos="absolute" bottom={-3} color='red.300'>test</FormHelperText> */}
                    {/* <FormHelperText pos="absolute" bottom={-5} color='red.300'>{campaignNameError}</FormHelperText> */}
                </FormControl>
        
            <FormControl my={6} display='flex' alignItems='center' justifyContent={'space-between'}>
                    <FormLabel htmlFor='firstName'>
                    <Text color='#2D3748' fontSize={18}  fontWeight={500}>New Password</Text>
                    </FormLabel>
                    <Input id='newPassword' type='password' 
                    w={'60%'}
                    // value={campaignDetails.campaignName}
                    onChange={e=>setNewPassword(e.target.value)}
                    onFocus={()=>setEditPasswordErrorMssg('')}
                    variant='outline'
                    borderColor={'brand.button'}
                    _focus={{
                        borderColor:'brand.buttonHover',
                        borderBottomWidth:3
                    }}
                    />
                    {/* <FormHelperText pos="absolute" bottom={-3} color='red.300'>test</FormHelperText> */}
                    <FormHelperText pos="absolute" bottom={-5} color='red.300'>{editPasswordErrorMssg}</FormHelperText>
                </FormControl>
                <Button
                  bgColor={'brand.button'}
                  color='#fff'
                  variant='solid'
                  _hover={{
                      bgColor:'brand.buttonHover'
                  }} 
                  onClick={()=>updateUserPassword()}
                  >
                    Save
                  </Button>
        </Box>
      </Fade>}
       </Box>}
      </Flex>
       </>
    );
}

export default Settings;