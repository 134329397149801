import React from 'react'
import {
    Box,
    Text,
    Image,
    Button,
    Container,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Spinner,
    Textarea,
    Center,
    Link,
    FormHelperText,CircularProgress, CircularProgressLabel,
    useToast,IconButton, Select
} from '@chakra-ui/react'
import Countries from './countries.json'
import {  useParams } from 'react-router-dom';
import { FiCheck, FiThumbsUp, FiThumbsDown,FiXCircle } from 'react-icons/fi'
import axios from 'axios'
import { useState,useEffect } from 'react';
import { BASEURL } from '../../api';
import Metadata from './../../Components/Metadata'
import AWS from 'aws-sdk';
import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/bootstrap.css";
const ShortUniqueId = require('short-unique-id')

window.Buffer = window.Buffer || require("buffer").Buffer; 
const Campaign = () => {
    const fileRef = React.useRef()
    const toast = useToast()
    const [progress,setProgress] = React.useState(0)
    const [clientMediaUrl,setClientMediaUrl] = React.useState('')
    const [mediaUploaded,setMediaUploaded] = React.useState(false)
    const [isUplaodingSubmissionImage,setIsUplaodingSubmissionImage] = React.useState(false)
    const { campaignId } = useParams()
    const [campaignData,setCampaignData] = useState([]) 
    const [isCampaignFetched,setIsCampaignFetched] = useState(false)
    const [reviewState,setReviewState] = useState('')
    const [formVisible,setFormVisible] = useState(false)
    const [thankYouVisible,setThankYouVisible] = useState(false)
    const [country,setCountry] = React.useState('United States')
    const showToast = (title,description,status,duration) => {
        toast({
            title,
            description,
            status,
            duration,
            isClosable: true,
        })
    }
    const [buyerData,setBuyerData] = useState({
        name:'',
        email:'',
        orderID:'',
        phoneNumber:'',
        whatsappNumber:'',
        modelNumber:'',
        productID:'',
        shipmentNumber:'',
        media:'',
        customMessage:'',
        negativeReview:''
    })
    const handleSatisfied = () => setReviewState('Satisfied')
    const handleUnSatisfied = () => setReviewState('Unsatisfied')

    const handleFormChanges = (data) => {
        setBuyerData({...buyerData,...data})
    }

    const [nameErrMssg,setNameErrMssg] = React.useState('')
    const [emailErrMssg,setEmailErrMssg] = React.useState('')
    const [mediaErrMssg,setMediaErrMssg] = React.useState('')
    const [orderIdErrMssg,setOrderIdErrMssg] = React.useState('')
    
    const isValidEmail = (email) => {
        let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}')
        return regex.test(email)
    }
    const validateAndSubmit = () => {
        let Errmsg = 'This field cannot be empty!'
        let isDirty = false
        if(campaignData['campaign_type'] == "WARRANTY") {
            if(buyerData.name.length == 0) {setNameErrMssg(Errmsg);isDirty = true}
            if(buyerData.email.length == 0 || !isValidEmail(buyerData.email)){ setEmailErrMssg(Errmsg);isDirty = true}
            if(buyerData.orderID.length == 0) {setOrderIdErrMssg(Errmsg);isDirty = true}
        }
        if(campaignData['campaign_type'] == "REVIEW") {
            if(buyerData.name.length == 0) {setNameErrMssg(Errmsg);isDirty = true}
            if(buyerData.email.length == 0 || !isValidEmail(buyerData.email)){ setEmailErrMssg(Errmsg);isDirty = true}
            if(buyerData.orderID.length == 0) {setOrderIdErrMssg(Errmsg);isDirty = true}
            // if(buyerData.media.length == 0) {setMediaErrMssg(Errmsg);isDirty = true}
            // another test for image
            
        }  if(campaignData['campaign_type'] == "REGISTRATION") {
            if(buyerData.name.length == 0) {setNameErrMssg(Errmsg);isDirty = true}
            if(buyerData.email.length == 0 || !isValidEmail(buyerData.email)) {setEmailErrMssg(Errmsg);isDirty = true}
            // another test for image
            
        }
        if(!isDirty) submitForm()
    }

    const sendAutomatedMail = async () => { 
        try {
            let buyerInfo =[ {
                'email' : buyerData.email,
                'name' : buyerData.name
            }]
              // console.log('submissionsWithEmailsNotSent',submissionsWithEmailsNotSent)
              axios.post(`${BASEURL}/emails/send-automated-email`, {
                uid : campaignData['seller_id'],
                submissions: buyerInfo
              }).then(({data}) => {
          
                let customSlug = data.customSlug
                let allowedEmails = data.emails
                let useCustomDomain = data.useCustomDomain
                let userPlan = data.userPlan
                let senderEmail = ''
                let hostName = window.location.hostname
        
                if(userPlan == 'free') {
                  if(customSlug.length == 0) {
                    senderEmail = `${'contact'}@${hostName}`
                  }else {
                    senderEmail = `${customSlug}@${hostName}`
                  }
                  
                }else if(userPlan == 'paid'){
                  if(customSlug.length == 0) {
                    if(useCustomDomain == '1') {
                      senderEmail = `${'contact'}@${data.customDomain}`
                    }else{
                      senderEmail = `${'contact'}@${hostName}`
                    }
                  }else{
                    if(useCustomDomain == '1') {
                      senderEmail = `${customSlug}@${data.customDomain}`
                    }else {
                      senderEmail = `${customSlug}@${hostName}`
                    }
                  }
                
                }
        
                let emailInfo = {
                  allowedEmails,
                  senderEmail,
                  emailTemplate : campaignData['template_email'],
                  emailSubject : campaignData['subject'],
                  senderFullName : campaignData['campaign_name']
                }
                // console.log(emailInfo)
                if(data.canSend) {
                  axios.post(`${BASEURL}/sendToWorker`,{
                    emailInfo
                  }).then((res)=>{
                    //
                  }).catch(err=>console.log(err))

                }
              })

        } catch (error) {
            console.log(error)
        }
    }
    const submitForm = () => {
        axios.post(`${BASEURL}/submissions/newSubmission`,{
            campaignId,
            reviewState,
            sellerId:campaignData.seller_id,
            submissionType:campaignData.campaign_type,
            ...buyerData,
            country // user country
        }).then((result)=>{
            if(result.status == 200 && result.data.created) {
            if(campaignData['send_automated_email'] == "1" ){
                sendAutomatedMail()
            }
            setThankYouVisible(true)
            }
        }).catch(err=>console.log('err submiting',err))
    }

    const hanleFormFocus = () => {
        setNameErrMssg('')
        setEmailErrMssg('')
        setOrderIdErrMssg('')
        setMediaErrMssg('')
    }

    const renderButtonText = (type) => {
        switch(type) {
            case 'REVIEW':
                return 'Review Product'
            break
            case 'REGISTRATION':
                return 'Register Product'
            break
            case 'WARRANTY':
                return 'Activate Warranty'
            break
            default:
                return ''
        }
    }

    const incrementScan = () => {
        axios.put(`${BASEURL}/campaigns/increment-scan-number`,{
            campaignId
        })
    }

    const fetchCampaign = () => {
        axios.get(`${BASEURL}/campaigns/${campaignId}`)
        .then(res=>{
            console.log(res.data)
            setCampaignData(res.data)
            setIsCampaignFetched(true)

            incrementScan()
        }).catch(err=>console.log(err))
    }

    function createObjectURL(object) {
        return (window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object);
    }
    const [localImageSrc,setLocalImageSrc] = React.useState(null)

    const deleteCurrentSubmissionImage = () => {
        setMediaUploaded(false)
        setProgress(0)
        setLocalImageSrc(null)
        setIsUplaodingSubmissionImage(false)
    }

const handleImageChange = (e) => {
    // reset conditions 
    setMediaErrMssg('')
    setMediaUploaded(false)
    setIsUplaodingSubmissionImage(true)
    setProgress(0)
    setLocalImageSrc(null)
    let file = e.target.files[0]
    let fileName = file.name
    
    if(file.size > 3145728){
        showToast('Upload Error','The file size must be less than or equal to 3MB','warning',3000)
        setIsUplaodingSubmissionImage(false)
        return
    }
    setLocalImageSrc(createObjectURL(file))


    const s3 = new AWS.S3({
        correctClockSkew: true,
        endpoint: 'https://s3.wasabisys.com', //use appropriate endpoint as per region of the bucket
        region: 'us-west-1',
        accessKeyId: 'QR6BXTUOMNVE2PAL5VKN',
        secretAccessKey:'HHjo3G0ZizdzW0dnT1C73n0vA4sWIKVqLOvSINDq',
        logger: console,
        signatureVersion:'v4',
      });

    const uid = new ShortUniqueId()
  let newUID = uid()
  const uploadRequest = new AWS.S3.ManagedUpload({
    params: { Bucket: 'sellersmedia', Key: `${newUID}-${fileName}`, Body: file },
    service: s3
  });

  uploadRequest.on('httpUploadProgress', function(event) {
    const progressPercentage = Math.floor(event.loaded * 100 / event.total);
    console.log('Upload progress ' + progressPercentage);
    setProgress(progressPercentage)
  });


  uploadRequest.send(function(err) {
    if (err) {
      console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));
    } else {
        setMediaUploaded(true)
        let url = `https://s3.us-east-1.wasabisys.com/${'sellersmedia'}/${newUID}-${fileName}`
        setBuyerData({...buyerData,media:url})
        console.log(url)
        setIsUplaodingSubmissionImage(false)
    }
  });


}

    useEffect(()=>{
        fetchCampaign()     
    },[])

    return (
        <>
            <Metadata title={'New Submission'}/>
            <Box
                pos="absolute"
                left={0}
                right={0}
                bgColor="#fff"
                minH={'100vh'}
                px={2}
            >
               {(isCampaignFetched == false) ? <Box><Spinner /></Box> : <>
               <Box
                    display="flex" flexDirection='column' alignItems={'center'}
                    // minH={'100vh'}
                    // bgColor='red.300'
                    >
                    <Box w={{ md: 650 }}
                    >
                        <Text
                            fontSize={33}
                            fontWeight='semibold'
                            mb={4}
                        >{campaignData['campaign_name']}</Text>
                        <Image
                            src={campaignData['campaign_image']}
                        />
                    </Box>

                  {campaignData['campaign_description'] && <Box 
                    mt={4}
                    px={2}
                    py={4}
                    borderWidth={1.5}
                    // bgColor='purple.100'
                    borderRadius={10}
                    w={{base:'100%',md:'45%'}}
                    borderColor={'brand.button'}
                    >
                    <Text as="h2" fontSize='2xl'>Description</Text>
                    <Text as="p" fontSize='lg'>{campaignData['campaign_description']}</Text>
                </Box>}
                </Box>
                {!formVisible &&  <Box        
                    display="flex"
                    justifyContent={'center'}
                    alignItems='center'
                    my={4}
                >
                    <Box
                    w={{base:'100%',md:'45%'}}
                    //  bgColor="purple.100"
                    //  py={50}
                     display='flex'
                     justifyContent={'center'}
                    //  boxShadow='md'
                    >
                            {campaignData.length == 0 ? <>
                            <Text fontSize='2xl'>No Campaigns Found!</Text>
                            </> : <Button size='lg' bgColor='brand.button'
                             _hover={{
                                bgColor:'brand.buttonHover'
                            }}
                            color='white' variant="solid"
                    onClick={()=>setFormVisible(true)}
                    >
                        <Text>{renderButtonText(campaignData['campaign_type'])}</Text>
                    </Button>}
                          
                    </Box>
                  
                </Box>} 
               {(formVisible && !thankYouVisible) &&  <Box flex={1}
                    my={4}
                    textAlign={"center"}
                    display='flex'
                    justifyContent={'center'}
                >
                    
                    <Box flex={1} 
                    textAlign={"center"}
                    display='flex'
                    justifyContent={'center'}
                    >
                        <Flex direction={'column'}
                        w={{base:'100%',md:'45%'}}
                        // bgColor='purple.100'
                        // py={3}
                        gap={5}
                        px={{base:3,md:10}}
                        borderRadius={10}
                        // boxShadow='lg'
                        >
                        <Box w={{base:'80%',md:'60%'}} textAlign='left' mt={2} mb={4} 
                        >
                        <Text as="span"
                        fontSize={22}
                        fontWeight='semibold'
                        >
                      {campaignData.campaign_heading}
                        </Text>
                        </Box>
                        <Box> 
                        <FormControl>
                        <FormLabel htmlFor='name'>
                    <Text color='brand.fieldName' fontSize={18} fontWeight={500}>Name</Text>
                    </FormLabel>
                        <Input id='buyerName' type='text' 
                        onChange={(e)=>handleFormChanges({name:e.target.value})}
                        onFocus={()=>hanleFormFocus()}
                        variant='flushed'
                        borderColor={'brand.button'}
                        _focus={{
                            borderColor:'brand.buttonHover',
                            borderBottomWidth:3
                        }}
                        />
                        <FormHelperText textAlign={'left'} color="red.300">{nameErrMssg}</FormHelperText>
                        </FormControl>
                        <FormControl>
                        <FormLabel htmlFor='name'>
                    <Text color='brand.fieldName' fontSize={18} fontWeight={500}>Email address</Text>
                    </FormLabel>
                        <Input id='buyerEmail' type='text' 
                        onChange={(e)=>handleFormChanges({email:e.target.value})}
                        onFocus={()=>hanleFormFocus()}
                          variant='flushed'
                        borderColor={'brand.button'}
                        _focus={{
                            borderColor:'brand.buttonHover',
                            borderBottomWidth:3
                        }}
                        />
                         <FormHelperText textAlign={'left'} color="red.300">{emailErrMssg}</FormHelperText>
                        </FormControl>
                        {(campaignData.phone_number_is_required == "1") && <FormControl>
                        <FormLabel htmlFor='name'>
                    <Text color='brand.fieldName' fontSize={18} fontWeight={500}>Phone Number</Text>
                    </FormLabel>
                        {/* <Input id='buyerPhoneNumber' type='text' 
                        onChange={(e)=>handleFormChanges({phoneNumber:e.target.value})}
                        onFocus={()=>hanleFormFocus()}
                          variant='flushed'
                        borderColor={'brand.button'}
                        _focus={{
                            borderColor:'brand.buttonHover',
                            borderBottomWidth:3
                        }}
                        /> */}
                         <PhoneInput
                country={"us"}
                className="marginBottom"
                inputStyle={{
                    width: "100%",
                    height: "45px",
                    borderTopWidth:0,
                    borderLeftWidth:0,
                    borderRightWidth:0,
                    borderRadius:0,
                    borderColor:'#F6AD55',
                  }}
                  buttonStyle={{
                    borderColor:'#F6AD55',
                  }}
                  onChange={phone=>handleFormChanges({phoneNumber:phone})}
              />
                        </FormControl>}
                        {(campaignData.whatsapp_number_is_required == "1") &&  <FormControl>
                        <FormLabel htmlFor='name'>
                    <Text color='brand.fieldName' fontSize={18} fontWeight={500}>Whatsapp Number</Text>
                    </FormLabel>
                        <Input id='buyerWhatsappNumber' type='text'
                        onChange={(e)=>handleFormChanges({whatsappNumber:e.target.value})}
                        onFocus={()=>hanleFormFocus()}
                          variant='flushed'
                        borderColor={'brand.button'}
                        _focus={{
                            borderColor:'brand.buttonHover',
                            borderBottomWidth:3
                        }}
                        />
                        </FormControl>}
                        <FormControl>
                       <FormLabel htmlFor='name'>
                    <Text color='brand.fieldName' fontSize={18} fontWeight={500}>Country</Text>
                    </FormLabel>
                    <Select variant={'flushed'} size={'md'} onChange={e=>setCountry(e.target.value)} 
                defaultValue={'United States'}
                borderColor={'brand.button'}
                _focus={{
                    borderColor:'brand.buttonHover',
                    borderBottomWidth:3
                }}
                >
                    {Countries.map((elm)=>{
                   return <option value={elm}>{elm}</option>
                     })}
                        </Select>
                        {/* <FormHelperText textAlign={'left'} color="red.300">{orderIdErrMssg}</FormHelperText> */}
                        </FormControl>
                       {(campaignData.order_id_is_required == "1") && <FormControl>
                       <FormLabel htmlFor='name'>
                    <Text color='brand.fieldName' fontSize={18} fontWeight={500}>Order ID</Text>
                    </FormLabel>
                        <Input id='buyerOrderId' type='text'
                        onChange={(e)=>handleFormChanges({orderID:e.target.value})}
                        onFocus={()=>hanleFormFocus()}
                          variant='flushed'
                        borderColor={'brand.button'}
                        _focus={{
                            borderColor:'brand.buttonHover',
                            borderBottomWidth:3
                        }}
                        />
                        <FormHelperText textAlign={'left'} color="red.300">{orderIdErrMssg}</FormHelperText>
                        </FormControl>}
                        {(campaignData.campaign_type == "REVIEW") && <Box>
                        <FormLabel htmlFor='name'>
                    <Text textAlign='left' color='brand.fieldName' fontSize={18} fontWeight={500}>What do you think ?</Text>
                    </FormLabel>
                       <Box
                        display='flex'
                        justifyContent={'flex-start'}
                        py={4}
                        gap={4}
                        >
                            <FiThumbsUp
                            style={{
                                backgroundColor:reviewState == 'Satisfied' ? '#FBD38D' : 'transparent',
                                borderWidth:reviewState != 'Satisfied' ? 2 : 0,
                                borderColor:reviewState == 'Satisfied' ? '#FBD38D' : '#9C4221',
                                padding:4,
                                borderRadius:50
                            }}
                            size={35} onClick={()=>handleSatisfied()}
                            color={reviewState == 'Satisfied' ? '#fff' : '#9C4221'}
                            className='thumb_down_up'
                            />
                            <FiThumbsDown 
                             style={{
                                backgroundColor:reviewState == 'Unsatisfied' ? '#FBD38D' : 'transparent',
                                borderWidth:reviewState != 'Unsatisfied' ? 2 : 0,
                                borderColor:reviewState == 'Unsatisfied' ? '#FBD38D' : '#9C4221',
                                padding:4,
                                borderRadius:50
                            }}
                            size={35}
                            onClick={()=>handleUnSatisfied()}
                            color={reviewState == 'Unsatisfied' ? '#fff' : '#9C4221'}
                            className='thumb_down_up'
                            />
                        </Box>
                        {/* <Text textAlign={'center'}>{reviewState}</Text> */}
                       </Box>}
                         {(campaignData.campaign_type == 'REVIEW' && reviewState == 'Unsatisfied') && <FormControl>
                         <FormLabel htmlFor='name'>
                    <Text color='brand.fieldName' fontSize={18} fontWeight={500}>Write a review</Text>
                    </FormLabel>
                        <Textarea 
                        id="negative_review"
                        placeholder='Tell us what went wrong and how we can improve'
                        onChange={(e)=>handleFormChanges({negativeReview:e.target.value})}
                        onFocus={()=>hanleFormFocus()}
                          variant='flushed'
                        borderColor={'brand.button'}
                        _focus={{
                            borderColor:'brand.buttonHover',
                            borderBottomWidth:3
                        }}
                        />
                        </FormControl>}
                        {(campaignData.product_id_is_required == "1") && <FormControl>
                        <FormLabel htmlFor='name'>
                    <Text color='brand.fieldName' fontSize={18} fontWeight={500}>Product ID</Text>
                    </FormLabel>
                        <Input id='buyerProductId' type='text' 
                        onChange={(e)=>handleFormChanges({productID:e.target.value})}
                        onFocus={()=>hanleFormFocus()}
                          variant='flushed'
                        borderColor={'brand.button'}
                        _focus={{
                            borderColor:'brand.buttonHover',
                            borderBottomWidth:3
                        }}
                        />
                        </FormControl>}
                        {(campaignData.model_number_is_required == "1") && <FormControl>
                        <FormLabel htmlFor='name'>
                    <Text color='brand.fieldName' fontSize={18} fontWeight={500}>Model Number</Text>
                    </FormLabel>
                        <Input id='buyerModelNumber' type='text'
                        onChange={(e)=>handleFormChanges({modelNumber:e.target.value})}
                        onFocus={()=>hanleFormFocus()}
                          variant='flushed'
                        borderColor={'brand.button'}
                        _focus={{
                            borderColor:'brand.buttonHover',
                            borderBottomWidth:3
                        }}
                        />
                        </FormControl>}
                        {(campaignData.shipment_number_is_required == "1") && <FormControl>
                        <FormLabel htmlFor='name'>
                    <Text color='brand.fieldName' fontSize={18} fontWeight={500}>Shipment Number</Text>
                    </FormLabel>
                        <Input id='buyerShipmentNumber' type='text'
                        onChange={(e)=>handleFormChanges({shipmentNumber:e.target.value})}
                        onFocus={()=>hanleFormFocus()}
                          variant='flushed'
                        borderColor={'brand.button'}
                        _focus={{
                            borderColor:'brand.buttonHover',
                            borderBottomWidth:3
                        }}
                        />
                        </FormControl>}
                        {(campaignData.media_is_required == "1") && <FormControl>
                        <FormLabel htmlFor='name'>
                    <Text color='brand.fieldName' fontSize={18} fontWeight={500}>Include a screenshot of the review</Text>
                    </FormLabel>
                        <Input id='buyerMedia' type='file' 
                        ref={fileRef} 
                        accept='image/*'
                        onChange={e=>handleImageChange(e)}
                        variant='flushed'
                        borderColor={'brand.button'}
                        _focus={{
                            borderColor:'brand.buttonHover',
                            borderBottomWidth:3
                        }}
                        />
                      {(isUplaodingSubmissionImage || mediaUploaded) &&  <Box
                        bgColor={'orange.100'}
                        w={'30%'}
                        pos='relative'
                        borderWidth={2}
                        mt={'23px'}
                        >
                        <IconButton
                        onClick={()=>deleteCurrentSubmissionImage()}
                        pos='absolute'
                        style={{
                            backgroundColor:'#F6AD55'
                        }}
                        right={-4}
                        top={-5}
                        zIndex={100}
                        variant='solid'
                        size='sm'
                        icon={<FiXCircle size={23} color='#fff'/>}
                        />
                        {(mediaUploaded && progress == 100) && <Box
                        pos={'absolute'}
                        top='50%'
                        left='50%'
                        bgColor='#fff'
                        style={{transform:'translate(-50%,-50%)'}}
                        borderRadius='full'
                        p={1}
                        boxShadow='md'
                        zIndex={1000}
                        >
                        <FiCheck 
                        size={44}
                        color='orange'
                        />  
                            </Box>}
                       
                        {progress < 100 && <CircularProgress 
                        value={progress} color='orange.400' 
                        pos={'absolute'}
                        top='50%'
                        left='50%'
                        style={{transform:'translate(-50%,-50%)'}}
                        zIndex={1000}
                        >
                            <CircularProgressLabel fontWeight={'semibold'}>{progress}%</CircularProgressLabel>
                        </CircularProgress>}
                       {(localImageSrc != null) && <Image 
                        src={localImageSrc}
                        filter='auto'
                        blur={progress == 100 ? '0px' : '1.5px'}
                        />}
                        </Box>}
                        <FormHelperText textAlign={'left'} color="red.300">{mediaErrMssg}</FormHelperText>
                    </FormControl>}
                        {(campaignData.custom_message_is_required == "1") && <FormControl>
                        <FormLabel htmlFor='name'>
                    <Text color='brand.fieldName' fontSize={18} fontWeight={500}>Custom Message</Text>
                    </FormLabel>
                        <Textarea id='buyerCustomMessage' 
                        onChange={(e)=>handleFormChanges({customMessage:e.target.value})}
                        onFocus={()=>hanleFormFocus()}
                        borderColor={'brand.button'}
                        _focus={{
                            borderColor:'brand.buttonHover',
                            borderBottomWidth:3
                        }}
                        />
                        </FormControl>}
                        <Box my={4}>
                            <Button w={'full'}
                             bgColor='brand.button'
                             _hover={{
                                bgColor:'brand.buttonHover'
                            }}
                            color='white' variant="solid"
                            onClick={()=>validateAndSubmit()}
                            >Submit</Button>
                            <Button w={'full'} colorScheme='orange' my={3}
                            variant='outline'
                            onClick={()=>setFormVisible(false)}
                            >Cancel</Button>
                        </Box>
                        </Box>
                        </Flex>
                    </Box>
                </Box>}
                {(thankYouVisible) && <Center>
               <Flex 
                        direction={'column'} 
                        alignItems='center'
                        justify='center'
                        // bgColor='purple.100'
                        w={{base:'100%',md:'45%'}}
                        maxW={'80%'}
                        wordBreak='break-word'
                        my={4}
                        minH={300}
                        maxH={300}
                        overflowY='hidden'
                        borderRadius={10}
                        textAlign="center"
                        boxShadow='lg'
                        >
                        <Box>
                        <Text as="span"><FiCheck size={60}/></Text>
                        </Box>
                        <Box>
                            <Text as="h1" fontSize={32} fontWeight='semibold'>{campaignData.thank_you_heading}</Text>
                            <Text as="h2" fontSize={22} fontWeight='semibold'>{campaignData.thank_you_sub_heading}</Text>
                        </Box>
                        <Box my={2}>
                            {(campaignData.thank_you_is_redirect_visible == "1" ) && <Button as={Link} href={campaignData.thank_you_redirect_url}  
                            bgColor='brand.button'
                           _hover={{
                                bgColor:'brand.buttonHover'
                            }}
                            color='white' variant="solid"
                            >
                                <Text>{campaignData.thank_you_redirect_text}</Text>
                            </Button>}
                        </Box>
                        </Flex>
               </Center>}
               </>}
            </Box>
            </>
    );
}

export default Campaign;