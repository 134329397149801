import React,{useContext} from 'react'
import { Box,Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Button,
    Flex,
    useToast,
    Spinner,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    IconButton,
    useDisclosure,
    HStack
} from '@chakra-ui/react'
import { BsThreeDotsVertical } from "react-icons/bs";

import { FiEdit2,FiDownload,FiCopy,FiBookOpen } from 'react-icons/fi'
import axios from 'axios'
import { QRCodeCanvas,QRCodeSVG } from 'qrcode.react';
import { BASEURL } from '../../../api/index'
import { useLocation,useHistory, Link } from 'react-router-dom'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { auth } from '../../../api/firebase-config';
import { onAuthStateChanged } from '@firebase/auth'
import { UserContext } from '../../../api';
import Metadata from '../../../Components/Metadata'

const CampaignsList = () => {

         const { setActivePage } = useContext(UserContext)
        
         const downloadQRInPNG = (id,name) => {
            const canvas = document.getElementById(id);
            const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = `${name}.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          };
          const downloadQRInSVG = (id,name) => {
            var svg = document.getElementById(id);
          
          var serializer = new XMLSerializer();
          var source = serializer.serializeToString(svg);
          
          if(!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)){
              source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
          }
          if(!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)){
              source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
          }
          
          source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
          
          var downloadLink = document.createElement("a");
          var url = "data:image/svg+xml;charset=utf-8,"+encodeURIComponent(source);
          downloadLink.href = url;
          downloadLink.download = `${name}.svg`;
            
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          };
        const toast = useToast()

        const history = useHistory()
        const [campaignsList,setCampaingsList] = React.useState([])
        const [totalAnalytics,setTotalAnalytics] = React.useState({})
        const [areCampaignsFetched,setAreCampaingsFetched] = React.useState(false)
        const [user,setUser] = React.useState(null)
        const [isFetching,setIsFetching] = React.useState(false)
        const [noMoreCampaigns,setNoMoreCampaigns] = React.useState(false)

        const showToast = (title,description,status,duration) => {
            toast({
                title,
                description,
                status,
                duration,
                isClosable: true,
            })
        }


        const DynamicTr = ({campaignData}) => {
            function QrMenu() {
                const { isOpen, onOpen, onClose } = useDisclosure()
                return (
                  <>
                      <MenuItem onClick={onOpen} icon={<FiDownload />}>Download QR Code</MenuItem>
                    {/* <Button onClick={onOpen}>Open Modal</Button> */}
              
                    <Modal isOpen={isOpen} onClose={onClose} isCentered>
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader>Download QR Code</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                        <Box display='flex' flexDirection={'column'} justifyContent='center' alignItems='center'>
                        <QRCodeCanvas id='qrCodeCanvas' fgColor='#C05621' bgColor='transparent' level='L' value={`${window.location.origin}/c/${campaignData['campaign_id']}`} 
              imageSettings={{
                width:'180px !important',
                height:'180px !important'
              }}
              style={{
                display:'none',
                width:180,
                height:180
              }}
              />
              <QRCodeSVG id='qrCodeSvg' fgColor='#C05621' bgColor='transparent' level='L' value={`${window.location.origin}/c/${campaignData['campaign_id']}`} 
              imageSettings={{
                width:'180px',
                height:'180px'
              }}
              style={{
                width:180,
                height:180
              }}
              />

              <HStack align={'center'} justify='center' my={2}>
                <Button
                size='sm'
                  bgColor='brand.button'
                  py={4}
                  _hover={{
                      bgColor:'brand.buttonHover'
                  }}
                  color='white' variant="solid"
                  onClick={()=>downloadQRInPNG('qrCodeCanvas',campaignData['campaign_name'])}
                >PNG</Button>
                <Button
                 size='sm'
                  bgColor='brand.button'
                  py={4}
                  _hover={{
                      bgColor:'brand.buttonHover'
                  }}
                  color='white' variant="solid"
                  onClick={()=>downloadQRInSVG('qrCodeSvg',campaignData['campaign_name'])}
                >SVG</Button>
              </HStack>
                        </Box>
                        </ModalBody>
              
                    
                      </ModalContent>
                    </Modal>
                  </>
                )
              }
            return <>
             <Tr
             borderBottomColor={'brand.buttonHover'}
             borderBottomWidth={1.5}
             >
        <Td maxW={170} overflowX='auto' p={0} wordBreak='break-word'>
        <Text textAlign='center' pl={5} fontSize={17} fontWeight='semibold' as='span'>
           {campaignData['campaign_name']}
        </Text>
        </Td>
        <Td>
        <Text textAlign='center' fontSize={17} fontWeight='semibold'>
            {campaignData['campaign_type']}
        </Text>
        </Td>
        <Td isNumeric>
            <Text textAlign='center' fontSize={17} fontWeight='semibold'>
            {campaignData['scans_number']}
            </Text>
        </Td>
        <Td isNumeric h={'100%'}>
            <Flex direction={'column'} justify='center' align='center'>

            <Text textAlign='center' fontSize={17} fontWeight='semibold'>
            {campaignData['submissionsNumber']}
            </Text>
            </Flex>
        </Td>
        <Td display='flex' flexDirection='column' alignItems='center'>
        <Menu>
  <MenuButton as={IconButton} variant='ghost' icon={<BsThreeDotsVertical />}>

  </MenuButton>
  <MenuList>
    <MenuItem 
    as={Link}
    to={`/dashboard/edit-campaign/${campaignData['campaign_id']}`}
    icon={<FiEdit2 />}>Edit</MenuItem>
    <MenuItem 
    as={Link}
    to={`/dashboard/submissions/${campaignData['campaign_id']}`}
    icon={<FiBookOpen />}>Submissions Details</MenuItem>
    <QrMenu />
    {/* <MenuItem onClick={()=>downloadQR(campaignData['campaign_id'])} icon={<FiDownload />}>Download QR Code</MenuItem> */}
    <CopyToClipboard text={`${window.location.origin}/c/${campaignData['campaign_id']}`} size={76.3}
          onCopy={() => showToast('Copied to clipboard.','','success',1500)}>
            <MenuItem icon={<FiCopy />}>
   Copy Link
    </MenuItem>
        </CopyToClipboard>
   
  </MenuList>
</Menu>

<QRCodeCanvas style={{display:'none'}} value={`${window.location.origin}/c/${campaignData['campaign_id']}`} size={76.3} id={campaignData['campaign_id']}/>
        </Td>
        </Tr>
            </>
        }
        const fetchCampaigns = () => {
            setIsFetching(true)
            axios.get(`${BASEURL}/campaigns/all-compaigns`,{
                params : {
                    campaignsListLength:campaignsList.length,
                    accessToken:user.accessToken
                }
            })
            .then(res=>{
                if(campaignsList.length > 0 && res.data.results.campaignsListAnalytics == 0) setNoMoreCampaigns(true)
                setCampaingsList([...campaignsList,...res.data.results.campaignsListAnalytics])
                setTotalAnalytics(res.data.results.totalAnalytics)
                setAreCampaingsFetched(true)
            }).then(()=>setIsFetching(false)).catch(err=>{
                showToast('Error','Unable to retrieve Campaigns, Please Try Again!','error',3000)
                console.log('fetch campaigns error',err)
            })
        }
    
    const Loading = () => {
        return <Flex align='center' justify={'center'} p={5}>
        <Spinner />                        
        </Flex>
    }

        onAuthStateChanged(auth,currentUser=>{
            setUser(currentUser)
        })
    React.useEffect(()=>{
        
        if(user) fetchCampaigns()
    },[user])
React.useEffect(()=>{
    setActivePage('CAMPAIGNSLIST')
},[])
    return (
        <Box>
            <Metadata title='Campaigns List'/>
           <Box
            boxShadow={'md'}
            py={4}
            px={3} 
            >
                 {areCampaignsFetched == false ? <Loading /> : <>
                <Text 
                fontSize={32}
                fontWeight='semibold'
                >Campaigns</Text>
               {/* {campaignsList.length == 0 ? <Text>No Campaigns!</Text> : <Box>
                    <Text fontWeight='semibold'>Total Scans : {totalAnalytics['TotalScans']}</Text>
                    <Text fontWeight='semibold'>Total Submissions : {totalAnalytics['TotalSubmissions']} </Text>
                </Box>} */}
                <Flex
                justify={'flex-end'}
                >
                    <Box>
                        {/* Filtering Here */}
                    </Box>
                    <Button
                    bgColor='brand.button'
                    as={Link}
                    to='/create-campaign'
                    py={4}
                    _hover={{
                        bgColor:'brand.buttonHover'
                    }}
                    color='white' variant="solid"
                    >
                        Create Campaign
                    </Button>
                </Flex>
                 {/* Table Analytics */}
           {campaignsList.length > 0 ?  <Box my={4}
           borderWidth={1.5}
           borderColor={'brand.buttonHover'}
           p={2}
           px={5}
           borderRadius={'5px'}
            >
            <TableContainer overflow={{lg:'hidden'}}>
  <Table variant={'unstyled'} size="lg" className='campaignsTable' >
    {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
    <Thead
    bgColor='brand.button'
    px={4}
    borderRadius={20}
    color='#fff'
    >
      <Tr>
        <Th style={{paddingLeft:'10px !important'}}>
            <Text pl={5} textAlign='left' fontWeight='semibold' fontSize={16}>Campaign Name</Text>
        </Th>
        <Th>
            <Text textAlign='center' fontWeight='semibold' fontSize={16}>Type</Text>
        </Th>
        <Th>
        <Text textAlign='center' fontWeight='semibold' fontSize={16}>No of Scans</Text>
        </Th>
        <Th>
        <Text textAlign='center' fontWeight='semibold' fontSize={16}>No of submission</Text>
        </Th>
        <Th>
        <Text textAlign='center' fontWeight='semibold' fontSize={16}>&nbsp;</Text>
        </Th>
      </Tr>
    </Thead>
    <Tbody>
        {(campaignsList.length != 0) && campaignsList.map(campaignData=>{
            return <DynamicTr campaignData={campaignData}/>
        })}
    </Tbody>
  </Table>
</TableContainer>
        {(noMoreCampaigns == false) && <Box mt={4} display={'flex'} justifyContent='center' alignItems={'center'}>
            <Button
            isLoading={isFetching}
            loadingText='Loading'
            bgColor={'brand.button'}
            color='#fff'
            variant='solid'
            my={2}
            _hover={{
                bgColor:'brand.buttonHover'
            }}
            spinnerPlacement='start'
            size='lg'
            onClick={()=>fetchCampaigns()}
            >Load More</Button>
        </Box>}

            </Box> : <>
            <Text as='p' fontSize='2xl' textAlign='center' fontWeight={'light'} mt={9}>You didn't create any campaigns yet!</Text>
            </>}
            </> }
            </Box>      
        </Box>
    );
}
 
export default CampaignsList;