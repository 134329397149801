import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from './reportWebVitals';
import { ChakraProvider,extendTheme  } from '@chakra-ui/react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
const theme = extendTheme({
  colors: {
    brand: {
      button:'#F6AD55',
      buttonHover:'#f6ad55d6',
      activePage:'#652B19',
      fieldName:'#9C4221'
    }
  }
});
ReactDOM.render(
  <ChakraProvider  theme={theme}>
    <Router>
     <App />
     </Router>
  </ChakraProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
