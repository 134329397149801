import React,{useContext} from 'react'
import { Box, Divider,Button,Text,Flex } from '@chakra-ui/react';
import { FiCheck,FiX } from 'react-icons/fi'
import { loadStripe } from '@stripe/stripe-js'
import { BASEURL, STRIPE_PUBLIC_KEY } from '../../api';
import axios from 'axios'
import { auth } from '../../api/firebase-config'
import { onAuthStateChanged } from '@firebase/auth'
import { useHistory } from 'react-router-dom'
import { UserContext } from '../../api';
import { Link } from 'react-router-dom'
import { FiChevronRight } from 'react-icons/fi'
import {ReactComponent as BottomElement} from './wave.svg';
import Metadata from '../../Components/Metadata'

let stripePromise  = loadStripe(STRIPE_PUBLIC_KEY)

const Pricing = () => {
    const history = useHistory()
    const [user,setUser] = React.useState(null)
    const [currentPlan,setCurrentPlan] = React.useState('')
    const { setActivePage,isAuth } = useContext(UserContext)
    const checkout = async (type) => {
        const stripe = await stripePromise
        axios.post(`${BASEURL}/plans/checkout`,{
            email:user.email,
            uid:user.uid
        }).then(res=>{
            const { sessionId } = res.data
             stripe.redirectToCheckout({sessionId})
        }).catch(err=>console.log(err))
    }

    const plansFeatures = {
        free : {
            youCan:[
                'Add Campaigns',
                'Send Emails (100/per Day)'
            ],
            youCant:[
                
            ]
        },
        pro : {
            youCan:[
                'Add Campaigns',
                'Submit Emails (10K/per Month)',
                'Use Custom Domain'
            ],
            youCant:[
                
            ]
        }, 
        premium : {
            youCan:[
                'Add Campaign',
                'Remove Campaign',
                'See Analytics',
                'Submit Emails'
            ],
            youCant:[
                
            ]
        }
    }
    const decideFeature = (type) => {
        switch(type) {
            case 'Starter':
                return <>
                    {plansFeatures['free'].youCan.map(feature=>{
                        return <Feature ability={1} feature={feature}/>
                    })}
                     {plansFeatures['free'].youCant.map(feature=>{
                        return <Feature ability={0} feature={feature}/>
                    })}
                </>
            break
            case 'Pro':
                return <>
                    {plansFeatures['pro'].youCan.map(feature=>{
                        return <Feature ability={1} feature={feature}/>
                    })}
                     {plansFeatures['pro'].youCant.map(feature=>{
                        return <Feature ability={0} feature={feature}/>
                    })}
                </>
            break
            case 'Premium':
                return <>
                    {plansFeatures['premium'].youCan.map(feature=>{
                        return <Feature ability={1} feature={feature}/>
                    })}
                     {plansFeatures['premium'].youCant.map(feature=>{
                        return <Feature ability={0} feature={feature}/>
                    })}
                </>
            break
        }
    }
    const Feature = ({feature,ability}) => {
        return (<Flex align='center' gap={3} my={1.2}>
            {ability == 1 ? <FiCheck size={20}/> : <FiX size={20}/>}
            <Text>{feature}</Text>
        </Flex>)
    }
    const renderPrice = type => {
        switch(type) {
            case 'starter':
                return '0.00'
            break
            case 'pro' :
                return '5.99'
            break
            case 'premium':
                return '8.99'
            break
            default:
                return '0.00'
        }
    }
    const Plan = ({type}) => {
        return <Box
        // bgColor={type == 'Pro' ? 'orange.200' : 'transparent'}
        borderWidth={1.5}
        bgColor='#fff'
        borderColor={'brand.buttonHover'}
        py={4}
        px={5}
        borderRadius={7}
        boxShadow='lg'
        position='relative'
        zIndex={1000}
        // top={{md:(type == 'Pro' && -5)}}
        w={300}
        h={350}
        >
            <Box my={4}
            // bgColor="red.200"
            display='flex'
            flexDirection='column'
            justifyContent={'space-between'}
            alignItems='center'
            >
                <Flex w={'100%'} align='center' justify={'space-between'} fontWeight='bold'>
                <Text fontSize={22}>{type}</Text>
                <Text fontSize={18}>${renderPrice(type.toLowerCase())}/mo</Text>
                </Flex>

            </Box>
            <Divider />
            <Text 
            textAlign='left'
            fontWeight={'bold'}
            my={3}
                >
            What's included
            </Text>
            <Box my={4}>
                {decideFeature(type)}
            </Box>
            {/* <Divider /> */}
            <Flex justify='center' my={4} pos="absolute" bottom={0} left={0} right={0}
            >
                {!isAuth ? <>
                <Button 
                 bgColor='brand.button'
                 as={Link}
                 to='/auth'
                 color='#fff'
                 py={4}
                 _hover={{
                     bgColor:'brand.buttonHover'
                 }}
                variant={type != 'Pro' ? 'solid' : 'outline'}
                >
                    <Text>{type == 'Starter' ? 'Sign Up ' : 'Select Plan'}</Text>
                </Button>
                </>:<>
                <Button 
                 bgColor='brand.button'
                 _hover={{
                    bgColor:'brand.buttonHover'
                }}
                color='#fff'
                rightIcon={<FiChevronRight size={20}/>}
                onClick={()=>{
                    if(currentPlan == 'free' && type != 'Free') checkout('paid')
                    else return
                }}
                variant={type != 'Pro' ? 'solid' : 'outline'}
                >
                    <Text>{type == 'Starter' ? 'Current Plan ' : 'Select Plan'}</Text>
                </Button>
                </>}
                
            </Flex>
        </Box>
    }

        onAuthStateChanged(auth,currentUser=>{
            setUser(currentUser)
        })

        const fetchCurrentPlan = () => {

            axios.get(`${BASEURL}/sellers`,{
                params:{
                    accessToken:user.accessToken
                }
            })
            .then(res=>{
                console.log(res.data)
                if(res.data.plan != 'free') history.replace('/current-plan')
                setCurrentPlan(res.data.plan)
            }).then(err=>{
              console.log(err)
            }).finally(()=>{
            //   setIsFetchingPlan(false)
            })

          }
        
          React.useEffect(()=>{
            if(user) fetchCurrentPlan()
          },[user])

          
React.useEffect(()=>{
    setActivePage('PRICING')
},[])
          
    return (
      <>
      <Metadata title={'Pricing'}/>
      {loadStripe && <>
      {(currentPlan == 'free' || currentPlan == '') && <Box>
          <Box
        minH={'90vh'}
        pb={{base:10,md:0}}
          >
           <Text
        textAlign={'center'}
        fontSize='5xl'
        fontWeight={800}
        my={8}
        >Pricing</Text>
            <Flex
            gap={5}
            direction={{base:'column',md:'row'}}
            align='center'
            justify={'center'}
            >
            <Plan type='Starter' />
            <Plan type='Pro' />
            </Flex>
            {/* <Plan type='Premium' /> */}
          </Box>
        </Box>}
      </>}
<BottomElement 
className='pricingBottomElementContainer'
style={{
    width:'100%',
    position:'absolute',
    bottom:0,
    left:0,
    right:0,
}}/>
      </>
    );
}
 
export default Pricing;