import React,{useContext} from 'react'
import { Box, Divider,Button,Text,Flex, Spinner } from '@chakra-ui/react';
import { FiCheck,FiX } from 'react-icons/fi'
import { loadStripe } from '@stripe/stripe-js'
import { BASEURL, STRIPE_PUBLIC_KEY } from '../api';
import axios from 'axios'
import Metadata from '../Components/Metadata';
import { auth } from '../api/firebase-config';
import { onAuthStateChanged } from '@firebase/auth'
import { useHistory } from 'react-router-dom'
import { UserContext } from '../api';

let stripePromise  = loadStripe(STRIPE_PUBLIC_KEY)
 
const MyPlan = () => {
  const history = useHistory()
  const { setActivePage,setCurrentStep,currentStep ,currentSettingsMode,setCurrentSettingsMode,
    currentMailMode,setCurrentMailMode
} = useContext(UserContext)
  const [user,setUser] = React.useState(null)
  const [customerData,setCustomerData] = React.useState({})
  const [isFetchingPlan,setIsFetchingPlan] = React.useState(true)
  onAuthStateChanged(auth,currentUser=>{
    setUser(currentUser)
  })
  const manageBilling = () => {
    axios.post(`${BASEURL}/plans/manage-billing`,{
        customerId:customerData['customer_id']
    }).then(res=>{
        window.location.replace(res.data.url)
    }).catch(err=>console.log(err))
  }
  
  const fetchCustomer = () => {
    // console.log(user.accessToken)
    axios.get(`${BASEURL}/sellers`,{
      params:{
        accessToken:user.accessToken
      }
    })
    .then(res=>{
      setCustomerData(res.data)
    }).then(err=>{
      console.log(err)
    }).finally(()=>{
      setIsFetchingPlan(false)
    })
  }

  React.useEffect(()=>{
    if(user) fetchCustomer()
  },[user])
  React.useEffect(()=>{
    setActivePage('MYPLAN')
},[])
    return (
      <>
      <Metadata title='My Plan'/>
    <Box
    display="flex"
    flexDirection={'column'}
    alignItems='center'
    boxShadow={'md'}
    py={7}
    px={7}
    style={{
      height:'calc(100vh - 72px)',
      maxHeight: 'calc(100vh - 72px)'
  }}
    >
   {isFetchingPlan ? <><Spinner /></> : <Box
   display={'flex'}$
   flexDirection={'column'}
   alignItems='center'
   borderWidth={1.5}
   borderColor={'brand.buttonHover'}
   my={10}
   py={7}
   px={4}
   >
   <Text fontSize={40} my={4} textAlign='center'>Your Current Plan : <Text as="b">{customerData['plan']}</Text></Text>
    <Divider />
    <Text as="p" fontSize='lg' mt={4}>
        Not happy with your current plan? Cancel or Upgrade by clicking the
        button below.
    </Text>
              {customerData['plan'] == 'paid' ? <>
              <Button 
               bgColor='brand.button'
               color='#fff'
               _hover={{
                   bgColor:'brand.buttonHover'
               }}
              my={5} id="manage-billing-button"
              onClick={()=>manageBilling()}
              >
                Manage Billing
              </Button>
              </> : <>
              <Button 
                bgColor='brand.button'
                color='#fff'
                _hover={{
                    bgColor:'brand.buttonHover'
                }}
              my={5} id="manage-billing-button"
              onClick={()=>history.replace('/pricing')}
              >
                Go To Pricing
              </Button>
              </>}
    </Box>}
        </Box>
      </>
    );
}
 
export default MyPlan;