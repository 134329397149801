import React, { useContext, useState } from 'react';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Text,Center,
    Image
} from '@chakra-ui/react';
import { Link } from 'react-router-dom'
import {
    signInWithPopup,
    getAdditionalUserInfo,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    onAuthStateChanged
} from '@firebase/auth'
import { FiArrowLeft } from 'react-icons/fi'
import { authProvider, auth } from '../../api/firebase-config';
import { BASEURL, UserContext } from '../../api';
import { useHistory } from 'react-router-dom';
import { sendPasswordResetEmail } from '@firebase/auth'
import { FcGoogle } from 'react-icons/fc'
import axios from 'axios';
import Metadata from '../../Components/Metadata';

const Auth = () => {
    const history = useHistory()
    const { setIsAuth,setActivePage,setUserDisplayName,setUserAvatarLink,setUserRole } = useContext(UserContext)
    const [afterManualSignUpMode, setAfterManualSignUpMode] = React.useState(false)
    const [user, setUser] = React.useState(null)
    const [firstName, setFirstName] = React.useState('')
    const [lastName, setLastName] = React.useState('')
    const [isUser, setIsUser] = React.useState(true)
    const [authError, setAuthError] = useState(false)
    const [userData, setUserData] = React.useState({
        email: "",
        password: ""
    })
    const [resetPasswordMode, setResetPasswordMode] = React.useState(false)
    const [resetEmail, setResetEmail] = React.useState('')
    const [resetEmailMessage, setResetEmailMessage] = React.useState('')
    const handleChange = (userInput) => {
        setUserData({ ...userData, ...userInput })
    }
    onAuthStateChanged(auth, currentUser => {
        setUser(currentUser)
    })

    /**
     * SignInWithGoogle method handles both sign in and sign up operations.
     */

    const saveUser = (uid, email, emailVerified,firstName,lastName) => {
        console.log('save user function')
        axios.post(`${BASEURL}/sellers/new_seller`, {
            uid, email, emailVerified,firstName,lastName
        }).then((res) => {
            console.log(res.data)
        })

    }
    const signInWithGoogle = async () => {
        const res = await signInWithPopup(auth, authProvider)        
        try {
            let firstName,lastName
            if (res && getAdditionalUserInfo(res).isNewUser) {
                const { uid, email, emailVerified } = res.user
                let displayName = res.user.displayName.split(' ')
                console.log('splitted',displayName)
                if(displayName.length > 1) {
                    firstName = displayName[0]
                    lastName = displayName[1]
                }else {
                    firstName = displayName[0]
                    lastName = ''
                }
                localStorage.setItem('userDisplayName',res.user.displayName)
                setUserDisplayName(res.user.displayName)

                saveUser(uid, email, emailVerified,firstName,lastName)
            }else{
                    axios.get(`${BASEURL}/sellers`,{
                        params:{
                          accessToken:res.user.accessToken
                        }
                      })
                      .then(res=>{
                        const { first_name,last_name,avatar,role } = res.data
                        let fullName = `${first_name} ${last_name}`
                        localStorage.setItem('userDisplayName',fullName)
                        setUserDisplayName(fullName)
                        setUserAvatarLink(avatar)
                        setUserRole(role)
                        localStorage.setItem('role',role)
                        localStorage.setItem('userAvatarLink',avatar)
                      })
            }
        } catch (error) {
            setAuthError(true)
        }
    }

    const manualSignUp = async () => {
        const { email, password } = userData
        try {
            const res = await createUserWithEmailAndPassword(auth, email, password)

            if (res && auth.currentUser) {
                const { uid, email, emailVerified } = res.user
                sendEmailVerification(auth.currentUser)
                saveUser(uid, email, emailVerified,firstName,lastName)
                setAfterManualSignUpMode(true)
            }
        } catch (err) {
            console.log(err.message)
        }
    }

    const manualSignIn = async () => {
        const { email, password } = userData
        try {
        const userResp =  await signInWithEmailAndPassword(auth, email, password)
            axios.get(`${BASEURL}/sellers`,{
                params:{
                  accessToken:userResp.user.accessToken
                }
              })
              .then(res=>{
                const { first_name,last_name,avatar,role } = res.data
                let fullName = `${first_name} ${last_name}`
                if(userResp.user.emailVerified) {
                    localStorage.setItem('userDisplayName',fullName)
                    setUserDisplayName(fullName)
                    setUserAvatarLink(avatar)
                    setUserRole(role)
                    localStorage.setItem('role',role)
                    localStorage.setItem('userAvatarLink',avatar)
                }
              })
        } catch (err) {
            setAuthError(true)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (isUser == true) {
            manualSignIn()
        } else {
            manualSignUp()
        }
    }
    const EmailVerificationMessage = () => {
        return (<Flex textAlign={"center"} direction='column' align='center' justify={'center'}>
            <Heading as="h3" size="md">Check your mail</Heading>
            <Text as="p" mt={6}>
            We sent the verification link to your email address
            </Text>
           <Flex align='center' gap={2}>
           <Text>Didn't recive the email?</Text>
            <Button my={3}
              color='brand.button' variant={"link"}
                onClick={() => sendEmailVerification(auth.currentUser)}
            >Click to resend</Button>
           </Flex>
          
           </Flex>
           )
    }

    const handleResetSubmit = (e) => {
        e.preventDefault()

        sendPasswordResetEmail(auth, resetEmail)
            .then(() => {
                setResetEmail('')
                setResetEmailMessage('A reset email has been successfully sent to this email if exists')
            })
    }

    React.useEffect(() => {
        if (user) {
            if (user.emailVerified) {
                setIsAuth(true)
                localStorage.setItem('isAuth', true)
                history.replace('/')
            }
        }
    }, [user])

    React.useEffect(()=>{
        setActivePage('AUTH')
    },[])
    return (
        <Flex
        minH={'100vh'}
        >
            <Flex
            w={'60%'}
            bgColor="brand.button"
            align='center'
            justify='center'
            display={{base:'none',md:'flex'}}
            >
                 <Image 
                    src='/images/login.png'
                    w={600}
                    objectFit='cover'
                    />
            </Flex>
          <Flex 
          align={'center'}
          justify='center'
          w={{base:'100%',md:'40%'}}
          >
  <Flex
        align='center'
        justify={"center"}
        minH={300}
        mt={10}
    >
        <Metadata title='Auth Page' />
        <Box w={{ base: '100%', md: 500 }}>
            <Flex
                direction={"column"}
                gap={5}
                py={10}
                px={5}
            >
                {(user && !user?.emailVerified) && <EmailVerificationMessage />}
                {!user && (resetPasswordMode == false && afterManualSignUpMode == false) && <>
                        <Box textAlign={"center"}>
                        <Heading as="h1" textAlign='left'>{isUser == true ? 'Login' : 'Sign up'}</Heading>
                        <Box>
                        <Text textAlign={"left"}>{isUser ? "Don't have an account?" : "already have an account?"} <Button  color='brand.button' variant={'link'} onClick={() => setIsUser(prev => !prev)}>{isUser == true ? "Sign Up" : "Sign In"}</Button></Text>
                    </Box>
                    </Box>
                    <Box>
                        <form autoComplete='off' onSubmit={handleSubmit}>
                            {!isUser && <Flex gap={5}>
                            <FormControl>
                                <FormLabel>First Name</FormLabel>
                                <Input
                                    isInvalid={authError}
                                    type="text"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    enterKeyHint='done'
                                    onClick={() => {
                                        if (authError) {
                                            setUserData({ ...userData, email: '', password: '' })
                                            setAuthError(false)
                                        }
                                    }}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Last Name</FormLabel>
                                <Input
                                    isInvalid={authError}
                                    type="text"
                                    value={lastName}
                                    // placeholder='you@example.com'
                                    onChange={(e) => setLastName(e.target.value)}
                                    enterKeyHint='done'
                                    onClick={() => {
                                        if (authError) {
                                            setUserData({ ...userData, email: '', password: '' })
                                            setAuthError(false)
                                        }
                                    }}
                                />
                            </FormControl>
                            </Flex>}
                            <FormControl my={7}>
                                <FormLabel>Email Address</FormLabel>
                                <Input
                                    isInvalid={authError}
                                    type="email"
                                    value={userData.email}
                                    placeholder='you@example.com'
                                    onChange={(e) => handleChange({ email: e.target.value })}
                                    enterKeyHint='done'
                                    onClick={() => {
                                        if (authError) {
                                            setUserData({ ...userData, email: '', password: '' })
                                            setAuthError(false)
                                        }
                                    }}
                                />
                            </FormControl>
                            <FormControl mb={7}>
                               <Flex justify={'space-between'} align='center'>
                               <FormLabel>Password</FormLabel>
                                <Box
                                display={"flex"}
                                justifyContent={"right"}
                                my={3}
                            >
                                {isUser && <Button color='brand.button' variant={"link"} onClick={() => setResetPasswordMode(true)}>Forgot Password?</Button>}
                            </Box>
                               </Flex>
                                <Input
                                    isInvalid={authError}
                                    value={userData.password}
                                    placeholder='Enter 6 Character or more'
                                    type="password" onChange={(e) => handleChange({ password: e.target.value })}
                                    enterKeyHint='done'
                                    onClick={() => {
                                        if (authError) {
                                            setUserData({ ...userData, email: '', password: '' })
                                            setAuthError(false)
                                        }
                                    }}
                                />
                            </FormControl>
                            <Button w="full" size="lg" bg='brand.button' color='#fff' variant={"solid"} type="submit"
                            _hover={{
                                bgColor:'brand.buttonHover'
                            }}
                            >{isUser == true ? 'Sign In' : 'Sign Up'}</Button>
                        </form>
                    </Box>
                    <Button
                        borderColor={'brand.button'}
                        size={'lg'}
                        onClick={signInWithGoogle}
                        leftIcon={<FcGoogle />}
                        variant={"outline"}
                    >Sign In With Google</Button>
                    {authError && <Text as="p" color={"crimson"}>You have entered an invalid username or password</Text>}
                </>}
                <Center w={'100%'}>
               <Box display={resetPasswordMode && 'none'}>
               <Button as={Link} to='/' variant={'link'} my={4} leftIcon={<FiArrowLeft size={20} />}>Back To Home</Button>
               </Box>
                    </Center>
                    {(!user && resetPasswordMode) && <>
                    <Box textAlign={"center"}>
                        <Heading as="h1" textAlign='left'>Forgot password?</Heading>
                        <Text as="p" color={"green.300"} fontWeight='bold' fontSize="lg">{resetEmailMessage}</Text>
                    </Box>
                    <Box>
                        <form autoComplete='off' onSubmit={handleResetSubmit}>
                            <FormControl>
                                <FormLabel>Your E-mail</FormLabel>
                                <Input
                                    isInvalid={authError}
                                    type="email" onChange={(e) => setResetEmail(e.target.value)}
                                    value={resetEmail}
                                    enterKeyHint='done'
                                    onFocus={() => setResetEmailMessage('')}
                                    onClick={() => {
                                        if (authError) {
                                            setUserData({ ...userData, email: '', password: '' })
                                            setAuthError(false)
                                        }
                                    }}
                                />
                            </FormControl>
                            <Box
                                display={"flex"}
                                justifyContent={"right"}
                                my={3}
                            >
                                <Button color="brand.button" variant={"link"} onClick={() => setResetPasswordMode(false)}>Cancel</Button>
                            </Box>
                            <Button w="full" size="lg" variant={"solid"} type="submit"
                             bgColor='brand.button'
                             color='#fff'
                             _hover={{
                                bgColor:'brand.buttonHover'
                            }}
                            >Reset</Button>
                        </form>
                    </Box>
                    <Center w={'100%'}>
                <Button as={Link} to='/' variant={'link'} my={4} leftIcon={<FiArrowLeft size={20} />}>Back To Home</Button>
                    </Center>
                </>}
            </Flex>
        </Box>
    </Flex>
          </Flex>
        </Flex>
    );
}
 
export default Auth;